<template>
  
  <head>
		<meta name='viewport' content="width=device-width, initial-scale=1.0">
	
</head>
  <v-app>
    <v-main>


      	<div id="disce">
		<VCardTitle id='dice' v-if="within > 1024"><button >UMGROUP	</button></VCardTitle>
		<VCardTitle id='dicety'  v-if="within < 1024 && hithin <= 430"><button >UMGROUP	</button></VCardTitle>
		<VCardTitle id='dicet'  v-if="within < 1024 && hithin > 430 && hithin >= 932"><button >UMGROUP	</button></VCardTitle>
		<VCardTitle id='dicetv'  v-if="within < 1024 && hithin > 430 && hithin < 932"><button>UMGROUP	</button></VCardTitle>
		<VCardTitle id='dicets'  v-if="within == 1024"><button >UMGROUP	</button></VCardTitle>
    </div>


    <div id="disc">
		<VCardTitle v-if="within > 1024 && hithin > 1024" id="foe" @click="$router.push({path:'/'})"><button id="''">UNLIMITED MONEY GENERATOR </button><VCardSubtitle id="discb"><button id="bold">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="margins">{{$store.state.user.username}}</button></VCardSubtitle>
		</VCardTitle>
		<VCardTitle v-if="within > 1024 && hithin <= 1024 && within > 1500" @click="$router.push({path:'/'})"><button id="''">UNLIMITED MONEY GENERATOR </button><VCardSubtitle id="discb"><button id="bold">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="margins">{{$store.state.user.username}}</button></VCardSubtitle>
		</VCardTitle>
		<VCardTitle v-if="within > 1024 && hithin <= 1024 && within < 1500" @click="$router.push({path:'/'})"><button id="downerse">UNLIMITED MONEY GENERATOR </button><VCardSubtitle id="discb"><button id="bold">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="margins">{{$store.state.user.username}}</button></VCardSubtitle>
		</VCardTitle>
		<VCardTitle v-if="within < 1024 && hithin > 430" id='getupp' @click="$router.push({path:'/'})"><button id="downer">UMGENERATOR </button><VCardSubtitle id="discbt"><button id="bolde">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="marginst">{{$store.state.user.username}}</button></VCardSubtitle></VCardTitle>
		<VCardTitle v-if="within < 1024 && hithin < 431" ><button id="downere" @click="$router.push({path:'/'})">UMGENERATOR </button><VCardSubtitle id="discbtt"><button id="bolder">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="marginst">{{$store.state.user.username}}</button></VCardSubtitle></VCardTitle>
		<VCardTitle v-if="within == 1024" ><button id="downers" @click="$router.push({path:'/'})">UNLIMITED MONEY GENERATOR </button><VCardSubtitle id="discbt"><button id="boldes">${{Intl.NumberFormat().format($store.state.user.money)}}</button><button id="marginst">{{$store.state.user.username}}</button></VCardSubtitle></VCardTitle>
		
		
		

    </div>
    <div id="discup">
		
		<VCardSubtitle v-if="within > 1024"><button id="discuptt" @click="$router.push({'path':'/worlds'})">Discover</button><button id="margins" @click="$router.push({'path':'/home'})">Home</button><button id="margins" @click="$router.push({'path':'/profile'})">Account</button><button @click="$router.push({'path':'/deposits'})" id="margins">Deposits / Withdrawals</button><button id="margins" @click="$router.push({'path':'/create/normal'})">Create</button><button v-if='!$store.state.isAuthenticated' id="margins" @click="$router.push({'path':'/login'})">Login</button><button id="margins"  v-if='$store.state.isAuthenticated' @click="Logout">Logout</button></VCardSubtitle>
		<VCardSubtitle v-if="within < 1024 && hithin > 430" id='sunup'><button id="discuptt" @click="$router.push({'path':'/worlds'})">Discover</button><button id="marginssm" @click="$router.push({'path':'/home'})">Home</button><button id="marginssm" @click="$router.push({'path':'/profile'})">Account</button><button @click="$router.push({'path':'/deposits'})" id="marginssm">Deposits / Withdrawals</button><button id="marginssm" @click="$router.push({'path':'/create/normal'})">Create</button><button v-if='!$store.state.isAuthenticated' id="marginssm" @click="$router.push({'path':'/login'})">Login</button><button id="marginssm"  v-if='$store.state.isAuthenticated' @click="Logout">Logout</button></VCardSubtitle>
		<VCardSubtitle v-if="within < 1024 && hithin < 431" id="getup"><button id="discupttt" @click="$router.push({'path':'/worlds'})">Discover</button><button id="marginssmm" @click="$router.push({'path':'/home'})">Home</button><button id="marginssmm" @click="$router.push({'path':'/profile'})">Account</button><button @click="$router.push({'path':'/deposits'})" id="marginssmm">Deposits / Withdrawals</button><button id="marginssmm" @click="$router.push({'path':'/create/normal'})">Create</button><button v-if='!$store.state.isAuthenticated' id="marginssmm" @click="$router.push({'path':'/login'})">Login</button><button id="marginssmm"  v-if='$store.state.isAuthenticated' @click="Logout">Logout</button></VCardSubtitle>
		<VCardSubtitle v-if="within == 1024"><button id="discuptts" @click="$router.push({'path':'/worlds'})">Discover</button><button id="marginssm" @click="$router.push({'path':'/home'})">Home</button><button id="marginssm" @click="$router.push({'path':'/profile'})">Account</button><button @click="$router.push({'path':'/deposits'})" id="marginssm">Deposits / Withdrawals</button><button id="marginssm" @click="$router.push({'path':'/create/normal'})">Create</button><button v-if='!$store.state.isAuthenticated' id="marginssm" @click="$router.push({'path':'/login'})">Login</button><button id="marginssm"  v-if='$store.state.isAuthenticated' @click="Logout">Logout</button></VCardSubtitle>

    </div>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import axios from 'axios';


export default {
  
  name: 'App',
  god:'',

  beforeCreate(){
    var laugh = 'TRAFFIC'
    if (this.$store.state.Maintenance == true){
      if(localStorage.adminmaintenance == 'true'){

      }else{
        this.$router.push({path:'/maintenance'})
      }
      
    }else{
		

	}
    
    this.$store.commit('initializeStore')
    //console.log(this.$store.state.isAuthenticated, 'looddwdw')
    if(this.$store.state.token){
      laugh = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Token ' + this.$store.state.token 
      this.$store.state.isAuthenticated = true
    }else{
      axios.defaults.headers.common['Authorization'] = ''
      this.$store.state.isAuthenticated = false
      
      
      //this.$router.push({ path: '/login' })
      }
      //

   
    

    const fromData = {
					USER:laugh
				}
		

				axios
					.post('/api/v1/IS_MAINT/', fromData).then(response =>{
						
						//console.log(response.data.MAINTENANCE, '444')
            
            if(response.data.MAINTENANCE == true){
              if(this.$store.state.token){

                localStorage.removeItem('token')
                localStorage.removeItem('username')
                
              }
              this.$store.state.Maintenance = true
              localStorage.setItem('adminmaintenance', false  )
              this.$router.push({path:'/maintenance'})
              
            }else{
              this.$store.state.Maintenance = false
              localStorage.setItem('adminmaintenance', true)
            }

					}).catch(error => {
						if(error.response){
							//console.log(error.response.data)
							
							
							
						}
					})
		
    
  },


  data(){
		return{
			money:0,myusername:'',within:0
			
		}
	},

 	 mounted: async function() {		
		
		
		setInterval(this.GETSTATS, 15000)
		setInterval(this.redact, 1000)
		this.GETSTATS()
		setInterval(this.redacted, 1000)
		

		var self = this;
			setTimeout(function() {
				if(self.$route.name !='about' ){


				if(self.$route.name != 'abouts' ){

					if(self.$route.name != 'home'){

					
					//self.$router.push({path:'/e2024/home'})
					
					}
				}

				}
											
										}, 1000);
		
		
		
	},

	methods:{
		redacted(){
			this.within = window.innerWidth
			this.hithin = window.innerHeight
		},
		redact(){
			var self = this;
			setTimeout(function() {
				if(self.$route.name !='about' ){


				if(self.$route.name != 'abouts' ){

					if(self.$route.name != 'home'){

					
						//self.$router.push({path:'/e2024/home'})

						}
					

				}

				}
											
										}, 1000);
		},
		jumpstart(){
			window.location.replace('https://unlimitedmoneygroup.com')
		},

		GETSTATS(){
				if(this.$store.state.token){

				
				const fromData = {
					'Token':this.$store.state.token
				}

				axios
					.post('/api/v1/playerpost/', fromData).then(response =>{
						
						

						this.$store.state.user.money = response.data.UM
						
						this.myusername = response.data.username
						

						//console.log(this.username)

					}).catch(error => {
						if(error.response){
							
							
							this.$store.state.user.money = 0

							this.$store.state.user.money = 0
							
							
						}
					})
				}
			
		},

		async Logout(){

				axios
					.post('/api/v1/token/logout/')
					.then(response =>{
						
						
						//console.log('Logged Out')
					
					}).catch(error => {
						if(error.response){
							//console.log(error.response.data)
						}
					})
				axios.defaults.headers.common['Authorization'] = ''
				localStorage.removeItem('token')
				
				this.$store.commit('removeToken')
				localStorage.removeItem('username')
				localStorage.removeItem('Tutorial')
				localStorage.removeItem('id')
				this.password = ''
				this.$store.state.user.money = 0
				this.$store.state.user.username = ''
				this.myusername = ''
				this.money = 0
				localStorage.money = 0

				

				this.$router.push({'path':'/'})



				},
	}
}
</script>

<style scoped>
#body {
            background-color: grey;
        }
#dice{
	font-size: 90%;
	margin-top:-0.20%;
}
#dicetv{
	font-size: 120%;
	margin-top:-0.20%;
}
#getup{
	margin-top:-2.6%;
}

#dicet{
	font-size: 150%;
	margin-top:-0.20%;
}
#dicety{
	font-size: 80%;
	margin-top:-0.8%;
}

#dicets{
	font-size: 150%;
	margin-top:0.1%;
}
#downer{
	font-size: 80%;
	margin-top:6.9%;
}
#downere{
	font-size: 80%;
	margin-top:-1.1%;
	z-index:60;
	position: absolute;
}
#downers{
	font-size: 120.5%;
	margin-top:4.45%;
}

#downerse{
	font-size: 120.5%;
	margin-top:.75%;
}
#bold{}
#discuptt{
	font-weight: 500;
	font-size:140%;
}
#discupttt{
	font-weight: 500;
	font-size:110%;
	top:-15%;
}
#discuptts{
	font-weight: 500;
	font-size:150%;
}
#margins{
	margin-left:5%;
	font-size:110%;
}
#marginssm{
	margin-left:5%;
	margin-top:2%;
	font-size:130%;
}
#marginssmm{
	margin-left:5%;
	margin-top:2%;
	font-size:100%;
}

#discb{
	position:absolute;
	width:100%;
	right:0%;
	height:100%;

	top:40%;
	padding-right:10px;
	text-align: right;
	
}
#disc {
	position: absolute;
	width: 100%;
	height: 8.5%;
	padding: 7.5px;
	border-bottom: 4px solid rgb(2, 9, 2);
	margin: 40;
	text-align: left;
	right: 0px;
	top:4.4%;
	color:rgba(47, 163, 34, 0.597);
	background-color: rgb(8, 8, 8);
	opacity: 1;
	z-index: 150;
	
}


#discup {
	position: absolute;
	width: 100%;
	height: 5.1%;
	
	border-bottom: 4px solid rgb(2, 9, 2);
	margin: 40;
	text-align: left;
	right: 0px;
	padding-top:4px;
	top: 11.75%;
	padding-left:5px;
	color:rgb(141, 141, 141);
	background-color: rgb(5, 5, 5);
	opacity: 1;
	z-index: 150;
	
}

#disce {
	position: absolute;
	width: 100%;
	height: 4.25%;
	


	text-align: right;
	right: 0px;
	top:0%;
	color:rgb(73, 73, 73);
	background-color: rgb(166, 166, 166);
	
	opacity: 1;
	z-index: 50;

	
}


	#bolde{font-size: 100%;}
	#bolder{font-size: 140%;}
	#boldes{font-size: 150%;}
#marginst{
	margin-left:5%;
	font-size:180%;
	margin-top:.5%;

}
#discbt{
	position:absolute;
	width:100%;
	right:2%;
	height:100%;

	top:40%;
	padding-right:10px;
	text-align: right;
	
}
#discbtt{
	position:absolute;
	width:100%;
	right:2%;
	height:100%;

	top:20%;
	font-size:40%;
	padding-right:10px;
	text-align: right;
	
}

@media only screen and (max-width:432px){
	#disc {
	position: absolute;
	width: 100%;
	height: 8.5%;
	padding: 7.5px;
	font-size:90%;
	border-bottom: 4px solid rgb(2, 9, 2);
	margin: 40;
	text-align: left;
	right: 0px;
	top:4.4%;
	color:rgba(47, 163, 34, 0.597);
	background-color: rgb(8, 8, 8);
	opacity: 1;
	z-index: 50;
	
}
#sunup{
	font-size:50%;
	margin-top:1%;
}
}
@media only screen and (max-width: 1000px) and (min-width: 500px){
	#downer{
	font-size: 80%;
	margin-top:1.5%;
}
#dicetv{
	font-size:80%;
	margin-top:-1%;
}
}

@media only screen  and (max-width:2000px)   and (min-height:700px) and (min-width: 1610px){
	#discuptt{
	font-weight: 500;
	font-size:200%;
}
#margins{
	font-size:150%;
}
#dice{
	font-size:135%;
}
#discb{
	font-size:70%;
	margin-top:-.75%;
	margin-right:2%;
}

#foe{
	font-size:180%;
	margin-top:0.5%;
}
}
@media only screen and (max-height: 749px){
	#getupp{
		margin-top:-3.5%;
}
}

</style>
