<template>

<html>
<head>
	<meta name='viewport' content="width=device-width, initial-scale=1">
  
</head>
<body>

	<div id="threediv"></div>

    <div id="discs" v-if='hithin < 800'>

</div>
    

    <div id="disc">

    </div>
	<div id='downcard' v-if='campaign != "custom" && campaign != "waitlist"' ><VCardSubtitle id='boldfs' class='text-wrap'>Unlimited Money Generator © 2024 <button >Contact, support@unlimitedmoneygenerator.com for fast support.</button></VCardSubtitle></div>
	<div id='downcards' v-if='campaign == "custom"  '><VCardSubtitle id='boldfs'>Unlimited Money Generator © 2024</VCardSubtitle></div>
    <div id="droppedclass">
		<VCard id='worldview' v-if="worlds != ''">
			<VCard id="world"><img id="blind" v-if='worlds=="goku"'  v-bind:src="require('../assets/goku-house.jpg')"/>
				<img id="blind" v-if='worlds=="namek"' v-bind:src="require('../assets/francisco.jpg')"/></VCard>
			<VCardTitle id="wvv">World View</VCardTitle>
			<VCard id="clone"><VCardTitle id="marginu"><button @click="worlds=''">Escape</button></VCardTitle></VCard>
			<VCard id="clones"><VCardTitle id="marginu"><button  @click="iwannabuy">Accept</button></VCardTitle></VCard>
			<VCard id="worldinfo">
				<div id="environment" v-if="worlds == 'goku'" >
					<VCardTitle id="right">Environment</VCardTitle>
					<VCardSubtitle id="rights">Information</VCardSubtitle>
					<VCardTitle id='dosum'>Payout Rate</VCardTitle>
					<VCardSubtitle id="gorights">Sores: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Chumps: <button id="tag">X1</button> Chumps Disperse: <button id="tag">X1</button></VCardSubtitle>
					

					<VCardTitle  id='dosum'>World Settings</VCardTitle>
					<VCardSubtitle id="gorights">Order Cost: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Luck: <button id="tag">X1</button> Exp: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Planet: <button id="tag">Goku's Planet</button> <label id="margins">Intermission Time:</label> <button id="tag">10 M</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Difficulty: <button id="tag">X1</button> <label id="margins">Force Luck Difficulty:</label> <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">PTR or RTP: <button id="tag">99%</button> <label id="margins">Max Level:</label> <button id="tag">320</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" >Sores Minimum: <button id="tag">99%</button> <label id="margins" >Max Data Level:</label> <button id="tag">80</button></VCardSubtitle>

					<VCardTitle  id='dosum'>World Data</VCardTitle>
					<VCardSubtitle id="gorights">World Link: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Season: <button id="tag">1</button> Method: <button id="tag">Gambling + Trading +Lottery</button></VCardSubtitle>
					<VCardTitle  id='dosum'>Pack</VCardTitle>
					<VCardSubtitle id="gorights">Tayton's X200 Trading X Pack:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">200</button> Orders</VCardSubtitle>
					<VCardSubtitle id="gorights">Tayton's X500 Trading X Pack:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">500</button> Orders</VCardSubtitle>
				
				</div>

				<div id="environment" v-if="worlds == 'namek'">
					<VCardTitle id="right">Environment</VCardTitle>
					<VCardSubtitle id="rights">Information</VCardSubtitle>
					<VCardTitle  id='dosum'>Payout Rate</VCardTitle>
					<VCardSubtitle id="gorights">Sores: <button id="tag">X5</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Chumps: <button id="tag">X5</button> Chumps Disperse: <button id="tag">X1</button></VCardSubtitle>
					

					<VCardTitle  id='dosum'>World Settings</VCardTitle>
					<VCardSubtitle id="gorights">Order Cost: <button id="tag">X15</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Luck: <button id="tag">X1</button> Exp: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Planet: <button id="tag">Namek</button> <label id="margins">Intermission Time:</label> <button id="tag">10 M</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Difficulty: <button id="tag">X1</button> <label id="margins">Force Luck Difficulty:</label> <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">PTR or RTP: <button id="tag">99%</button> <label id="margins">Max Level:</label> <button id="tag">320</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" >Sores Minimum: <button id="tag">99%</button> <label id="margins">Max Data Level:</label> <button id="tag">80</button></VCardSubtitle>

					<VCardTitle  id='dosum'>World Data</VCardTitle>
					<VCardSubtitle id="gorights">World Link: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Season: <button id="tag">1</button> Method: <button id="tag">Gambling + Trading +Lottery</button></VCardSubtitle>
					<VCardTitle  id='dosum'>Pack</VCardTitle>
					<VCardSubtitle id="gorights">Tayton's X5 Distribution Challenge:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">1</button> Order</VCardSubtitle>
				</div>
				<VCardTitle id="wv">Encyclopedia</VCardTitle>
				
				
				<VCardTitle id="tw"  v-if='worlds == "goku"'>Tayton's X1 Distribution Center </VCardTitle>
				<VCardTitle id="tw" v-if='worlds == "namek"'>Tayton's X5 Distribution Center </VCardTitle>
				<VCardSubtitle id='tww' class="text-wrap" v-if='worlds == "goku"'>Home to one of the most rewarding trading centers. Allowing humans across the globe to generate revenue. A calm, peaceful trading environment, in which humans can profit very easily. Graphs, and complex knowledge has become extinct, being the humans do not need to study to profit anymore.</VCardSubtitle>
				<VCardSubtitle id='tww' class="text-wrap" v-if='worlds == "namek"'>Home to one of the most rewarding trading centers. Allowing humans across the globe to generate revenue. A calm, peaceful trading environment, in which humans can profit very easily. Graphs, and complex knowledge has become extinct, being the humans do not need to study to profit anymore.</VCardSubtitle>
				<VCardTitle v-if='worlds == "namek"'>Planet: Namek </VCardTitle>
				<VCardTitle v-if='worlds == "goku"'>Planet: Vegeta </VCardTitle>
				<VCard id="planet" v-if="worlds == 'namek'"><img id="blind" v-bind:src="require('../assets/francisco.jpg')"/></VCard>
				<VCard id="planet" v-if="worlds == 'goku'"><img id="blind" v-bind:src="require('../assets/goku-house.jpg')"/></VCard>
				<VCardSubtitle v-if='worlds == "namek"' id='twww' class="text-wrap">Namek (ナメック星せい Namekku-sei, lit. "Planet Namek") is a planet in a trinary star system[2] located at coordinates 9045XY within Universe 7.[3] It is the home planet of the Nameless Namekian, and Dende, along with other Namekians.

The planet was destroyed by the wrath of Frieza on December 24, Age 762. The Namekian people were relocated to New Namek after being refugees on Earth for roughly a year.</VCardSubtitle>
<VCardSubtitle v-if='worlds == "goku"' id='twwwx' class="text-wrap"><label id='greenbeanss'>Goku's home planet is Planet Vegeta, also known as Planet Plant before the Saiyans relocated there from Planet Sadala. Planet Vegeta is home to Goku, Vegeta, and other Saiyans, Tuffles, and the Inhabitants of Plant. However, before Goku is born, Frieza destroys Planet Vegeta on Beerus's orders, and Goku is sent to Earth as an infant. Goku and Vegeta, along with some of the last pure-blooded Saiyans and their hybrid children, eventually make Earth their new home. 
</label></VCardSubtitle>
<VCardTitle id="tw">Challenges </VCardTitle>
<VCardSubtitle v-if="worlds=='goku'" id='og'>(Tayton's X1 Distribution Challenge)</VCardSubtitle>
<VCardSubtitle v-if="worlds=='namek'" id='og'>(Tayton's X5 Distribution Challenge)</VCardSubtitle>
				<VCard id="icon"><img id="blinds" v-if="worlds=='goku'" v-bind:src="require('../assets/1180800R.jpg')"/>
					<img id="blinds" v-bind:src="require('../assets/1175939SR.jpg')" v-if="worlds=='namek'"/></VCard>
			</VCard>

			
		</VCard>
		<VCard id="preorderclose"   v-if="showpre && worlds == ''"><VCardTitle id="goupppp"><button @click="showpre = false">Close</button></VCardTitle></VCard>
		<VCard id="preorderpurchase" v-if="showpre && wannabuy">
			<VCardTitle >Purchase</VCardTitle>
			<VCardSubtitle >Please enter your email to receive your confirmation keys.</VCardSubtitle>
			
			<div id="goflashs">
			<v-text-field v-model='purchaselabel' label="Email" id="goflash"></v-text-field>
			</div>
			<VCardSubtitle  id='grow' class='text-wrap'>A random 32 digit key will be sent to your email. It will be activated once your transaction is confirmed.</VCardSubtitle>
			<VCard id='accept'><VCardSubtitle ><button id='groww' @click='go_grab_key' v-if='!sentkey'>Send Key</button><button v-if="sentkey" id='groww'>Sent Key</button></VCardSubtitle> </VCard>
			<div v-if='chalone'>
			<VCardTitle id='godownse'>Tayton's X500 Trading X Challenge</VCardTitle>
			<VCardSubtitle id="controlshqqqq">Included</VCardSubtitle>
			<VCardSubtitle id="controlshqqqqq" class='text-wrap' >Tayton's X500 Trading X Pack.</VCardSubtitle>
			<VCardSubtitle id="gotolil">500 Orders for Tayton's Trading Center (500$).</VCardSubtitle>
			<VCardSubtitle id="gotolil">5 Random Capture Cards.</VCardSubtitle>
			<VCardSubtitle id="gotolil">Access to- demo mode.</VCardSubtitle>
			
			<VCardSubtitle id="gotolil" class='text-wrap'>Your purchase is used to place orders for you.  All money will be sent to the  economy.</VCardSubtitle>
			</div>
			<div v-if='!chalone'>
			<VCardTitle id='godownse'>Tayton's X200 Trading X Challenge</VCardTitle>
			<VCardSubtitle id="controlshqqqq">Included</VCardSubtitle>
			<VCardSubtitle id="controlshqqqqq" class='text-wrap'>Tayton's X200 Trading X Pack.</VCardSubtitle>
			<VCardSubtitle id="gotolil">200 Orders for Tayton's Trading Center (200$).</VCardSubtitle>
			<VCardSubtitle id="gotolil">5 Random Capture Cards.</VCardSubtitle>
			<VCardSubtitle id="gotolil">Access to- demo mode.</VCardSubtitle>
			<VCardSubtitle id="gotolil" class='text-wrap'>Your purchase is used to place orders for you. All money will be sent to the economy.</VCardSubtitle>

			
			</div>
			<VCard id='accept' v-if="sentkey" @click='generatedaddress = true'><VCardSubtitle ><button >Generate Address</button></VCardSubtitle> </VCard>

			<VCard id='accepted' v-if='generatedaddress'><VCardSubtitle id='carbon'>{{thataddress}}</VCardSubtitle> </VCard>
			<VCard id='acceptedd' @click="monu"><VCardSubtitle id='ortega'><button>Close this Purchase Screen</button></VCardSubtitle> </VCard>
			
			<VCardSubtitle id='acceptqrr' v-if="timer != ''">Expires:{{ (timer/3600).toFixed(2) }} H</VCardSubtitle>
			<VCard id='acceptqr'><img id="qrcode" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + thataddress" /></VCard> 
			

		</VCard>
		<VCard id="preorder" v-if="showpre">
			
			<img id="examplars" v-bind:src="require('../assets/firma.jpg')"/>
			
			<VCard id="examplarsbox" >
				
				<VCardTitle id="goright">Challenges</VCardTitle>

				<VCard id="preorderone" @mouseover="chalone = false" @click="worlds='goku'">
					<img id="examplarss" v-bind:src="require('../assets/goku-house.jpg')"/>
					<div id='mango'>
					<VCardTitle id="preorderlabelsss">Tayton's X200 Trading X Challenge</VCardTitle>
					<VCardTitle id="preorderlabelss">Profitability: Moderate</VCardTitle>
					<VCardTitle id="preorderlabels">200$ / 200 Orders.</VCardTitle>
					<VCardTitle id="preorderlabel">Goku's Planet</VCardTitle>
					</div>
				</VCard>
				<VCard id="preordertwo" @mouseover="chalone = true" @click="worlds='goku'">
					<img id="examplarss" v-bind:src="require('../assets/francisco.jpg')"/>
					<div id='mango'>
					<VCardTitle id="preorderlabelsss">Tayton's X500 Trading X Challenge</VCardTitle>
					<VCardTitle id="preorderlabelss">Profitability: Easy</VCardTitle>
					<VCardTitle id="preorderlabels">500$ / 500 Orders.</VCardTitle>
					<VCardTitle id="preorderlabel">Namek</VCardTitle>
					</div>
				</VCard>

				
				
				<VCard id="example" v-if="!chalone"><img id="examplarsxg" v-bind:src="require('../assets/1180800R.jpg')"/>
					<VCardTitle id='cern' class='text-wrap'></VCardTitle>
					<VCard id="buy"><VCardTitle id="goup"><button @click="wannabuys">Purchase</button></VCardTitle></VCard></VCard>
					<VCard id="example" v-if="chalone"><img id="examplarsxg" v-bind:src="require('../assets/1175939SR.jpg')"/>
					<VCardTitle id='cern' class='text-wrap'></VCardTitle>
					<VCard id="buy" ><VCardTitle id="goup"><button @click="wannabuys" >Purchase</button></VCardTitle></VCard></VCard>
				</VCard>
				
				
			<VCardTitle id="width">Details
				<VCard id="thesize" v-if="!chalone" class='text-wrap' >Tayton's X200 Trading X Challenge</VCard>
				<VCard id="thesize" v-if="chalone" class='text-wrap' >Tayton's X500 Trading X Challenge</VCard>
				<VCardSubtitle class='text-wrap' id='goupgv'>Access to Demo</VCardSubtitle>
				<VCardSubtitle  v-if="!chalone" id='goupgv'>200 Orders for 200$</VCardSubtitle>
				<VCardSubtitle v-if="chalone" id='goupgv'>500 Orders for 500$</VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='goupgv'>Guaranteed Registration Slot.</VCardSubtitle>
				
			</VCardTitle>
			<div id="gohere">

				<div id="toright">
					<VCardTitle>Pre-Order</VCardTitle>
					<VCardSubtitle class="text-wrap" id="gouppp">All purchases will be used to fund your economy for you in the corresponding worlds.</VCardSubtitle>
					<VCardSubtitle class="text-wrap" id="atad">There is a small 1% fee on all winnings.</VCardSubtitle>
				</div>

			</div>
			

			<VCard id="preorderbox" v-if='!chalone'><img id="examplarsx" v-bind:src="require('../assets/1180800R.jpg')"/></VCard>
			<VCard id="preorderbox" v-if='chalone'><img id="examplarsx" v-bind:src="require('../assets/1175939SR.jpg')"/></VCard>
			<div id="preorderbottom">
				<VCard id="buy"><VCardTitle></VCardTitle></VCard>
			</div>

		</VCard>
		</div>





		

		
		<div id='droppedclassx' >

		<div id='zindex'>
		<VCardTitle id='surf'>Unlimited Money Generator</VCardTitle>
		<VCardSubtitle id="sizing" class='text-wrap'><button @click="campaign='smith'" id='greense'>E:2025</button>  <button id="margin" @click='campaign="custom"'>Economies</button><button id="margin" @click="campaign='waitlist'">Open-Access</button><button id="margin" @click="campaign='social'">Social Media</button><button id="margin" @click="showpre = !showpre">Pre-Order</button><button id="margin" @click="$router.push({path:'/generator/Holy-Grail'})">Campaign</button><button id="margin" @click="$router.push({path:'/generator/E:2025'})">Trading X</button></VCardSubtitle>
		<div v-if="campaign =='custom'" >
			<div id='gorightsee' width='100'>
				<VCard id="iconegg"><img id="blindsgy" v-bind:src="require('../assets/1639507SR.jpg')"/>
				</VCard><VCardSubtitle id="bold"><label id='gf'><label id='ignf'> Distribution Centers</label></label></VCardSubtitle>
				<VCardSubtitle id="upin" class='text-wrap' v-if='within > 1100'><button id='widthu'> Buy distribution challenges to pre-order in a specific world..</button></VCardSubtitle>
				<VCardSubtitle id="upin" class="text-wrap" v-if='within <= 1100' ><button id='raport'> Buy distribution challenges to pre-order in a specific world..</button></VCardSubtitle>
				<VCard id="icone" @click='worlds = "goku"'><img id="blindsg"  v-bind:src="require('../assets/1180800R.jpg')"/>
				</VCard>

				<VCard id="icones" @click='worlds = "namek"'><img id="blindsg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>
			<div id='saints' @click='magic'>
				<VCard id="iconess"><img id="blindsgg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>

				<VCard id="iconess"  @click="move "><img id="blindsgg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>
				
				<VCardTitle id="">Pre Order Now and Get:</VCardTitle>
				
				<VCardSubtitle id="dpe">Access to Demo Mode</VCardSubtitle>
				<VCardSubtitle id="dpe">500 Orders (500$)</VCardSubtitle>
				<VCardSubtitle id="bold"><label id='gf'>Guaranteed Registration </label></VCardSubtitle>
			
			</div>
			

			</div>
			<div id="sidealldownf"></div>

		
		
		<div id="con">
		<VCardTitle id='baeug'>Custom Economies (18+)</VCardTitle>
		<VCardSubtitle id='greensee' class="text-wrap">Economies that can increasingly grow bigger, likewise producing, more frequent and larger revenue. The economies having the capability to generate an unlimited amount of money. </VCardSubtitle>
		<VCardSubtitle id='greent'>Humans Looking For Financial Freedom</VCardSubtitle>
		<VCardSubtitle id='green'>Primary Source of Income</VCardSubtitle>
		
		<div id="sideonec">
			<VCard id="wickedc"><img id="examplarsxvj" v-bind:src="require('../assets/house.jpg')"/>
			
		</VCard>
		<VCardTitle  id='greenbeanss'>Customizing</VCardTitle>
		<VCard id="linessgg">s</VCard>
		<VCardSubtitle id='greenbeans' class='text-wrap'>Create Custom Gambling, Trading, Staking, Lottery, Distribution/Investment Economies </VCardSubtitle>
		<VCardSubtitle id='greenbeansb' class='text-wrap'>Create unique economies humans can generate small to large amounts of revenue with.</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class='text-wrap'>Choose how you want the economy to operate and configure it based on the prefferred style of profiting.</VCardSubtitle>
		<VCardSubtitle id='greenbeansb' class='text-wrap'>The economy will send all the revenue generated during the session back to its' participants randomly.</VCardSubtitle>
		<VCardSubtitle id='greenbeansb' class='text-wrap'>As the size of the economy increases, the size of the payments will keep increasing (1:1 Ratio). Humans can quickly build their own affordable economies, they can then use as a source of income.  </VCardSubtitle>
		<VCardSubtitle  id='greenbeansb' class='text-wrap'>There are no limits on how much a human can be paid.</VCardSubtitle>
		<VCardSubtitle  id='greenbeansb' class='text-wrap'>There are no limitations on the amount of money that can be available in an economy. </VCardSubtitle>
		<VCardSubtitle  id='greenbeansb' class='text-wrap'>The system is helping you to profit.</VCardSubtitle>
		
		<VCardSubtitle id='greenbeans' class='text-wrap'>Users will be connected to a large amount of money consistently if the economy is productive.</VCardSubtitle>
		<VCardSubtitle id='greenbeansb' class='text-wrap'>Ultimately these sessions can become, extremely profitable based on how much money is in the economy.</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class='text-wrap'>There are no hidden fees. Humans can obtain as much money in the economy. Their winnings will only be taxed 1%.</VCardSubtitle>
		<VCard id="wickeddtwooooo"><img id="examplarsxvvvvzz" v-bind:src="require('../assets/1639168C.jpg')"/>

			</VCard>
			<VCardSubtitle id='boldgg'><label id='odel'>(Bulma)</label></VCardSubtitle>
			<VCardSubtitle id='godownsg' class='text-wrap'>So if there is a million dollars in the economy, there is a million dollars available!</VCardSubtitle>
			<VCardSubtitle id='godownsg'  class='text-wrap'>You can profit consistenly with these economies, by divvying that money up and paying many people for great amounts !</VCardSubtitle>
			<VCardSubtitle id='godownsg'  class='text-wrap'>Or if your a high risk type of guy you can customize the economy to only pay fewer people but for much more extreme amounts!</VCardSubtitle>
			<VCardSubtitle id='godownsg'  class='text-wrap'>The choice is yours..</VCardSubtitle>
		<VCard id="wickedcc"><img id="examplarsxvzz" v-bind:src="require('../assets/francisco.jpg')"/>
			
		</VCard>

		<VCardTitle  id='greenbeansssr' class='text-wrap'><button id='margin'>Control Payout Rate</button><button id='margin'>Control Season Time</button><button id='margin'>Control Splits</button><button id='margin'>Control Difficulty</button><button id='margin'>Control Luck</button><button id='margin'>Control Cost</button></VCardTitle>
		<VCardTitle id='gorightt'><button id='marginse'>Generate Revenue</button></VCardTitle>
		<VCardSubtitle id='gorightst' class='text-wrap'><button id='margin'>Staking</button><button id='marginse'>Investing</button><button id='marginse'>PvP</button><button id='marginse'>Lottery</button><button id='margin'>Distribution</button><button id='margin'>Gambling</button><button id='margin'>Trading</button><button id='margin'>Selling Data</button></VCardSubtitle>
		<VCardSubtitle id='gorighty' class='text-wrap'><button id='greeno'>99% RTP</button><button id='margin'>Earnings That Keep Growing</button><button id='margin'>Profitable Economies</button><button id='marginx'>Built For You to Win</button></VCardSubtitle>
		
		<VCardSubtitle id='greenbeansgo' class='text-wrap'>Users can control a variety of options, like how frequent they would like their worlds to payout.</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class='text-wrap'>Essentially allowing humans to create economies with each different purposes .</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class='text-wrap'>Controlling the options will determine the approach in profiting and the profitability.</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class="text-wrap">There can be massive amounts of money the economy is generating for players. All the money inside of an economy is liquidated and given back to the players of the world instantly.</VCardSubtitle>
		<VCardSubtitle id='greenbeans' class="text-wrap">The bigger the economy gets, the bigger the liquidated payments are. Allowing humans easy access to large amounts of money for little to nothing. All they have to do is wager a very small amount of their money and they have access to all the money in the economy. </VCardSubtitle>
		<VCardSubtitle id='greenbeans' class='text-wrap'>Create economies based on how you like making money. If your economy is successful, your world will begin to be visited by many humans. Creating a safe, fun, profitable enviroment for you to generate revenue within.</VCardSubtitle>
		
		
		<VCardSubtitle id='greenbeans' class='text-wrap'>There are estimated to be millions of combinations ultimately initiating different types of economies, you can learn/analyze to then profit better.</VCardSubtitle>
		<VCard id="linessg">s</VCard>
		<VCardTitle  id='greenbeansss'>Express Yourself</VCardTitle>
		<VCardSubtitle id='grow' class='text-wrap'>There is a rare chance a human will receive a 'Capture Card', upon winning money.</VCardSubtitle>
		

		<VCard id='angle'>
			<img id='zone' v-bind:src="require('../assets/francisco-segovia.jpg')"/>

			<VCard id='wickeddtwoh'><img id='with'  v-bind:src="require('../assets/ade.png')"/></VCard>

			<VCardSubtitle id='gorightgs' class='text-wrap'>Over 400+ Obtainable Capture Cards</VCardSubtitle>
		</VCard>

		<VCard id="wickedccc"><img id='wit'  v-bind:src="require('../assets/1660678R.jpg')"/>
			
		</VCard>
		<VCardSubtitle id='gorightg' class='text-wrap'>Mix of Rare and Common Capture Cards</VCardSubtitle>
	</div>
		</div>
		


		<div v-if="true">
		<div id="saint">
		<VCardTitle></VCardTitle>
		
	</div>
	</div>
		</div>
		
		<div v-if="campaign =='smith'" id="gomar" >

			

			<div id='gorightsee' width='100'>
				<VCard id="iconegg"><img id="blindsgy" v-bind:src="require('../assets/1639507SR.jpg')"/>
				</VCard><VCardSubtitle id="bold"><label id='gf'> <label id='ignf'>Distribution Centers</label></label></VCardSubtitle>
				<VCardSubtitle id="upin" class="text-wrap"><button id='raport'> Buy distribution challenges to pre-order in a specific world..</button></VCardSubtitle>
				
				<VCard id="icone" @click='worlds = "goku"'><img id="blindsg"  v-bind:src="require('../assets/1180800R.jpg')"/>
				</VCard>

				<VCard id="icones" @click='worlds = "namek"'><img id="blindsg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>
			<div id='saints' @click='magic'>
				<VCard id="iconess"><img id="blindsgg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>

				<VCard id="iconess"  @click="move "><img id="blindsgg" v-bind:src="require('../assets/1175939SR.jpg')" />
				</VCard>
				
				<VCardTitle id="">Pre Order Now and Get:</VCardTitle>
				
				<VCardSubtitle id="increasesc">Access to Demo Mode</VCardSubtitle>
				<VCardSubtitle id="increasesc">500 Orders (500$)</VCardSubtitle>
				<VCardSubtitle id="bold"><label id='gf'>Guaranteed Registration</label></VCardSubtitle>
			
			</div>
			

			</div>
			<VCardTitle id="hit">Trading (16+)</VCardTitle>
			<VCard id="line">s</VCard>
			<VCard id="smurf" height="100"><img id="gang" v-bind:src="require('../assets/firma.jpg')"/>
			
			<div id="absolute"><VCardTitle id='dec' >Trade Without Graphs or Complex Knowledge</VCardTitle>
				<VCardSubtitle id="goupx" ><label id='increase'>Basic trading. <label>Start making money within hours.</label></label></VCardSubtitle>
				<VCardSubtitle id='raportttt' class='text-wrap'><label id='increase'>Source of income for the less fortunate. Extreme profits for the dedicated.</label></VCardSubtitle>
				
			</div>
			
			
			</VCard>
		
		<div id="sideone">
			<VCard id="wicked" @click='sospecial = true'  v-if='hithin <= 500'><img id="examplarsxv" v-bind:src="require('../assets/francisco.jpg')"/>

				
				<div id="absolute">
				<v-infinite-scroll id='wingworm' height='300' >
				<VCardTitle >No Limits</VCardTitle>
				
				<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
				<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
				<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

				<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
				<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
				<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

				<VCardSubtitle id="godowns">All you have to do is learn how to play the game.</VCardSubtitle>
				<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
				<VCardSubtitle id="bold"><button>Generate revenue at the click of a button.</button></VCardSubtitle>


				<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
				<VCardSubtitle id="boldg"><button>Trading but inversed.</button></VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
				<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
				<VCardSubtitle ><button>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
				<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
				</v-infinite-scroll>

				
				</div>
		</VCard>

		<VCard id="wicked" v-if='hithin > 500' ><img id="examplarsxv" v-bind:src="require('../assets/francisco.jpg')"/>



			<div id="absolute">
			<v-infinite-scroll id='wingworm' height='630' v-if='within >= 1700' >
			<VCardTitle id='growwup' >No Limits</VCardTitle>
			
				
				
				
			
			
	
			
			
			<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
			<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
			<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

			<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
			<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

			<VCardSubtitle id="godowns">All you have to do is learn how to play the game.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
			<VCardSubtitle id="bold"><button>Generate revenue at the click of a button.</button></VCardSubtitle>


			<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
			<VCardSubtitle id="boldg"><button>Trading but inversed.</button></VCardSubtitle>
			<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
			<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
			<VCardSubtitle id='gottabe'><button>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
			<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
			</v-infinite-scroll>
			<v-infinite-scroll id='wingworm' height='500' v-if='within > 1400 && within < 1700' >
			<VCardTitle id='growwup' >No Limits</VCardTitle>
			
				
				
				
			
			
	
			
			
			<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
			<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
			<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

			<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
			<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

			<VCardSubtitle id="godowns">All you have to do is learn how to play the game.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
			<VCardSubtitle id="bold"><button>Generate revenue at the click of a button.</button></VCardSubtitle>


			<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
			<VCardSubtitle id="boldg"><button>Trading but inversed.</button></VCardSubtitle>
			<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
			<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
			<VCardSubtitle><button>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
			<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
			</v-infinite-scroll>

			<v-infinite-scroll id='wingworm' height='600' v-if='within <= 1400 && hithin > 760'>
			<VCardTitle id='growwup' >No Limits</VCardTitle>
			
				
				
				
			
			
	
			
			
			<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
			<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
			<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

			<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
			<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

			<VCardSubtitle id="godowns" class='text-wrap'>All you have to do is learn how to play the game.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
			<VCardSubtitle id="bold"><button id='get'>Generate revenue at the click of a button.</button></VCardSubtitle>


			<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
			<VCardSubtitle id="boldg"><button id='get'>Trading but inversed.</button></VCardSubtitle>
			<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
			<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
			<VCardSubtitle><button id='get'>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
			<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
			</v-infinite-scroll>

			<v-infinite-scroll id='wingworm' height='500' v-if='within <= 1400 && hithin <= 760'>
			<VCardTitle id='growwup' >No Limits</VCardTitle>
			
				
				
				
			
			
	
			
			
			<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
			<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
			<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

			<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
			<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

			<VCardSubtitle id="godowns" class='text-wrap'>All you have to do is learn how to play the game.</VCardSubtitle>
			<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
			<VCardSubtitle id="bold"><button id='get'>Generate revenue at the click of a button.</button></VCardSubtitle>


			<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
			<VCardSubtitle id="boldg"><button id='get'>Trading but inversed.</button></VCardSubtitle>
			<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
			<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
			<VCardSubtitle><button id='get'>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
			<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
			</v-infinite-scroll>
			</div>
			</VCard>


		<VCard id="wickedef" @click='sospecial=false' v-if='sospecial == true'  ><img id="examplarsxv" v-bind:src="require('../assets/francisco.jpg')"/>

				<div id="absolute">
					
					
					<VCard id='greengob' height='40' flat color='transparent'><img id="mentos" v-bind:src="require('../assets/1742245U.jpg')"/></VCard>
				<v-infinite-scroll id='wingworm' height='400' >
				<VCardTitle >No Limits</VCardTitle>
				
				<VCardSubtitle id="bold" >Affordable, Profitable Trading.</VCardSubtitle>
				<VCardSubtitle id="boldg" >Make Money With Little to No Income.</VCardSubtitle>
				<VCardSubtitle id="godowns">Source of Income.</VCardSubtitle>

				<VCardTitle id="widthit" class="text-wrap">Humans who have not made money will be prioritized to receive money.</VCardTitle>
				<VCardSubtitle id="godowns">As long as they keep playing.</VCardSubtitle>
				<VCardTitle id="widthitt" class="text-wrap">Every human will make money at some point.</VCardTitle>

				<VCardSubtitle id="godowns">All you have to do is learn how to play the game.</VCardSubtitle>
				<VCardTitle id="widthitt" class="text-wrap">And you will profit.</VCardTitle>
				<VCardSubtitle id="bold"><button>Generate revenue at the click of a button.</button></VCardSubtitle>


				<VCardTitle id="widthittg" class="text-wrap">Trading X</VCardTitle>
				<VCardSubtitle id="boldg"><button>Trading but inversed.</button></VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap'><button>Instead of waging large amounts of money for small gains, you will be waging small amounts of money for large gains.</button></VCardSubtitle>
				<VCardTitle id="widthitth" class="text-wrap">Stake the minimum amount of money for the largest amount of money available.</VCardTitle>
				<VCardSubtitle><button>Low Risk, High Reward <button id='bold'>Source of Income</button></button></VCardSubtitle>
				<VCardSubtitle id="boldg" class="text-wrap">No Large Deposits Required.</VCardSubtitle>
				</v-infinite-scroll>
				</div>

		</VCard>


		</div>
		<div id="sidetwo">
			<VCard id='getyoupp' height='40' v-if='within < 532'><VCardTitle id='idiots'>Generate Revenue</VCardTitle></VCard>
			<VCard id="wickedtwos">
			<div id="absolute">
				<v-infinite-scroll id='wingworm' v-if="hithin > 500" height='230'>
				<VCardTitle id='getyoup' >Generating Revenue</VCardTitle>
				<VCardSubtitle id="goupxxx" class='text-wrap'><label id='clearupp'>This platform is built for users to consistently make money.</label></VCardSubtitle>
				<VCardSubtitle  id='widthitttty' class='text-wrap'><label id='clearupp'>You can generate revenue simply by clicking buttons.</label></VCardSubtitle>
				<VCardSubtitle id='widthittttt' class='text-wrap'><label id='clearupp'>You will be connected to an economic network, and in order to receive payments from the network, the only requirement is you invest a small amount of your money.</label> </VCardSubtitle>
				<VCardTitle id='getyouppx'>Economies</VCardTitle>
				<VCardSubtitle id="goupxx" class="text-wrap"><label id='clearup'>Each world has a different economy.</label></VCardSubtitle>
				
				<VCardSubtitle id='green' class="text-wrap">Once you level up in this economy by winning/spending money you will have access to much more data. </VCardSubtitle>
				

				<VCardSubtitle id="boldg" class='text-wrap'>All worlds are built for you to profit.</VCardSubtitle>
				<VCardSubtitle id='green' class='text-wrap'>Depending on the amount of money in an economy, that will determine how much money you are able to make.</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'>You can make large amounts of money consistently depending on the economy.</VCardSubtitle>
				<VCardSubtitle id='green' class="text-wrap" >Economies can be considered stocks.<label id="bold"> These economies can become very profitable.</label> The only difference is these economies help the investors to profit.</VCardSubtitle>
				
				<VCardTitle id='getyouppx'>Profitability</VCardTitle>
				<VCardSubtitle  id="goupxx" class='text-wrap'><label id='clearup'>Data will be dispersed back to each human detailing how much money it costs to profit.</label></VCardSubtitle>
				<VCardSubtitle id='boldg'  class='text-wrap'>If you can buy your way in the market you will profit consistently.</VCardSubtitle>
				<VCardSubtitle id='green' class='text-wrap' >You can also sell crucial data like this for money on the side <label id='bold'> once you are a high enough level.</label></VCardSubtitle>
				<VCardSubtitle  id='widthitttty' class='text-wrap'>As a trader you will encounter losses, and gains. <label id='bold'>As long as you keep participating and the economy keeps growing, you are able to manipulate losses into gains easily.</label></VCardSubtitle>
				
				<VCardSubtitle id='boldg' class='text-wrap' >Large capital is not needed and at times, you will profit with the smallest amounts.</VCardSubtitle>
				<VCardSubtitle id='widthitttty' class='text-wrap' ><label id='clearupp'>This is the most rewarding system in the world. Users will get paid, and can generate large amounts of money without much knowledge at all.</label></VCardSubtitle>
				<VCardTitle id='getyouppx'>Minimal Risk</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='boldtre'><label id='clearup'>A trader in this system, is bound to profit.</label></VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' ><label id='clearup'>There are also ways to instantly counter your losses to gains, with the right amount of experience.</label></VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' ><label id='clearup'>The only reason you did not profit was because you stopped playing.</label></VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' ><label id='clearup'>(Users can customize their trading world to be high risk and high reward, low risk and low reward or a combination of the two. )</label></VCardSubtitle>
				</v-infinite-scroll>
			</div>

			<div id="absolute">
				<v-infinite-scroll id='wingworm' v-if="hithin < 500" height='130'>
				<VCardTitle >Generate Revenue</VCardTitle>
				<VCardSubtitle id="goupxxx" >This platform is built for users to consistently profit.</VCardSubtitle>
				<VCardSubtitle  id='widthitttty' class='text-wrap'>You can generate revenue simply by placing orders.</VCardSubtitle>
				<VCardSubtitle id='widthittttt' class='text-wrap'>You will be connected to an economic network, and in order to receive payments from the network, the only requirement is you invest a small amount of your money. </VCardSubtitle>
				<VCardTitle >Economies</VCardTitle>
				<VCardSubtitle id="goupxx" class="text-wrap">Each world has a different economy.</VCardSubtitle>
				
				<VCardSubtitle id='green' class="text-wrap">Once you level up in this economy by winning/spending money you will have access to much more data. </VCardSubtitle>
				

				<VCardSubtitle id="boldg" class='text-wrap'>All worlds are built for you to profit.</VCardSubtitle>
				<VCardSubtitle id='green' class='text-wrap'>Depending on the amount of money in an economy, that will determine how much money you are able to make.</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'>You can make large amounts of money consistently depending on the economy.</VCardSubtitle>
				<VCardSubtitle id='green' class="text-wrap" >Economies can be considered stocks.<label id="bold"> These economies can become very profitable.</label> The only difference is these economies help the investors to profit.</VCardSubtitle>
				
				<VCardTitle >Profitability</VCardTitle>
				<VCardSubtitle  id="goupxx" class='text-wrap'>Data will be dispersed back to each human detailing how much money it costs to profit.</VCardSubtitle>
				<VCardSubtitle id='boldg'  class='text-wrap'>If you can buy your way in the market you will profit consistently.</VCardSubtitle>
				<VCardSubtitle id='green' class='text-wrap' >You can also sell crucial data like this for money on the side <label id='bold'> once you are a high enough level.</label></VCardSubtitle>
				<VCardSubtitle  id='widthitttty' class='text-wrap'>As a trader you will encounter losses, and gains. <label id='bold'>As long as you keep participating and the economy keeps growing, you are able to manipulate losses into gains easily.</label></VCardSubtitle>
				
				<VCardSubtitle id='boldg' class='text-wrap' >Large capital is not needed and at times, you will profit with the smallest amounts.</VCardSubtitle>
				<VCardSubtitle id='widthitttty' class='text-wrap' >This is the most rewarding system in the world. Users will get paid, and can generate large amounts of money without much knowledge at all.</VCardSubtitle>
				<VCardTitle >Minimal Risk</VCardTitle>
				<VCardSubtitle  class='text-wrap' >A trader in this system, is bound to profit.</VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' >There are also ways to instantly counter your losses to gains, with the right amount of experience.</VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' >The only reason you did not profit was because you stopped playing.</VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' >(Users can customize their trading world to be high risk and high reward, low risk and low reward or a combination of the two. )</VCardSubtitle>
				</v-infinite-scroll>
			</div>
			
			
			<img id="examplarsxvh" v-bind:src="require('../assets/goku-house.jpg')"/>
			
		</VCard>
		
		<VCard id="wickedtwoss">
			<div id="absolute">
				<v-infinite-scroll id='wingworm'  v-if="hithin < 500" height='120'>
				<VCardTitle >Advantages</VCardTitle>
				<VCardSubtitle id="goupxx" class="text-wrap">Every time you use an advantage 99% of your money will be used to pay other humans in your world.</VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg">The more you level up in a world the stronger your advantages get.</VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg">In every world once max level you can use your advantage to profit consistently everytime.</VCardSubtitle>
				<VCardTitle id="gouph">Competition</VCardTitle>
				<VCardSubtitle class="text-wrap" id="boldr">You will be competing with humans all across the globe.</VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg">The more you play, the better you will get, likewise while gaining access to better rewards.</VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg">It is fair for every human no matter their financial status.</VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg">You do not need a large amount of money to profit, or to generate large amounts of revenue.</VCardSubtitle>
				</v-infinite-scroll>
			</div>
			
			<div id="absolute">
				<v-infinite-scroll id='wingworm' v-if="hithin >= 500" height='180'>
				<VCardTitle id='getyoup' >Advantages</VCardTitle>
				<VCardSubtitle id="goupxx" class="text-wrap"><label id='clearup'>Every time you use an advantage 99% of your money will be used to pay other humans in your world.</label></VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg"><label id='clearupp'>The more you level up in a world the stronger your advantages get.</label></VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg"><label id='clearupp'>In every world once max level you can use your advantage to profit consistently everytime.</label></VCardSubtitle>
				<VCardTitle id="gouph">Competition</VCardTitle>
				<VCardSubtitle class="text-wrap" id="boldr"><label id='clearup'>You will be competing with humans all across the globe.</label></VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg"><label id='clearupp'>The more you play, the better you will get, likewise while gaining access to better rewards.</label></VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg"><label id='clearupp'>It is fair for every human no matter their financial status.</label></VCardSubtitle>
				<VCardSubtitle class="text-wrap" id="boldg"><label id='clearupp'>You do not need a large amount of money to profit, or to generate large amounts of revenue.</label></VCardSubtitle>
				</v-infinite-scroll>
			</div>
			<img id="examplarsxvhh" v-bind:src="require('../assets/goku-house.jpg')"/>
			
		</VCard>
		<VCard id="wickedtwosss">
			
			<div id="absolute">
				<v-infinite-scroll v-if="hithin < 500" height="47.5">
				<VCardTitle >Transparency</VCardTitle>
				<VCardSubtitle id="boldr" class="text-wrap">It is extremely easy to profit in this system. That is because there is information everywhere with data you can interpret for a better chance of winning.</VCardSubtitle>
				
				</v-infinite-scroll>

				<v-infinite-scroll v-if="hithin >= 500">
				<VCardTitle id='getyouppp'>Transparency</VCardTitle>
				<VCardSubtitle id="boldr" class="text-wrap"><label id='clearup'>It is extremely easy to profit in this system. That is because there is information everywhere with data you can interpret for a better chance of winning.</label></VCardSubtitle>
				
				</v-infinite-scroll>
			</div>
			
			<img id="examplarsxvhhh" v-bind:src="require('../assets/goku-house.jpg')"/>
			
		</VCard>
	</div>
	
		<div id="sidethreedown" ><VCardTitle id='solo'>Gambling (18+) (99%)<button id='gole'><VCardSubtitle>Rtp</VCardSubtitle></button></VCardTitle>
			<VCard id="lines">s</VCard>
			<VCardSubtitle id='boldrd'>Enhanced Gambling X. <button id="bold"></button></VCardSubtitle>
			<VCardSubtitle id='green' class='text-wrap' v-if='hithin <= 500'>Profit while gambling consistently. Make much better financial choices, while gambling. <button id='boldgg'>(Click the image.)</button></VCardSubtitle>
			<VCardSubtitle id='green' class='text-wrap' v-if='hithin > 500'><label >Profit while gambling consistently. Make much better financial choices, while gambling. Gambling where you are guaranteed to win.</label></VCardSubtitle>
			
		</div>
		<div id="sidefour" >
			

		<VCard id="wickedcl" v-if='hithin > 500'  @click='$router.push("")'><img id="examplarsxvv" v-bind:src="require('../assets/dying-planet.jpg')"/>
				<div id="absolutey" >
					<v-infinite-scroll id='wingworm' height='760' v-if='within >= 1700 ' >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				
				<VCardSubtitle  id='boldg' class='text-wrap'><label id='drrr'>Low Risk Gambling for Large Rewards.</label></VCardSubtitle>
				<VCardTitle id='widthittyy'>Enhanced Gambling X</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Inevitable wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" ><label id='dr'>Profitable. Low Risk. Affordable</label></VCardSubtitle>
					<VCardTitle id='increasedr' >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'><label id='drr'>(Addicting)</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'><label id='dr'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</label></VCardSubtitle>
				
				<VCardSubtitle id="boldg" class='text-wrap' ><label id='dr'>And it's very easy to win large amounts of money.</label></VCardSubtitle>
				<VCardSubtitle id='green'  class='text-wrap' ><label id='dr'>Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' ><label id='dr'>There is no limit to the amount of money you can make.</label></VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' ><label id='dr'>Only a Small 1% Fee on All Winnings</label></VCardTitle>
				</div>
			</v-infinite-scroll>	
			<v-infinite-scroll id='wingworm' height='510' v-if='within > 1500 && within < 1700 ' >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				
				<VCardSubtitle  id='boldg' class='text-wrap'>Low Risk Gambling for Large Rewards.</VCardSubtitle>
				<VCardTitle id='widthittyy'>Enhanced Gambling X</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Inevitable wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" >Profitable. Low Risk. Affordable</VCardSubtitle>
					<VCardTitle id='increasedr' >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'>(Addicting)</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</VCardSubtitle>
				
				<VCardSubtitle id="boldg" class='text-wrap' >And it's very easy to win large amounts of money.</VCardSubtitle>
				<VCardSubtitle id='green'  class='text-wrap' >Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' >There is no limit to the amount of money you can make.</VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' >Only a Small 1% Fee on All Winnings</VCardTitle>
				</div>
			</v-infinite-scroll>
			<v-infinite-scroll id='wingworm' height='360' v-if='within <= 1000' >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				<div  id='saintit'	>
				<VCardSubtitle  id='boldg' class='text-wrap'><label id='fataa'>Low Risk Gambling for Large Rewards. </label></VCardSubtitle>
				</div>		
				<VCardTitle id='widthittyy'>Enhanced Gambling X</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'><label id='boldrr'>Inevitable wins.</label> </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" ><label id='fataaa'>Profitable. Low Risk. Affordable</label></VCardSubtitle>
					<VCardTitle >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'><label id='fataaa'>(Addicting)</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'><label id='fataa'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</label></VCardSubtitle>
				
				<VCardSubtitle id="boldg" class='text-wrap' ><label id='fataa'>And it's very easy to win large amounts of money.</label></VCardSubtitle>
				<VCardSubtitle id='green'  class='text-wrap' ><label id='fataa'>Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' ><label id='fataa'>There is no limit to the amount of money you can make.</label></VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' ><label id='fataa'>Only a Small 1% Fee on All Winnings</label></VCardTitle>
				</div>
			</v-infinite-scroll>
			<v-infinite-scroll id='wingworm' height='400' v-if='within <= 1500 && within > 1000' >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				<div  id='saintit'	>
				<VCardSubtitle  id='boldg' class='text-wrap'><label id='fataa'>Low Risk Gambling for <label id='evenlao'> Large Rewards. </label></label></VCardSubtitle>
				</div>		
				<VCardTitle id='widthittyy'>Enhanced Gambling X</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'><label id='boldrr'>Inevitable wins.</label> </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" ><label id='fataaa'>Profitable. Low Risk. Affordable</label></VCardSubtitle>
					<VCardTitle >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'><label id='fataaa'>(Addicting)</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'><label id='fataa'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</label></VCardSubtitle>
				
				<VCardSubtitle id="boldg" class='text-wrap' ><label id='fataa'>And it's very easy to win large amounts of money.</label></VCardSubtitle>
				<VCardSubtitle id='green'  class='text-wrap' ><label id='fataa'>Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</label></VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' ><label id='fataa'>There is no limit to the amount of money you can make.</label></VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' ><label id='fataa'>Only a Small 1% Fee on All Winnings</label></VCardTitle>
				</div>
			</v-infinite-scroll>
			
				</div>
				
		</VCard>

		<VCard id="wickedcl" v-if='hithin <= 500'  @click='snake = true'><img id="examplarsxvv" v-bind:src="require('../assets/dying-planet.jpg')"/>
				<div id="absolutey" >
				
			<v-infinite-scroll id='wingworm' height='370'  >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				
				<VCardSubtitle  id='boldg' class='text-wrap'>Low Risk Gambling for Large Rewards. </VCardSubtitle>
				<VCardTitle id='widthittyy'>Enhanced Gambling</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Inevitable wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" >Profitable. Low Risk. Affordable</VCardSubtitle>
					<VCardTitle >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'>(Addicting)</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</VCardSubtitle>
				<VCardSubtitle id="boldg" class='text-wrap' >And it's very easy to win large amounts of money.</VCardSubtitle>
				
				<VCardSubtitle id='green'  class='text-wrap' >Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' >There is no limit to the amount of money you can make.</VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' >Only a Small 1% Fee on All Winnings</VCardTitle>
				</div>
			</v-infinite-scroll>
			
			
			
				</div>
				
		</VCard>

		<VCard id="wickedcll" v-if='snake == true' @click='snake = false'><img id="examplarsxvv" v-bind:src="require('../assets/dying-planet.jpg')"/>
			<VCard id='fulfill'></VCard>
				<div id="absolutey" >
				
			<v-infinite-scroll id='wingworm' height='500'  >
				<VCardTitle id='raportt'>Stress-Free Gambling</VCardTitle>
				<VCardSubtitle id='controlshqqq' class='text-wrap'>Gamble but with much more awareness on where your money is traveling.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqq' class='text-wrap'>Win big frequently, knowing the system was built for you to win.</VCardSubtitle>
				<VCardSubtitle id='controlshqqqqq' class='text-wrap'>Recover your losses from casinos.</VCardSubtitle>
				
				<VCardSubtitle  id='boldg' class='text-wrap'>Low Risk Gambling for Large Rewards. </VCardSubtitle>
				<VCardTitle id='widthittyy'>Enhanced Gambling</VCardTitle>
				<VCardSubtitle  class='text-wrap' id='controlshqqqqqqq'>Gambling, but with much larger and rapid rewards. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Built for you to Win. </VCardSubtitle>
				<VCardSubtitle  id='controlshqq' class='text-wrap'>Much more consistent payouts. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Inevitable wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Find a consistent source of income while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Transparent systems allowing gamblers to make much wiser decisions while gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>No Limits on Jackpot. </VCardSubtitle>
				
				<VCardSubtitle  id='controlsh' class='text-wrap'>Potentially huge wins. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Larger wins more consistently. </VCardSubtitle>
				<VCardSubtitle  id='controlshq' class='text-wrap'>Control Risk of Gambling. </VCardSubtitle>
				<VCardSubtitle  id='controlsh' class='text-wrap'>Gamblers can customize their economies to be a mix of risk and reward fitting to their play-style. </VCardSubtitle>
				<div id="absolutets">
				<VCardSubtitle id="bold" >Profitable. Low Risk. Affordable</VCardSubtitle>
					<VCardTitle >High Reward Gambling</VCardTitle>
					<VCardSubtitle id='goupxx'>(Addicting)</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap'>Gamble for the biggest of rewards while wagering the smallest amounts of money.</VCardSubtitle>
				<VCardSubtitle id="boldg" class='text-wrap' >And it's very easy to win large amounts of money.</VCardSubtitle>
				<VCard id='greengob' height='40' flat color='transparent'><img id="mento" v-bind:src="require('../assets/1742274UC.jpg')"/></VCard>
				<VCardSubtitle id='green'  class='text-wrap' >Each world instantly pays back all the money put into it, ultimately providing gamblers with lucrative payments.</VCardSubtitle>
				<VCardSubtitle id="boldg"  class='text-wrap' >There is no limit to the amount of money you can make.</VCardSubtitle>
				<VCardTitle id='widthitty' class='text-wrap' >Only a Small 1% Fee on All Winnings</VCardTitle>
				</div>
			</v-infinite-scroll>
			
			
			
				</div>
				
		</VCard>

		<VCard id="wickedclll" v-if='snakes == true' @click='snakes = false'><img id="examplarsxvv" v-bind:src="require('../assets/dying-planet.jpg')"/>
			<VCard id='fulfill'></VCard>
			<div id="absoluteg" >
				<VCard id='greengob' height='50' flat color='transparent'><img id="mentoss" v-bind:src="require('../assets/1639566ED.jpg')"/></VCard>
				<v-infinite-scroll id='wingworm'  height='520'>
				<VCardTitle >Overview</VCardTitle>
				<VCardTitle >Luck Based System</VCardTitle>
				<VCardSubtitle id="boldggg" class='text-wrap' >Gamble while manipulating the odds in your favor.</VCardSubtitle>
				<VCardSubtitle id="boldgggg" class='text-wrap' >Manipulate your luck for a guaranteed way to win.</VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap' >You are guaranteed to win at some point, all losers are prioritized to win.</VCardSubtitle>
				<VCardTitle id='entice'>Large Rewards</VCardTitle>
				
				<VCardSubtitle class='text-wrap' id='boldggg' >All money that goes in the Unlimited Money Generator is liquidated.  </VCardSubtitle>
				<VCardTitle id='entice'>Many Ways to Win</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >There are many ways to profit, while gambling. There are no hidden tactics. The system is not trying to beat you, finding methods can ensure you are always able to make money. </VCardSubtitle>
				<VCardTitle id='entice'>Monetization</VCardTitle>
				<VCardSubtitle id='widthittn'>Sell data to players.</VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >By becoming a dedicated player in a world, you gain access to many benefits.  </VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >Once you are at a high enough level, you can begin selling data to other users to maximize your profits. </VCardSubtitle>
				<VCardTitle id='entice'>Transparency</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >The system will disperse information back to you so you are aware of everything going on. This will allow you to figure out the requirements for making money. </VCardSubtitle>
				</v-infinite-scroll>


				


				</div>
				
		</VCard>
		</div>
		<div id="sidefive" ><VCard id="wickedtwo" v-if='hithin < 500' @click='snakes = true'><img id="examplarsxvii" v-bind:src="require('../assets/dying-planet.jpg')"/>
			<div id="absoluteg" >
				<v-infinite-scroll id='wingworm'  height='520'>
				<VCardTitle  >Overview</VCardTitle>
				<VCardTitle  id='upper'>Luck Based System</VCardTitle>
				<VCardSubtitle id="boldggg" class='text-wrap' >Gamble while manipulating the odds in your favor.</VCardSubtitle>
				<VCardSubtitle id="boldgggg" class='text-wrap' >Manipulate your luck for a guaranteed way to win.</VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap' >You are guaranteed to win at some point, all losers are prioritized to win.</VCardSubtitle>
				<VCardTitle id='entice'>Large Rewards</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldggg' >All money that goes in the Unlimited Money Generator is liquidated.  </VCardSubtitle>
				<VCardTitle id='entice'>Many Ways to Win</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >There are many ways to profit, while gambling. There are no hidden tactics. The system is not trying to beat you, finding methods can ensure you are always able to make money. </VCardSubtitle>
				<VCardTitle id='entice'>Monetization</VCardTitle>
				<VCardSubtitle id='widthittn'>Sell data to players.</VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >By becoming a dedicated player in a world, you gain access to many benefits.  </VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >Once you are at a high enough level, you can begin selling data to other users to maximize your profits. </VCardSubtitle>
				<VCardTitle id='entice'>Transparency</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >The system will disperse information back to you so you are aware of everything going on. This will allow you to figure out the requirements for making money. </VCardSubtitle>
				</v-infinite-scroll>


				


			</div>



			

		</VCard>
	
	

		<VCard id="wickedtwo" @click='$router.push("")'  v-if='hithin > 500'><img id="examplarsxvii" v-bind:src="require('../assets/dying-planet.jpg')"/>
			<div id="absoluteg" >
				<v-infinite-scroll id='wingworm' height='230' v-if='within >= 1700'>
				<VCardTitle id='donp'  >Overview</VCardTitle>
				<VCardTitle id='forsure'>Luck Based System</VCardTitle>
				<VCardSubtitle id="boldggg" class='text-wrap' >Gamble while manipulating the odds in your favor.</VCardSubtitle>
				<VCardSubtitle id="boldgggg" class='text-wrap' ><label id='sun'>Manipulate your luck for a guaranteed way to win.</label></VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap' ><label id='sun'>You are guaranteed to win at some point, all losers are prioritized to win.</label></VCardSubtitle>
				<VCardTitle id='entice'>Large Rewards</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldggg' >All money that goes in the Unlimited Money Generator is liquidated.  </VCardSubtitle>
				<VCardTitle id='entice'>Many Ways to Win</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >There are many ways to profit, while gambling. There are no hidden tactics. The system is not trying to beat you, finding methods can ensure you are always able to make money. </VCardSubtitle>
				<VCardTitle id='entice'>Monetization</VCardTitle>
				<VCardSubtitle id='widthittn'><label id='sun'>Sell data to players.</label></VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >By becoming a dedicated player in a world, you gain access to many benefits.  </VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >Once you are at a high enough level, you can begin selling data to other users to maximize your profits. </VCardSubtitle>
				<VCardTitle id='entice'>Transparency</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >The system will disperse information back to you so you are aware of everything going on. This will allow you to figure out the requirements for making money. </VCardSubtitle>
				</v-infinite-scroll>
				<v-infinite-scroll id='wingworm' height='520' v-if='within > 1366 && within < 1700'>
				<VCardTitle id='donp'  >Overview</VCardTitle>
				<VCardTitle >Luck Based System</VCardTitle>
				<VCardSubtitle id="boldggg" class='text-wrap' >Gamble while manipulating the odds in your favor.</VCardSubtitle>
				<VCardSubtitle id="boldgggg" class='text-wrap' >Manipulate your luck for a guaranteed way to win.</VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap' >You are guaranteed to win at some point, all losers are prioritized to win.</VCardSubtitle>
				<VCardTitle id='entice'>Large Rewards</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldggg' >All money that goes in the Unlimited Money Generator is liquidated.  </VCardSubtitle>
				<VCardTitle id='entice'>Many Ways to Win</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >There are many ways to profit, while gambling. There are no hidden tactics. The system is not trying to beat you, finding methods can ensure you are always able to make money. </VCardSubtitle>
				<VCardTitle id='entice'>Monetization</VCardTitle>
				<VCardSubtitle id='widthittn'>Sell data to players.</VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >By becoming a dedicated player in a world, you gain access to many benefits.  </VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >Once you are at a high enough level, you can begin selling data to other users to maximize your profits. </VCardSubtitle>
				<VCardTitle id='entice'>Transparency</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >The system will disperse information back to you so you are aware of everything going on. This will allow you to figure out the requirements for making money. </VCardSubtitle>
				</v-infinite-scroll>
				<v-infinite-scroll id='wingworm' height='170' v-if='within <= 1366'>
				<VCardTitle id='upper'>Overview</VCardTitle>
				<VCardTitle  id='gty' >Luck Based System</VCardTitle>
				<VCardSubtitle id="boldggg" class='text-wrap' >Gamble while manipulating the odds in your favor.</VCardSubtitle>
				<VCardSubtitle id="boldgggg" class='text-wrap' >Manipulate your luck for a guaranteed way to win.</VCardSubtitle>
				<VCardSubtitle id='controls' class='text-wrap' ><label id='fatesd'>You are guaranteed to win at some point, all losers are prioritized to win.</label></VCardSubtitle>
				<VCardTitle id='entice'>Large Rewards</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldggg' >All money that goes in the Unlimited Money Generator is liquidated.  </VCardSubtitle>
				<VCardTitle id='entice'>Many Ways to Win</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >There are many ways to profit, while gambling. There are no hidden tactics. The system is not trying to beat you, finding methods can ensure you are always able to make money. </VCardSubtitle>
				<VCardTitle id='entice'>Monetization</VCardTitle>
				<VCardSubtitle id='widthittn'>Sell data to players.</VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >By becoming a dedicated player in a world, you gain access to many benefits.  </VCardSubtitle>
				<VCardSubtitle class='text-wrap' id='boldgggx' >Once you are at a high enough level, you can begin selling data to other users to maximize your profits. </VCardSubtitle>
				<VCardTitle id='entice'>Transparency</VCardTitle>
				<VCardSubtitle class='text-wrap' id='boldgggm' >The system will disperse information back to you so you are aware of everything going on. This will allow you to figure out the requirements for making money. </VCardSubtitle>
				</v-infinite-scroll>




			</div>



			

		</VCard>
	
	
	
	
	</div>
	</div>




		<div id="sideeightdown" ></div>
		<div id="sidealldown"></div>
		<div id="sidesevendown" >
		
		<div id='absolutedown' v-if='campaign =="smith"'>
			
			<VCardTitle id='goupsome'>Waitlist</VCardTitle>
		
			<VCardSubtitle id="green" class='text-wrap'>Please enter your email to be added to the waitlist, and entered into the newsletter for more information.</VCardSubtitle>
			<div id="goflashs" >
				<form @submit.prevent='submitwaiter'>
			<v-text-field label="Email" v-model="themail" id="goflash"></v-text-field></form>

			<VCardSubtitle class='text-wrap' v-if="thewait != ''">{{thewait}}</VCardSubtitle>
			</div>
			
			
			<VCardTitle id='godownsome' >Phases</VCardTitle>
			<VCard id='wide'><img id="adjust" v-bind:src="require('../assets/1639161C.jpg')"/></VCard>
			<VCardSubtitle id='greeng' class='text-wrap' >The top humans in the world will increase a phase every season.</VCardSubtitle>
			<VCardSubtitle id='boldg' class='text-wrap'><button id='goupinsize'>View Unlimited Money Generator 2024 Global Honorary.</button></VCardSubtitle>
			<VCard id="wickeddtwoo"><img id="examplarsxvz" v-bind:src="require('../assets/firma.jpg')"/>
				<div id='absolute'><VCardTitle id='control' class='text-wrap'>Each season lasts 3 months</VCardTitle>
				
				

				<VCardSubtitle id='controlss' class='text-wrap'><label id='increasess'>There are only 10 phases before a human can reach their final form.</label></VCardSubtitle>

				<VCardSubtitle id='controlse' class='text-wrap'><label id='increasess'>Your phase will associate you with a whole new class of humans.</label></VCardSubtitle>
				</div>
				
				
			
			
			</VCard>
			<VCard id="linessss">s</VCard>
			<VCardTitle id='margindown'><label id='hesus'>PvP (21+)</label></VCardTitle>
			<VCardSubtitle id='red' >Warning (High Risk - High Reward)</VCardSubtitle>
			<VCardSubtitle id='green' class='text-wrap'><label id='fate'>Competitive wagering against other users for their money.</label></VCardSubtitle>
			<VCardSubtitle id='greenss' class='text-wrap' >Skill based with many different ways to counter losses.</VCardSubtitle>
			
			<VCardSubtitle id='boldgg' ><label id='fates'>Profitable Trading Style <button id='marginse'>Very Lucrative Wins.</button></label></VCardSubtitle>
			<VCard id="wickeddtwoooo"><img id="examplarsxvvvvz" v-bind:src="require('../assets/1704916SR.jpg')"/>
				
			
			
				
				
				
			
			
		</VCard>
		<VCardSubtitle id='boldgg'><label id='fatess'>(Android 13)</label></VCardSubtitle>
		<VCardSubtitle id='godownsg'><label id='fatess'><label id='supper'>Try That Martingale Strategy !</label></label></VCardSubtitle>
			<VCardSubtitle id='bold' ></VCardSubtitle>
			<VCard id="wickeddtwooo"><img id="examplarsxvvv" v-bind:src="require('../assets/firma.jpg')"/>
				<div id='absolute'>
					
					<VCardTitle id='control' class='text-wrap'><label id='sunn'>Wagering</label></VCardTitle>
					<VCardSubtitle id='boldg'><label id='fata'><label id='sun'>Amounts up to 50,000$</label></label></VCardSubtitle>
					<VCardSubtitle id='boldgggx' class='text-wrap'>Trading but while betting against other users.</VCardSubtitle>
					<VCardSubtitle id="boldg"><label id='sun'>Profitable high-income gambling.</label></VCardSubtitle>
					<VCardSubtitle id="green" class="text-wrap"  v-if='within >= 1700'><label id='sun'>Double your money consistently ultimately allowing <br>you to find ways to profit big.</label></VCardSubtitle>
					<VCardSubtitle id="green" class="text-wrap" v-if='within < 1700'><label id='sun'>Double your money consistently ultimately allowing you to find ways to profit big.</label></VCardSubtitle>
					<VCardSubtitle id="boldgggx" class="text-wrap">Multiple tactics in winning.</VCardSubtitle>
					<VCardSubtitle id="boldgg" class="text-wrap"><label id='sun'>No limits on your bets or wins.</label></VCardSubtitle>
					
				</div>
				
				
			
			
			</VCard>

			
		</div>


		</div>

		
		
		<div id="sidesixdown" v-if='campaign=="smith"'>
			<VCardTitle id='greengg'>Wealth Distribution (14+)</VCardTitle><VCardSubtitle id='boldgreen'>Affordable <button id='marginse'>Profitable</button><button id='marginse'>Rewarding</button></VCardSubtitle><VCardSubtitle id='green' class='text-wrap'><label id='id'>Investment opportunities for all types of humans. Distributing wealth and financial freedom across the globe.</label> </VCardSubtitle>
			
			<VCard id="wickedddtwo"><img id="examplarsxvtt" v-bind:src="require('../assets/house.jpg')"/>

				<div id='absolute'><VCardTitle id='raporttt'><label id='hitup'>Better Opportunities for Everyone.</label></VCardTitle>
					<VCardSubtitle id='raporttt' class='text-wrap'><label id='falsehope'>Distribution systems, managing and dispersing money to humans in a logical manner, repairing damaged economies. Instead of providing a small amount of people with wealth, providing many people financial freedom.</label></VCardSubtitle>
				</div>
			</VCard>
			<VCard id="liness">s</VCard>
			<VCard id="wickeddtwo"><img id="examplarsxvt" v-bind:src="require('../assets/house.jpg')"/>
				<div id='absolute'><VCardTitle id='control' class='text-wrap'><label id='inceasesss'>Distribution Centers Spreading Wealth Across the Globe.</label></VCardTitle>
				
				<VCardSubtitle id='bold' class='text-wrap' v-if='within > 453'><label id='matrix'>Connecting Humans in Poverty to Large Sums of Money.</label></VCardSubtitle>
				<VCardSubtitle id='bold' class='text-wrap' v-if='within <= 453'><label id='matrix'>Connecting Humans in Poverty to <br>Large Sums of Money.</label></VCardSubtitle>
				<VCardSubtitle id='bold'><label id='matrix'>For as little as</label><VCardTitle id='bigfont'>1$</VCardTitle></VCardSubtitle>
				<VCardSubtitle id='greenbeans'>Rewarding investment plans accessible and low-priced.</VCardSubtitle>
				<VCardSubtitle id='boldg'><label id='increasesss'>As little as 1$ a day for large returns.</label></VCardSubtitle>
				
				<VCardTitle id='increases'><label id='goupnow'>Ultimately Curing Poverty</label></VCardTitle>
				<VCardSubtitle id='boldfr' class='text-wrap'>Spreading financial freedom to millions of humans.</VCardSubtitle>
				<VCardTitle id='control' class='text-wrap' ><label id='goupnow'>Preventing Recessions and Economic Crises from occurring across the Globe.</label></VCardTitle>
				<VCardSubtitle id='boldfr' class='text-wrap'>Decreasing crime in third world countries. Reducing Unhealthy Migration. </VCardSubtitle>
				<VCardTitle id='control' class='text-wrap' ><label id='goupnow'>Financial Freedom for Millions.</label></VCardTitle>
				<VCardSubtitle id='boldfr' class='text-wrap' >Business Owners<button id='margin'>Impoverished </button><button  id='margin'> Teenagers </button><button  id='margin'> </button></VCardSubtitle>
				<VCardTitle id='control' class='text-wrap' ><label id='goupnow'>Registration starts October 26th.</label></VCardTitle>
				<VCardSubtitle id='boldfr' class='text-wrap'>Start for as little as 1$/day or 30$/month. </VCardSubtitle>
				</div>
				
				
			
			
			</VCard>
		
		</div>


	</div>

		<div v-if="campaign =='waitlist'">
			
			<VCard id="heart" v-if='within <1700' height="400" flat color="transparent" ><img id="examplarsxvci" v-bind:src="require('../assets/power-firmas.jpg')"/></VCard>
			<VCard id="heart" v-if='within >= 1700' height="500" flat color="transparent" ><img id="examplarsxvci" v-bind:src="require('../assets/power-firmas.jpg')"/></VCard>
			<VCard id="wished" flat color="transparent" ><img id="examplarsxvc" v-bind:src="require('../assets/1754499SR.jpg')"/></VCard>
			
			<VCardTitle id='golo'>Open-Access (OA)</VCardTitle>
			<VCardSubtitle id='bold'>Waitlist</VCardSubtitle>
			<VCardSubtitle id="green" class='text-wrap'><label id='some'>Please enter your email to be added to the waitlist, and entered into the newsletter for more information.</label></VCardSubtitle>
			<div id="goflash">
				<form @submit.prevent='submitwaiter'>
			<v-text-field label="Email" v-model='themail' id="goflash"></v-text-field>
			<VCardSubtitle class='text-wrap' v-if="thewait != ''">{{thewait}}</VCardSubtitle>
			</form>
			</div>
			<div id="godown">
			<VCardTitle ><label id='thisthird'>July</label> </VCardTitle>
			<VCardSubtitle  id='thisthat'>Open - Access</VCardSubtitle>
			<VCardTitle id="goit" ><label id='thisthird'>October 26th</label></VCardTitle>
			<VCardSubtitle id='thisthat'>Registration Opens </VCardSubtitle>
			
			
			<VCardTitle id="goit"><label id='thisthird'>November 2nd</label></VCardTitle>
			<VCardSubtitle  id='thisthat'>Preliminary Exams Begin</VCardSubtitle>


		</div>

		
		</div>
		
		
		<div v-if="campaign == 'social'">
		
			<VCardTitle id="abs">Follow our socials to stay up to date!</VCardTitle>
			
			<VCard id="hearts"><img id="examplarsxvcii" v-bind:src="require('../assets/francisco.jpg')"/></VCard>

		
			<VCard id="heartsig" @click="twitter"><img id="scale" v-bind:src="require('../assets/X_logo.jpg')"/></VCard>

			<VCard id="heartsigs" @click="tiktok"><img id="scales" v-bind:src="require('../assets/Youtube_logo.png')"/></VCard>

			<VCard id="heartsigss" @click="insta"><img id="scaless" @click="insta" v-bind:src="require('../assets/tailor.png')"/></VCard>
			<div id="godown">


			

		</div>
		</div>
		
		
    </div>

		

	<div id='droppedclassd' v-if="showpre">

		

	
	<VCard id="preorder" v-if="showpre" >
			
			<img id="examplars" v-bind:src="require('../assets/firma.jpg')"/>
			
			<VCard id="examplarsbox" >
				
				<VCardTitle id="goright">Challenges</VCardTitle>

				<VCard id="preorderone" @mouseover="chalone = false" @click="worlds='goku'">
					<img id="examplarss" v-bind:src="require('../assets/goku-house.jpg')"/>
					
					<VCardTitle id="preorderlabelsss" class='text-wrap'>Tayton's X200 Trading X Challenge</VCardTitle>
					<div id='mango'>
					<VCardTitle id="preorderlabelss">Profitablity: Moderate</VCardTitle>
					<VCardTitle id="preorderlabels">200$ / 200 Orders.</VCardTitle>
					<VCardTitle id="preorderlabel">Goku's Planet</VCardTitle>
					</div>
				</VCard>
				<VCard id="preordertwo" @mouseover="chalone = true" @click="worlds='goku'">
					<img id="examplarss" v-bind:src="require('../assets/francisco.jpg')"/>
					
					<VCardTitle id="preorderlabelsss" class='text-wrap'>Tayton's X500 Trading X Challenge</VCardTitle>
					<div id='mango'>
					<VCardTitle id="preorderlabelss">Profitablity: High</VCardTitle>
					<VCardTitle id="preorderlabels">500$ / 500 Orders.</VCardTitle>
					<VCardTitle id="preorderlabel">Goku's Planet</VCardTitle>
					</div>
				</VCard>

				
				
				<VCard id="example" v-if="!chalone"><img id="examplarsxg" v-bind:src="require('../assets/1180800R.jpg')"/>
					<VCardTitle id='cern' class='text-wrap'></VCardTitle>
					<VCard id="buy"><VCardTitle id="goup"><button @click="wannabuys">Purchase</button></VCardTitle></VCard></VCard>
					<VCard id="example" v-if="chalone"><img id="examplarsxg" v-bind:src="require('../assets/1175939SR.jpg')"/>
					<VCardTitle id='cern' class='text-wrap'></VCardTitle>
					<VCard id="buy" ><VCardTitle id="goup"><button @click="wannabuys" >Purchase</button></VCardTitle></VCard></VCard>
				</VCard>
				
				
			<VCardTitle id="width">Details
				<VCard id="thesize" v-if="!chalone" class='text-wrap' >Tayton's Trading Center</VCard>
				<VCard id="thesize" v-if="chalone" class='text-wrap'>Tayton's Trading Center</VCard>
				<VCardSubtitle class='text-wrap'  id='goupgv'>Access to Demo Mode</VCardSubtitle>
				<VCardSubtitle  v-if="!chalone"  id='goupgv'>200 Orders for 200$</VCardSubtitle>
				<VCardSubtitle v-if="chalone" id='goupgv'>500 Orders for 500$</VCardSubtitle>
				<VCardSubtitle class='text-wrap'  id='goupgv'>Guaranteed Registration Slot.</VCardSubtitle>
				
			</VCardTitle>
			<div id="gohere">

				<div id="toright">
					<VCardTitle>Pre-Order</VCardTitle>
					<VCardSubtitle class="text-wrap" id="gouppp">All purchases will be used to fund your economy.</VCardSubtitle>
					<VCardSubtitle class="text-wrap" id="atad">There is a small 1% fee on all winnings.</VCardSubtitle>
				</div>

			</div>
			

			<VCard id="preorderbox" v-if='!chalone'><img id="examplarsx" v-bind:src="require('../assets/1180800R.jpg')"/></VCard>
			<VCard id="preorderbox" v-if='chalone'><img id="examplarsx" v-bind:src="require('../assets/1175939SR.jpg')"/></VCard>
			<div id="preorderbottom">
				<VCard id="buy"><VCardTitle></VCardTitle></VCard>
			</div>

		</VCard>


		<VCard id='worldview' v-if="worlds != ''">
			<VCard id="world"><img id="blind" v-if='worlds=="goku"'  v-bind:src="require('../assets/goku-house.jpg')"/>
				<img id="blind" v-if='worlds=="namek"' v-bind:src="require('../assets/francisco.jpg')"/></VCard>
			<VCardTitle id="wvv">World View</VCardTitle>
			<VCard id="clone"><VCardTitle id="marginu"><button @click="worlds=''">Escape</button></VCardTitle></VCard>
			<VCard id="clones"><VCardTitle id="marginu"><button  @click="iwannabuy">Accept</button></VCardTitle></VCard>
			<VCard id="worldinfo">
				<div id="environment" v-if="worlds == 'goku'" >
					<VCardTitle id="right">Environment</VCardTitle>
					<VCardSubtitle id="rights">Information</VCardSubtitle>
					<VCardTitle id='dosumm'>Payout Rate</VCardTitle>
					<VCardSubtitle id="gorights">Sores: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Chumps: <button id="tag">X1</button> Chumps Disperse: <button id="tag">X1</button></VCardSubtitle>
					

					<VCardTitle id='dosumm'>World Settings</VCardTitle>
					<VCardSubtitle id="gorights">Order Cost: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Luck: <button id="tag">X1</button> Exp: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Planet: <button id="tag">Goku's Planet</button> <label id="margins">Intermission Time:</label> <button id="tag">10 M</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Difficulty: <button id="tag">X1</button> <label id="margins">Force Luck Difficulty:</label> <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">PTR or RTP: <button id="tag">99%</button> <label id="margins">Max Level:</label> <button id="tag">320</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Sores Minimum: <button id="tag">99%</button> <label id="margins">Max Data Level:</label> <button id="tag">80</button></VCardSubtitle>

					<VCardTitle id='dosumm'>World Data</VCardTitle>
					<VCardSubtitle id="gorights">World Link: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'> Season: <button id="tag">1</button> Method: <button id="tag">Gambling + Trading + Lottery</button></VCardSubtitle>
					<VCardTitle id='dosumm'>Pack</VCardTitle>
					<VCardSubtitle id="gorights">Tayton's X200 Trading X Pack:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">200</button> Orders</VCardSubtitle>
					<VCardSubtitle id="gorights">Tayton's X500 Trading X Pack:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">500</button> Orders</VCardSubtitle>
				
				</div>

				<div id="environment" v-if="worlds == 'namek'">
					<VCardTitle id="right">Environment</VCardTitle>
					<VCardSubtitle id="rights">Information</VCardSubtitle>
					<VCardTitle id='dosumm'>Payout Rate</VCardTitle>
					<VCardSubtitle id="gorights">Sores: <button id="tag">X5</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Chumps: <button id="tag">X5</button> Chumps Disperse: <button id="tag">X1</button></VCardSubtitle>
					

					<VCardTitle id='dosumm'>World Settings</VCardTitle>
					<VCardSubtitle id="gorights">Order Cost: <button id="tag">X15</button></VCardSubtitle>
					<VCardSubtitle id="gorightse">Luck: <button id="tag">X1</button> Exp: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Planet: <button id="tag">Namek</button> <label id="margins">Intermission Time:</label> <button id="tag">10 M</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Difficulty: <button id="tag">X1</button> <label id="margins">Force Luck Difficulty:</label> <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>PTR or RTP: <button id="tag">99%</button> <label id="margins">Max Level:</label> <button id="tag">320</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Sores Minimum: <button id="tag">99%</button> <label id="margins">Max Data Level:</label> <button id="tag">80</button></VCardSubtitle>

					<VCardTitle id='dosumm'>World Data</VCardTitle>
					<VCardSubtitle id="gorights">World Link: <button id="tag">X1</button></VCardSubtitle>
					<VCardSubtitle id="gorightse" class='text-wrap'>Season: <button id="tag">1</button> Method: <button id="tag">Gambling + Trading +Lottery</button></VCardSubtitle>
					<VCardTitle id='dosumm'>Pack</VCardTitle>
					<VCardSubtitle id="gorights" class='text-wrap'>Tayton's X5 Distribution Challenge:  </VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">5</button> Capture Cards</VCardSubtitle>
					<VCardSubtitle id="gorightsd"><button id="tag">1</button> Orders</VCardSubtitle>
				</div>
				<VCardTitle id="wv">Encyclopedia</VCardTitle>
				
				
				<VCardTitle id="tw"  v-if='worlds == "goku"'>Tayton's Trading Center </VCardTitle>
				<VCardTitle id="tw" v-if='worlds == "namek"'>Tayton's X5 Distribution Center </VCardTitle>
				<VCardSubtitle id='tww' class="text-wrap" v-if='worlds == "goku"'>Home to one of the most rewarding trading centers. Allowing humans across the globe to generate revenue. A calm, peaceful trading environment, in which humans can profit very easily. Graphs, and complex knowledge has become extinct, being the humans do not need to study to profit anymore.  </VCardSubtitle>
				<VCardSubtitle id='tww' class="text-wrap" v-if='worlds == "namek"'>Home to one of the most rewarding trading centers. Allowing humans across the globe to generate revenue. A calm, peaceful trading environment, in which humans can profit very easily. Graphs, and complex knowledge has become extinct, being the humans do not need to study to profit anymore. </VCardSubtitle>
				<VCardTitle v-if='worlds == "namek"'>Planet: Namek </VCardTitle>
				<VCardTitle v-if='worlds == "goku"'>Planet: Vegeta </VCardTitle>
				<VCard id="planet" v-if="worlds == 'namek'"><img id="blind" v-bind:src="require('../assets/francisco.jpg')"/></VCard>
				<VCard id="planet" v-if="worlds == 'goku'"><img id="blind" v-bind:src="require('../assets/goku-house.jpg')"/></VCard>
				<VCardSubtitle v-if='worlds == "namek"' id='twww' class="text-wrap">Namek (ナメック星せい Namekku-sei, lit. "Planet Namek") is a planet in a trinary star system[2] located at coordinates 9045XY within Universe 7.[3] It is the home planet of the Nameless Namekian, and Dende, along with other Namekians.

The planet was destroyed by the wrath of Frieza on December 24, Age 762. The Namekian people were relocated to New Namek after being refugees on Earth for roughly a year.</VCardSubtitle>
<VCardSubtitle v-if='worlds == "goku"' id='twwwx' class="text-wrap"><label id='greenbeanss'>Goku's home planet is Planet Vegeta, also known as Planet Plant before the Saiyans relocated there from Planet Sadala. Planet Vegeta is home to Goku, Vegeta, and other Saiyans, Tuffles, and the Inhabitants of Plant. However, before Goku is born, Frieza destroys Planet Vegeta on Beerus's orders, and Goku is sent to Earth as an infant. Goku and Vegeta, along with some of the last pure-blooded Saiyans and their hybrid children, eventually make Earth their new home. 
</label></VCardSubtitle>
<div v-if='within > 500'>
<VCardTitle id="tw">Challenges </VCardTitle>
<VCardSubtitle v-if="worlds=='goku'" id='og'><label id='loud'>(Tayton's Trading X Challenge)</label> </VCardSubtitle>
<VCardSubtitle v-if="worlds=='namek'" id='og'><label id='loud'>(Tayton's X5 Distribution Challenge)</label></VCardSubtitle>
				<VCard id="icon" ><img id="blinds" v-if="worlds=='goku'" v-bind:src="require('../assets/1180800R.jpg')"/>
					<img id="blinds" v-bind:src="require('../assets/1175939SR.jpg')" v-if="worlds=='namek'"/></VCard>



				</div>
			</VCard>

	
		</VCard>
	
		<VCard id="preorderclose"   v-if="showpre && worlds == ''"><VCardTitle id="goupppp"><button @click="showpre = false">Close</button></VCardTitle></VCard>
		<VCard id="preorderpurchase" v-if="showpre && wannabuy">
			<VCardTitle id='is'>Purchase</VCardTitle>
			<VCardSubtitle id='inter'>Please enter your email to receive your confirmation keys.</VCardSubtitle>
			
			<div id="goflashs">
			<v-text-field v-model='purchaselabel' label="Email" id="goflash"></v-text-field>
			</div>
			<VCardSubtitle  id='grow' class='text-wrap'>A random digit key will be sent to your email. It will be activated once your transaction is confirmed.</VCardSubtitle>
			<VCard id='accept'><VCardSubtitle id='lineman'><button id='groww' @click='go_grab_key' v-if='!sentkey'>Send Key</button><button v-if="sentkey" id='groww'>Sent Key</button></VCardSubtitle> </VCard>
			<div v-if='chalone'>
			<VCardTitle id='godownse'>Tayton's X500 Trading X Challenge</VCardTitle>
			<VCardSubtitle id="controlshqqqq">Included</VCardSubtitle>
			<VCardSubtitle id="controlshqqqqq" class='text-wrap'>Tayton's X500 Trading X Pack.</VCardSubtitle>
			<VCardSubtitle id="gotolil">500 Orders for Tayton's Trading Center (500$).</VCardSubtitle>
			<VCardSubtitle id="gotolil">5 Random Capture Cards.</VCardSubtitle>
			<VCardSubtitle id="gotolil">Access to Demo Mode.</VCardSubtitle>
			<VCardSubtitle id="gotolil" class='text-wrap'>Your purchase is used to place orders for you.  All money will be sent to the  economy.</VCardSubtitle>
			<VCardSubtitle id="gotolilx" class='text-wrap' v-if='gotogot != ""'>{{ gotogot }}</VCardSubtitle>
			</div>
			<div v-if='!chalone'>
			<VCardTitle id='godownse'>Tayton's X200 Trading X Challenge</VCardTitle>
			<VCardSubtitle id="controlshqqqq">Included</VCardSubtitle>
			<VCardSubtitle id="controlshqqqqq" class='text-wrap' >Tayton's X200 Trading X Pack.</VCardSubtitle>
			<VCardSubtitle id="gotolil">200 Orders for Tayton's Trading Center (200$).</VCardSubtitle>
			<VCardSubtitle id="gotolil">5 Random Capture Cards.</VCardSubtitle>
			<VCardSubtitle id="gotolil">Access to Demo Mode.</VCardSubtitle>
			<VCardSubtitle id="gotolil" class='text-wrap'>Your purchase is used to place orders for you.  All money will be sent to the  economy.</VCardSubtitle>
			<VCardSubtitle id="gotolilx" class='text-wrap' v-if='gotogot != ""'>{{ gotogot }}</VCardSubtitle>
			</div>
			<VCard id='accept' v-if="sentkey" @click='generatedaddress = true'><VCardSubtitle><button id='groww'>Generate Address</button></VCardSubtitle> </VCard>

			<VCard id='accepted' v-if='generatedaddress && thataddress != ""'><VCardSubtitle id='gof'>{{thataddress}}</VCardSubtitle> </VCard>
			<VCard id='acceptedd' @click="monu" v-if='within >= 1700' height='30'><VCardSubtitle id='ortega'><button>Close this Purchase Screen</button></VCardSubtitle> </VCard>
			<VCard id='acceptedd' @click="monu" v-if='within < 1700'><VCardSubtitle id='ortega'><button id='linemans'>Close this Purchase Screen</button></VCardSubtitle> </VCard>
			<VCardSubtitle id='acceptqrr' v-if="timer != ''">Expires:{{ (timer/3600).toFixed(2) }} H</VCardSubtitle>
			<VCard id='acceptqr' v-if='sentkey && thataddress != ""'><img id="qrcode" :src="'https://image-charts.com/chart?chs=150x150&cht=qr&chl=' + thataddress" /></VCard> 


		</VCard>

	</div>

    <div id="secondrop">
    </div>
	
	

					
					
					

				


		


           


	<!--/<router-link to="/generator" id="isovertwo">Namekeee</router-link>--->


     
		<div v-if="!$store.state.isAuthenticated">
			<div v-if='wantshow'>
				<form id="form" @submit.prevent="logboy" v-if="lose">
					<div id="loginbox">
						<div id="backlogin">

						</div>

						<button id="uptop" type="button" @click="maker">REGISTER</button>

						<input id="username" v-model = "username" placeholder="username">

						

						<input id="password" v-model = 'password' placeholder="password">

						

						<div id="complete">

						</div>

						<button id="logtitle">LOGIN</button>
						<button id="forgotpasswordtitle" v-if="forgotten">FORGOT PASSWORD</button>

						
					</div>
				</form>


				<ul id="lucid" v-if="successs">
					
					<div id="li"><button @click = "closesuc" id="Accountcreated">{{success}}</button></div>
					
					
				</ul>

				<ul id="lucid" v-if="lying == true">
					<li v-for="error in errors" id="li">
						<button @click = "close" id="Accountcreated">{{error}}</button>
					</li>
				</ul>

				

				<ul id="lucid" v-if="successs">
					
					<div id="li"><button @click = "closesuc" id="Accountcreated">Account {{success}}</button></div>
					
					
				</ul>

				<ul id="lucid" v-if="lying == true">
					<li v-for="error in errors" id="li">
						<button @click = "close" id="Accountcreated">{{error}}</button>
					</li>
				</ul>
			</div>
		</div>
        

    </body>
    </html>
	
</template>

<script>
var x = 0
var y = 0
var teeths = false
var forgot = false
document.onmousemove = (ev) =>{
	//

	//console.log(ev.target)


	

}
import axios from 'axios'

export default{

	data(){
		return{
			within:0,hithin:0,campaign:'smith',showpre:false,worlds:'',chalone:true,chaltwo:false,wannabuy:false,sentkey:false,
			generatedaddress:false,purchaselabel:'',channel:'',thataddress:'',themail:'',thewait:'',timer:'',thetypeifany:'',
			snake:false,snakes:false,sospecial:false,gotogot:'',bias:'',suite:''
			
		}
	},

	mounted: async function() {		
		this.reload()
		setInterval(this.reload, 1000)
		
	
		this.CAPTURECARDSS()
		this.GETCARDS()
		this.GETAPROFILE()
		
		setInterval(this.getaddy, 5000)
		setInterval(this.go, 5000)
		this.within = window.innerWidth
		this.hithin = window.innerHeight
		this.grabkey()
		this.whatis()

		
	},

	methods:{
		monu(){
			this.thataddress = ''
			this.wannabuy = false
		},
	magic(){
	this.worlds = 'goku'
		this.showpre = true
		this.wannabuy = true
	},
		insta(){
			window.location.replace('https://www.instagram.com/unlimitedmoneygenerator/')
		},
		tiktok(){
			window.location.replace('https://www.youtube.com/@UnlimitedMoneyGenerator')
		},
		twitter(){

			window.location.replace('https://x.com/umoneygenerator/')

},
		whatis(){
			if(this.$route.params.id == 'home'){
				this.campaign = 'smith'
			}

			if(this.$route.params.id == 'economies'){
				this.campaign = 'custom'
			}

			if(this.$route.params.id == 'preorder'){
				this.campaign = 'custom'
				this.showpre = true
			}

			if(this.$route.params.id == 'social'){
				this.campaign = 'social'
				
			}

			if(this.$route.params.id == 'waitlist'){
				this.campaign = 'waitlist'
				
			}
		},

		go(){
			this.within = window.innerWidth
		this.hithin = window.innerHeight
		//console.log(this.hithin)

		},

		wannabuys(){
			if(  this.wannabuy == false){

			
			if (this.chalone == true && this.thetypeifany == 'frieza'){
					this.wannabuy = true
				}else{

					if (this.chalone == false && this.thetypeifany == 'goku'){
						this.wannabuy = true
					}else{
						this.wannabuy = true
						this.generatedaddress = false
						this.sentkey = false
						this.thataddress = false
						this.timer = false
					}
			}
		}
		},
		grabkey(){
			if(this.$route.name == 'about'){

			
			if(this.$route.params.id.length >= 29){

				const fromData = {
						'address':this.$route.params.id,
						
						
						
						
						

					}

				axios

					.post('/api/v1/thataddressfind/', fromData).then(response =>{
						
						this.showpre = true
						this.wannabuy = true
						this.generatedaddress = true
						this.purchaselabel = response.data.email
						this.sentkey = true
						this.thataddress = response.data.address
						this.thetypeifany = response.data.type
						this.suite = response.data.email
						this.bias = response.data.type
						
					}).catch(error => {
						if(error.response){

							this.$router.push({path:'/e2025/home'})
							
							
						}
					})
								
			}
			}
		},
		getaddy(){
			if(this.thataddress != '' && this.wannabuy == true){

			

				if (this.chalone == false){
					this.bias = 'goku'
				}else{
					this.bias = 'frieza'
				}
				const fromData = {
						'address':this.suite,
						'type':this.bias
						
						
						
						
						
						

					}

				axios

					.post('/api/v1/thataddress/', fromData).then(response =>{
						
						this.timer = response.data.timer
						this.thataddress = response.data.address
						if (response.data.type == 'goku'){
							this.chalone = false
						}else{
							this.chalone = true
						}
						 
						
						
					}).catch(error => {
						if(error.response){

							if(this.thataddress != '' && this.thataddress != undefined && this.thataddress != 'Please wait an address is being generated.'){
								
								window.location.reload()
							}
							
							
						}
					})
								}
		},
		submitwaiter(){
			const fromData = {
						'email':this.themail.toLowerCase()
						
						
						
						
						

					}

					axios

						.post('/api/v1/GOWAITLIST/', fromData).then(response =>{
							var self = this;
							this.thewait = response.data
							setTimeout(function() {
								self.thewait = ''
							}, 2500);
						
							
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},
		go_grab_key(){

			const typo = ''

			if (this.chalone == true){
				this.channel = 'frieza'
			}else{
				this.channel = 'goku'
			}
			this.purchaselabel = this.purchaselabel.toLowerCase();
			const fromData = {
						'email':this.purchaselabel,
						'type':this.channel
						
						
						
						

					}

					axios
						.post('/api/v1/GRAB_KEY/', fromData).then(response =>{
							
							
							this.sentkey = true
							
							
							
							if (response.data == 'Please enter a valid email address.'){

							}else{
								
								if (response.data.Address == undefined){
									
									this.thataddress = 'Please wait an address is being generated.'
									
								}else{
									this.thataddress = response.data.Address
								}
								

								
								this.suite = this.purchaselabel 
								
								
							}
							
							this.gotogot = response.data

							var self = this;
			
							setTimeout(function() {
									self.gotogot = ''

									if (response.data == 'Please enter a valid email address.'){

										self.sentkey = false
										//console.log('okay')
								
									}
								}, 2500);
							
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},
		regboy(){

		const fromData = {
			username: this.username,
			password: this.password,
			email: this.email,
			name:this.name,
			Age:this.age
		}

		axios
			.post('/api/v1/users/', fromData).then(response =>{
				//console.log(response)
				this.success = response.statusText
				this.successs = true
				this.terms = false
				//console.log(response.statusText)
				this.lose = true
				this.errors = ['Successfully Created an Account.']
				var self = this;
			
				setTimeout(function() {
						self.regable = false;
						self.loginable = true;
						self.resable = false;
					}, 2500);
				



			}).catch(error => {
				if(error.response){
					this.errors = error.response.data
					//console.log(error.response.data)
					this.lying = true
					this.terms = false
					var self = this;
			
					setTimeout(function() {
						self.lying = false;
						
					}, 2500);
					
					
					
				}
			})



		},
		iwannabuy(){
			if (this.chalone == true && this.thetypeifany == 'frieza'){
				this.worlds = ''
				this.wannabuy = true
			}else{

				if (this.chalone == false && this.thetypeifany == 'goku'){
					this.worlds = ''
					this.wannabuy = true
				}else{
					this.wannabuys()
					this.worlds = ''
					this.showpre = true
					this.wannabuy = true
				}


			}
			
		},
		preordervalue(){
			if (this.worlds == 'namek'){
				this.worlds = ''
				this.chalone = true
				this.showpre = true
			}else{
				this.worlds = ''
				this.chalone = false
				this.showpre = true
			}
		},
		move(){
			this.chalone= true
			this.showpre = true
		},
		reload(){
			this.status = localStorage.GOINGBACK
			//console.log(this.status,'idiot')
			if(this.$store.state.isAuthenticated){

			
				if(localStorage.GOINGBACK){
					//console.log('iwin')
					window.location.reload()
					localStorage.removeItem('GOINGBACK')
				}
			}

			if(!this.$store.state.isAuthenticated){

			
				if(localStorage.GOINGBACK){
					//console.log('iwin')
					window.location.reload()
					localStorage.removeItem('GOINGBACK')
				}
			}
			
					
					
				
				
			

		},

		jumpstart(){
			window.location.replace('https://unlimitedmoneygroup.com')
		},

		SELECT_CAPTURE_CARD(i){


			this.id = this.CAPTURECARDS[i].capture_id

			const fromData = {
						'token':this.$store.state.token,
						'id':this.id
						
						
						
						

					}

					axios
						.post('/api/v1/SELECTCARD/', fromData).then(response =>{
							
							
							
							
							this.GETAPROFILE()
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},
		

		getImage(imagePath) {
			return require(imagePath);

		},

		

		clear(){
			this.message = []
		},

		clears(){
			this.messages = []
		},
		
		reload(){
			this.status = localStorage.GOINGBACK
			//console.log(this.status,'idiot')
			if(this.$store.state.isAuthenticated){

			
				if(localStorage.GOINGBACK){
					//console.log('iwin')
					window.location.reload()
					localStorage.removeItem('GOINGBACK')
				}
			}

			if(!this.$store.state.isAuthenticated){

			
				if(localStorage.GOINGBACK){
					//console.log('iwin')
					window.location.reload()
					localStorage.removeItem('GOINGBACK')
				}
			}

			
					
					
				
				
			

		},

		GETCARDS(){
			const fromData = {
						'token':this.$store.state.token,
						'link':this.$route.params.id
						
						
						

					}

					axios
						.post('/api/v1/GETCARDS/', fromData).then(response =>{
							
							
							
							this.captureimage = 'http://127.0.0.1:8000/api/v1' +response.data.image
							//console.log(this.captureimage)
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},

		GETAPROFILE(){
			const fromData = {
						'token':this.$store.state.token,
						
						
						
						

					}

					axios
						.post('/api/v1/GETAPROFILE/', fromData).then(response =>{
							
							
							this.captureprofile = response.data
							this.pat = response.data.Bio
							this.recentlyjoin = response.data.Recent
							//console.log(this.captureprofile)

							if (this.recentlyjoin.length >= 1){

								this.recentlyjoinone = this.recentlyjoin[0]
								
							}

							if (this.recentlyjoin.length >= 2){

								this.recentlyjoinone = this.recentlyjoin[0]
								this.recentlyjointwo = this.recentlyjoin[1]

								}
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},

		CAPTURECARDSS(){

			const fromData = {
						'token':this.$store.state.token,
						'link':this.$route.params.id
						
						
						

					}

					axios
						.post('/api/v1/CAPTURECARDSG/', fromData).then(response =>{
							
							
							
							this.CAPTURECARDS = response.data
							
							
							
						}).catch(error => {
							if(error.response){

								
								
								
							}
						})
		},


		RESETPASS(){
			if(this.logconfirmpassword != this.logpassword){

				this.message = ['Both Passwords must match to continue']
				
			}else{
				const data = {
					'uid':this.$route.params.id,
					'token':this.$route.params.token,
					'new_password': this.logconfirmpassword
				}

				

				axios.post('api/v1/users/reset_password_confirm/',data)
					.then(response =>{
						//console.log(response.data)
						this.message = ['Successfully Reset Password']
						
						
						var self = this;
						
						setTimeout(function() {
							self.$router.push({ path: '/login' })
						}, 3000);



						
					})
					.catch(error =>{
						this.messages = error.response.data
						//console.log(this.message)
					})



			}
		}
		

		





		
		

	



	}




}

</script>

<style scoped>
#body {
            background-color: grey;
        }

h1 {
            color: whitesmoke;
        }

p {
            color: red;
        }
#wv{
	font-size:120%;
color:black;
}
#gotolilx{
	margin-top:1%;
	color:red;
}
#zindex{
	position:absolute;
	z-index:120;
	width:100%;
	height:100%;

}
#wizard{
	position:relative;
	width:30%;
	top:10%;
	height:25%;
	line-height:100%;
}
#red{
	color:rgb(173, 21, 21);
}
#qrcode{
	width:100%;
	margin-top:-5%;
}
#accept{
	width:20%;
	margin-left:1%;
	margin-top:2%;

	background-color: rgb(8, 143, 17);
}
#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:40%;
	right:5%;
	height:45%;
	position:absolute;

}

#acceptqrr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:33.5%;
	right:5%;
	height:5%;
	text-align:right;
	position:absolute;

}
#accepted{
	width:fit-content;
	margin-left:1%;
	margin-top:2%;

	background-color: rgb(8, 143, 17);
}

#acceptedd{
	width:fit-content;
	margin-left:1%;
	margin-top:2%;
	color:rgb(30, 30, 30, 0.193);

	border:2px solid rgb(33, 33, 33);
	background-color: rgb(168, 168, 168);
}

#ortega{
	color:rgb(30, 30, 30);
	
}
#gotolil{
	margin-left:1%;
	margin-top:1%;

}
#greeno{
	font-weight:500;
	color:green;
}
#zone{
	position:absolute;
	top:0%;
	opacity:0.03;
	width:100%;

}
#angle{
	position:absolute;
	width:100%;
	height:140%;
	background-color:rgb(6,6,6);
	top:502.5%;
	z-index:190;
}

#wickeddtwoh{
	left:5.5%;
	top:5.5%;
	width:50%;
	position:relative;
	z-index:10;
	height:80%;
}
#wit{
	width:105%;
	margin-left:-3%;
	margin-top:-1%;
}

#with{
	width:100.5%;

}
#wingworm{

	position:relative;
	margin-top:-2%;
	scrollbar-width: none;
	-ms-overflow-style: none;


}
#downcard{

	background-color:rgb(7.5,7.5,7.5);
	position:absolute;
	width:100%;
	height:10%;
	right:0%;
	top:586%;
	color:white;
	text-align:left;
	padding-top:25px;
	font-size:90%;
	border-top:15px solid rgb(10,10,10);
	font-weight:600;
	z-index:100;
}

#downcards{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:10%;
right:0%;
top:280%;
color:white;
text-align:left;
padding-top:25px;
font-size:90%;
border-top:15px solid rgb(12,12,12);
font-weight:600;
z-index:100;
}
#boldfs{
	font-size:80%;
	color:rgb(210,210,210);

}
#margindown{
	margin-top:7.5%;
}
#goupsome{
	margin-top:-12.5%;
}

#godownsome{
	margin-top:2.5%;
}

#wide{
	width:14.5%;
	height:4.25%;
	left:.5%;
	margin-top:1%;
}

#adjust{
	width:100%;
margin-top:-30%;

}
#green{
	margin-top:0.6%;
}
#greeng{
	margin-top:1%;
}
#greengg{
	margin-top:0%;
}

#greent{
	font-weight:bold;
	margin-top:0.6%;
}
#greenbeansb{
	margin-top:1%;
	font-weight:bold;
}

#greenbeans{
	position:relative;
	z-index:200;
	margin-top:1%;
}

#greenbeansgo{
	margin-top:2%;
	position:relative;
	z-index:200;
	
}
#greenbeanss{
	margin-top:3.5%;
}

#greenbeansss{
	position:relative;
	z-index:200;
	margin-top:5.5%;
}

#greenbeansssr{
	margin-top:5.5%;
	margin-right:-5.5%;
	text-align:right;
	position:relative;
	z-index:200;
	font-size:100%;
	padding-right:15%;
}
#greens{
	color:rgb(40,120,40);
	margin-top:0.6%;
}

#greenss{

	color:rgb(29, 162, 44);
	margin-top:0.6%;
	
}
#greense{
	color:rgb(20,120,20);
	
}


#greensee{
	color:rgb(20,150,20);
	
}
#bigfont{
	font-size:900%;
	line-height:100%;
	margin-top:2.5%;

}

#idle{
	font-size:100%;
}
#control{
	width:50%;
	font-size:140%;
}

#controls{
	width:50%;
	margin-top:1%;
	font-size:90%;
}

#controlss{
	width:50%;
	margin-top:1%;
	font-size:90%;
}
#controlsgo{
	width:50%;
	margin-left:50%;
	margin-top:1%;
	font-size:90%;
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:100%;
}
#controlshqq{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:100%;
}
#controlshqqq{
	width:50%;
	margin-left:0%;

	font-weight:500;

	font-size:100%;
}

#controlshqqqq{
	width:55%;
	font-weight:500;

	margin-top:1%;
	margin-left:0%;
	font-size:100%;
}

#controlshqqqqq{
	width:50%;


	margin-top:1%;
	font-size:100%;
}

#controlshqqqqqq{
	width:50%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:50%;

	margin-top:0.5%;
	font-size:100%;
	font-weight:500;
}
#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlse{
	width:50%;
	margin-top:1%;
	font-weight:500;
	font-size:90%;
}
#entice{
	font-size:160%;
}
#gole{
	margin-left:-0.5%;
}
#smurf{
	width:98%;
	right:-1%;
	margin-top:1%;
}

#line{
	width:99%;
	height:2%;
	left:.5%;
	font-size:10%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#lines{
	width:32.5%;
	line-height:22%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#liness{
	width:77.5%;
	line-height:22%;
	top:4%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#linessg{
	width:97.5%;
	line-height:22%;
	top:15%;
	left:.5%;
	font-size:100%;
	position:relative;
	z-index:200;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
#linessgg{
	width:97.5%;
	line-height:22%;
	top:0%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#linesss{
	width:95%;
	line-height:75%;
	top:-4.25%;
	left:0.5%;
	font-size:100%;
	filter:drop-shadow(2px 0px 2px rgba(18, 18, 18, 0));
	color:transparent;
	background-color: rgba(15, 15, 15, 0);
}

#linessss{
	width:100%;
	line-height:75%;
	top:7.85%;
	left:0.5%;
	font-size:100%;
	filter:drop-shadow(2px 0px 2px rgb(43, 43, 43));
	color:transparent;
	background-color: rgb(15, 15, 15);
}
#widthit{
	margin-top:1%;
	width:50%;
	font-size:150%;
}
#widthittt{

	width:50%;

}
#widthitttt{

width:89%;

}
#widthittttt{
margin-top:.6%;
width:89%;

}

#widthitttty{
margin-top:0.6%;
width:69%;

}

#widthitt{
	margin-top:1%;
	width:50%;
	
	font-size:180%;
}


#widthittn{

	font-size:120%;
}
#widthitty{
	margin-top:1%;
	width:50%;

	text-align:left;
	font-size:180%;
}
#widthittyyy{
	margin-top:3%;
	width:50%;
	font-weight: 500;
	text-align:left;
	font-size:180%;
}
#widthittyy{
	margin-top:0%;
	width:50%;

	font-size:140%;
}
#widthitth{
	margin-top:0%;
	width:50%;
	font-size:140%;
}
#widthittg{
	margin-top:7.5%;
	line-height:100%;
	width:50%;
	font-size:380%;
}
#absolute{
	position: absolute;
	top:0%;
	color:black;
}
#absolutey{
	position: absolute;
	top:0%;
	width:100%;
	background-color:rgb(100,0,0, 0.193);

right:0%;
	color:black;
}
#absolutedown{
	position: absolute;
	top:45.5%;
	height:40%;
	width:100%;

	color:white;

}
#absoluteg{
	position: absolute;
	top:0%;
	right:0%;
	text-align:left;
	color:black;
}

#absolutet{
	position: absolute;
	top:76%;
	right:0%;
	text-align:right;
	color:black;
}


#absolutets{
margin-top:6%;
	top:76%;
	right:0%;
	text-align:left;
	color:black;
}
#gang{
	margin-top:-90%;
}
#upin{
	position:relative;
	z-index:39;
}
#hit{
	margin-top:1%;
}
#bold{

	font-weight:500;
}
#boldgreen{
color:green;
font-weight:500;
}
#boldgo{
width:50%;
right:0%;
margin-left:50%;
font-weight:500;
}
#boldgog{
width:50%;
right:0%;
margin-top:1%;
margin-left:50%;
font-weight:500;
}
#boldgogg{
width:50%;
right:0%;
margin-top:1%;
margin-left:50%;

}
#boldg{
	font-weight:500;
	margin-top:0.5%;
}
#boldgggg{
	font-weight:500;
	margin-top:1.5%;
}
#boldggg{
	font-weight:500;
	margin-top:0.5%;
	font-size:100%;
}

#boldgggm{

	margin-top:0.5%;
	font-size:100%;
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	font-size:100%;
}

#boldgt{
	
	margin-top:0.5%;
}

#boldgg{
	font-weight:500;
	margin-top:0.75%;
}
#boldr{
	margin-top:-1%;
	font-weight:500;
}

#boldrd{
	margin-top:1%;
	font-weight:500;
}
#bolds{
	margin-top:0.5%;
	font-weight:500;
}
#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:100%;
top:0%;
text-align: left;
padding-top:10px;

}
#abs{
	right:5%;
	width:80%;
	top:2%;

	text-align: right;
	z-index: 10;
	font-size:100%;
	pointer-events: none;
	position: absolute;
	color:rgb(228, 228, 228);

}

#icon{
	left:1%;
	margin-top:0.75%;
	width:5%;
	height:5%;
	color:rgba(16, 16, 16, 0.193);
}

#icone{

	right:-94%;
	margin-top:0.75%;
	width:5%;
	height:3%;
	color:rgba(16, 16, 16, 0.193);
}

#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
height:100%;
}

#icones{
	color:rgba(16, 16, 16, 0.193);
right:-88%;
margin-top:-5.95%;
width:5%;
height:3%;

margin-right:5%;
}

#iconess{
position:absolute;
top:12.5%;
right:30%;
width:20%;
height:50%;
color:rgba(46, 46, 46, 0.193);

}
#blinds{
	width:150%;
	margin-top:-30%;
}

#blindsg{
	width:100%;
	margin-right:0%;
	margin-top:-30%;
}

#blindsgy{
	width:100%;
	margin-right:0%;
	margin-top:-20%;
}
#blindsgg{
	width:100%;
	margin-right:0%;
	margin-top:-22.5%;
}
#idea{
	width:100%;
	color:white;
	height:14.5%;
	font-size:80%;
	text-align: right;
	padding-right:5px;
	background-color: rgb(52, 52, 52);
	position: absolute;
	top:0%;
}
#goupppp{
	color:black;
}

#goupx{
	margin-top:-2%;
}

#gouph{
	margin-top:-1%;
}



#goupxx{

	margin-top:-1%;
}

#goupxxx{
font-weight:500;
margin-top:-1%;
}
#preorderclose{
	position: absolute;
	width:10.75%;
	height:2.25%;
	right:25%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#wvv{
position:absolute;
color:black;
width:100%;
height:10%;
top:0%;

}
#right{
	text-align: right;
	padding-right:30.5%;
	margin-top:2%;
}
#rights{
	margin-top:0%;
	text-align: right;
	padding-right:30.5%;
}
#marginu{
	font-size:120%;
	margin-top:-3.5%;
}
#clone{
	width:12.5%;
	position: absolute;
	top:95%;
	right:2.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}

#clones{
	width:12.5%;
	position: absolute;
	top:95%;
	right:15.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#tag{
	margin-left:2.5%;
	font-size:150%;
}
#environment{
	width:50%;
	right:0%;
	position: absolute;
	height:100%;

}
#planet{
	width:40%;
	left:1%;
	height:12.5%;
}
#tw{
	font-size:150%;
}
#tww{
	margin-top:0.5%;
	width:35%;
}
#twww{
	margin-top:0.5%;
	width:35%;
}
#twwwx{
	margin-top:1%;
	width:35%;
}
#blind{
	width:100%;
	margin-top:00%;
}
#world{
	width:100%;
	height:100%;
	background-color: rgb(16, 16, 16);
}
#worldinfo{
	width:100%;
	height:100%;
	top:37.5%;

	position: absolute;
	background-color: rgba(63, 82, 110, 0.482);
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:68.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#atad{
	margin-top:2%;
}
#goright{


	text-align: right;
}

#gorighty{
	position:relative;
	z-index:200;
margin-top:2.25%;
text-align: right;
}
#gorightt{
	margin-top:-.75%;
	margin-left:-3.5%;
	font-weight:500;
	font-size:120%;
	text-align: left;
	position:relative;
	z-index:200;
}
#gorightg{
	margin-top:10%;
	z-index:10;
	margin-right:-1%;
	position:relative;
	text-align: right;
	z-index:220;
}

#gorightgs{
	margin-top:5%;

	z-index:10;
	position:relative;
	text-align: left;
}
#gorightsee{
	width:100%;
	position: relative;
	right:0%;
	margin-top:1%;
	background-color: rgb(8, 8, 8);
	text-align: right;
}
#gorights{
	margin-left:1.5%;
}
#gorightst{
	margin-left:1.5%;
	position: relative;
	z-index:200;
	margin-top:.75%;
	
}
#gorightsd{
	margin-left:1.5%;
	margin-top:1.5%;
}

#gorightse{
	margin-top:1%;
	margin-left:1.5%;
}
#gohere{
	width:65%;
	height:27.5%;
	position: absolute;
	background-color: rgb(5, 5, 5);
}

#toright{
	height:90%;
	top:5%;
	width:50%;
	right:15%;
	position: absolute;

}
#goup{
	font-size:110%;
text-align: right;
margin-top:-6%;
}

#example{
	width:27.5%;
	height:80%;
	position:absolute;
	top:0%;
	left:2.5%;
	top:5.5%;
	z-index: 50;
	
}
#preorderbottom{
	width:100%;
	top:70%;
	position: absolute;

	height:30%;
}

#preorderone{
	height:35%;
	width:60%;
	left:35%;
	color:rgba(52, 52, 52, 0.222);
	top:2.5%;
}
#preordertwo{
	height:35%;
	width:60%;
	left:35%;
	color:rgba(52, 52, 52, 0.222);
	top:8.5%;
}
#preorderlabel{
	position: absolute;
	top:63.5%;
	font-size:90%;
	color:black;
}
#preorderlabels{
	position: absolute;
	top:48%;
	font-size:90%;
	color:black;
}
#preorderlabelss{
	position: absolute;
	top:32%;
	font-size:90%;
	color:black;
}

#preorderlabelsss{
	position: absolute;
	top:10.5%;
	font-size:140%;
	color:black;
}
#buy{
	width:62.5%;
	height:11%;
	left:32.25%;
	border:2px solid rgb(2, 90, 2);
	top:85%;
	position:absolute;
	color:rgb(19, 19, 19);
	background-color: rgb(0, 128, 45);
}
#preorder{
	position: absolute;
	width:50%;
	right:25%;
	height:35%;
	top:2.5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}

#preorderpurchase{
	position: absolute;
	width:60%;
	right:20%;
	height:32.5%;
	top:5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}
#width{
	width:100%;
	height:30%;
	background-color: rgb(7, 7, 7);
	position: absolute;
	text-align: right;
}
#preorderbox{
	width:20%;
	top:1%;
	left:1%;
	height:22.5%;
	background-color: red;
}
#godown{
	margin-top:2%;
}
#godownsg{
	margin-top:1.25%;
	margin-left:1.75%;
	font-style:italic;
}

#godowns{
	margin-top:0.5%;
}

#godownse{
	margin-top:.5%;
}
#godownst{
	text-align: right;
	margin-top:4.5%;
	margin-right:10%;
	letter-spacing: 1px;
	
	color:rgb(200, 199, 199);
}
#goit{
	margin-top:1.5%;
}
#goflash{
	width:97.5%;
	margin-left:.5%;
	margin-top:.5%;
	position:relative;
	z-index: 250;
}

#goflashs{
	width:97.5%;
	margin-left:.5%;
	margin-top:1.5%;
	
}
#heart{
	margin-top:5%;
	width:95%;
	height:30%;
	position: relative;
}
#hearts{
	margin-top:5%;
	width:95%;
	height:20%;
	z-index: 190;
	position: absolute;
}
#heartsig{
	margin-top:-1%;
	width:4%;
	right:6%;
	z-index: 200;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#heartsigs{
	margin-top:-1%;
	width:4%;
	z-index: 200;
	right:11%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}

#heartsigss{
	margin-top:-1%;
	width:4%;
	z-index: 200;
	right:16%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#scale{
	width:100%;
}

#scaless{
	width:102.5%;
	margin-left:-.5%;
}
#scales{
	margin-left:-35%;
	height:97%;
	width:170%;
}
#wished{
	top:4.75%;
	left:-0.5%;
	width:95%;
	height:30%;
	position: absolute;
}
#examplarsxv{
	width:150%;
}
#examplarsxvzz{
	width:100%;
}
#examplarsxvii{
	width:320%;
	margin-left:-220%;
	margin-top:-50%;
}
#examplarsxvj{
	width:100%;
	margin-top:-20%;
}
#examplarsxvz{
	width:100%;
}
#examplarsxvvv{
	width:100%;
	margin-top:-34%;
}


#examplarsxvt{
	width:100%;
	margin-top:-27.5%;
}
#examplarsxvtt{
	width:100%;
	margin-top:-5%;
}
#examplarsxvh{
	width:120%;
}

#examplarsxvhh{
	width:120%;
	margin-top:-30%;
}

#examplarsxvhhh{
	width:120%;
	margin-top:-60%;
}
#examplarsx{
	width:100%;
	margin-top:-10%;
}

#examplarss{
	width:100%;
}

#examplarsxg{
	width:112%;
margin-left:-5%;
}
#examplarsxvc{
	margin-top:1%;
	margin-left:1%;
	width:17.5%;
}
#examplars{
	right:-20%;
	width:140%;
	top:30%;

	position: absolute;
}
#examplarsbox{
	right:-0.5%;
	width:97.5%;
	top:35%;
	height:62.5%;
	z-index: 50;
	background-color: black;
	position: absolute;
}
#examplarsxvci{
	margin-top:-20%;
	margin-left:1%;
	width:100%;
}

#examplarsxvcii{
	margin-top:-34%;
	margin-left:1%;
	width:100%;

}
#saint{
	margin-top:28%;
}
#examplarsxvv{
	margin-top:-34%;
	width:150%;
}
#sideone{
	height:30%;
	position: absolute;

	width:50%;
}
#sideonec{
	height:25%;
	position: absolute;

	width:95%;
}
#sidetwoc{
	width:33.3%;
	right:0.3%;
	height:25%;
	position: absolute;

}
#sidetwocc{
	width:33.3%;
	right:33.3%;
	height:25%;
	position: absolute;

}
#sidetwo{
	width:50%;
	left:50%;
	height:30%;
	position: absolute;

}
#sidefour{
	height:30%;
	position: absolute;
	top:57.75%;
	width:70%;
	z-index:100;
}

#sidefive{
	width:30%;
	left:70%;
	top:57.75%;
	height:30%;
	z-index:100;
	position: absolute;

}
#sidethreedown{
	width:100%;
	left:00%;
	z-index:100;
	height:50%;
	top:52.5%;
	position: absolute;

}

#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:89%;
	position: absolute;
}

#sidesevendown{
	width:100%;
	left:00%;
	background-color: rgb(9, 9, 10);
	height:120%;
	top:100.25%;
	position: absolute;
}
#sidealldown{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:135.5%;
	top:99.25%;
	position: absolute;
}

#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:82.5%;
	top:99.25%;
	position: absolute;
}
#sideeightdown{
	width:100%;
	left:00%;
	background-color: rgb(9, 9, 10);
	height:120%;
	top:109.25%;
	position: absolute;
}
#margin{
	margin-left:3.5%;
}

#marginx{
	margin-left:3.5%;
}
#marginse{
	margin-left:4.5%;
}

#margins{
	margin-left:2.5%;
}
	#droppedclass {
	width: 100%;
	height: 250%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#droppedclassd {
	width: 100%;
	height: 250%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);

}

#droppedclassx {
	width: 100%;
	height: 250%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#thesize{

	text-align: right;
	font-size: 80%;
}
#wicked{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:90%;
}


#wickedcl{
	left:1.5%;
	top:7%;
	width:95%;
	height:90%;
	color:rgb(200,0,0, 0.193);
}

#wickedc{
	left:3.5%;
	top:4.5%;
	width:97.5%;
	height:90%;
}

#wickedcc{
	left:27.5%;
	top:12.5%;
	width:62.5%;
	height:100%;
	position:relative;
	z-index:150;
}

#wickedccc{
	left:80.5%;
	top:27.5%;
	position:relative;
	z-index:200;
	width:22.5%;
	height:98%;

}


#wickedtwo{
	left:-5%;
	top:-1%;
	border:2px solid rgb(47, 2, 2);
	width:101.5%;
	color:rgba(242, 60, 60, 0.193);
	height:97%;
}
#grow{
	position:relative;
	z-index:200;

}
#wickeddtwo{
	left:2.5%;
	top:5.5%;
	width:87.5%;
	height:80%;
}

#wickeddtwoo{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:30%;
}


#wickeddtwooo{
	left:2.5%;
	top:3.5%;
	width:95%;
	height:60%;
}

#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:12.5%;
	height:3.25%;
}
#wickeddtwooooo{
	left:2.5%;
	margin-top:1%;
	width:15.5%;
	height:6%;
}
#examplarsxvvvvz{
	width:100%;
	margin-top:-10%;
}
#examplarsxvvvvzz{
	width:100%;
	margin-top:-30%;
}
#wickedddtwo{
	left:1.5%;
	top:2.5%;
	width:97.5%;
	height:10%;
}

#wickedtwos{
	left:-1%;
	top:3.5%;
	width:98%;
	height:40%;
}
#wickedtwoss{
	left:-1%;
	top:4.5%;
	width:98%;
	height:40%;
}

#wickedtwosss{
	left:-1%;
	top:5.5%;
	width:98%;
	height:20%;
}
#wickedtwoc{
	left:1%;
	top:1.5%;
	width:97.5%;
	height:90%;
}

#ogstatus{
	width:40%;
}
@media only screen and (max-width:933px) and (max-height:431px) and (min-width:833px) {
	#wickeddtwo{
	left:1.5%;
	top:6.5%;
	width:97.5%;
	height:130%;
}#heart{
	margin-top:8%;
	width:95%;
	height:30%;
	position: relative;
}
#mango{
	position:absolute;
	width:100%;
	top:-5%;
	height:100%;
	right:0%;
	
}
#preorderlabelsss{
	top:4%;
	font-size:130%;
}
#downcard{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:20%;
right:0%;
top:586%;
color:white;
text-align:left;
padding-top:25px;
font-size:90%;
border-top:15px solid rgb(10,10,10);
font-weight:600;
z-index:100;
}

#gorightg{
	color:transparent;
}
#clone{
	width:15.5%;
	position: absolute;
	top:95%;
	right:2.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}

#clones{
	width:21.5%;
	position: absolute;
	top:95%;
	right:19.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#blind{
	width:190%;
	margin-top:00%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:17.5%;
	height:4.25%;
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:138.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	font-size:150%;
	line-height:1.3;
}

#wickeddtwoo{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:45%;
}

#wickedcl{
	left:1.5%;
	top:2%;
	width:95%;
	height:90%;
	color:rgb(200,0,0, 0.193);
}
#greengob{
	width:25%;
	border:2px solid grey;
	margin-top:1%;
	margin-left:1%;
	position:relative;
	z-index:10;
}
#mento{
	width:100%;	
	margin-top:-30%;
}

#mentos{
	width:100%;	
	margin-top:-12.5%;
}

#mentoss{
	width:100%;	
	margin-top:-25.5%;
}
#examplarsxvt{
	width:150%;
	margin-top:-27.5%;
}

	#gorightsee{
	width:100%;
	position: relative;
	right:0%;
	height:105%;

	background-color: rgb(8, 8, 8);
	text-align: right;
}
#wickedddtwo{
	left:1.5%;
	top:2.5%;
	width:97.5%;
	height:20%;
}


#wicked{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:90%;
	color:rgba(0, 0, 0, 0.181);
}

#wickedef{
	left:12.5%;
	top:-2.5%;
	width:175%;
	position:absolute;
	z-index:150;
	height:140%;
	color:rgba(0, 0, 0, 0.178);
}
#wickedtwo{
	left:-7%;
	top:-1%;
	border:2px solid rgb(47, 2, 2);
	width:103.5%;
	color:rgba(242, 60, 60, 0.193);
	height:97%;
}
#fulfill{
	position:absolute;
	width:100%;
	height:100%;
	top:0%;
	right:0%;
	background-color:rgb(200,0,40, 0.193);
}
#wickedcll{
	left:7.5%;
	top:-5%;
	width:130%;
	height:120%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedclll{
	left:7.5%;
	top:-10%;
	width:130%;
	height:135%;
	position:absolute;
	z-index:300;
	color:rgb(200,0,0, 0.193);
}

#absolutedown{
	position: absolute;
	top:45.5%;
	height:40%;
	width:100%;

	color:white;

}
#boldg{
	font-weight:500;
	width:20%;	
	margin-right:0%;
	margin-top:0.5%;
}
#controlshqqqqq{
	width:100%;

	text-align: left;
	margin-top:1%;
	font-size:100%;
}

#controlshqqqqqq{
	width:100%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:100%;
	font-weight:500;
}
#controlshqqqq{
	width:100%;
	font-weight:500;
	text-align:left;
	margin-top:1%;
	margin-left:0%;
	font-size:100%;
}
#controlshqqq{
	width:100%;

	right:0%;
	font-weight:500;
	text-align:left;
	font-size:100%;
}
#absolutedown{
	position: absolute;
	top:95.5%;
	height:40%;
	width:100%;

	color:white;

}
#widthittttt{
margin-top:.6%;
width:59%;

}
#bold{
width:60%;
font-weight:500;
}
#goupxx{
width:50%;
margin-top:-1%;
}

#boldg{
	font-weight:500;
	margin-top:0.5%;
	width:100%;

}
#green{
	width:50%;
	margin-top:0.6%;
}
#gang{
	
	margin-top:-40%;
}
#goupx{
	font-weight:bold;
	margin-top:-2%;
}
#raporttt{
	font-size:90%;
font-weight: bold;
}
#raportt{
	
font-weight: bold;
}
#raport{
width:20%;
right:30%;
}
#upin{
	position:relative;
	z-index:39;
	
}
#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:100%;
top:0%;
text-align: left;
padding-top:10px;

}
#sidealldown{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:235.5%;
	top:90%;
	position: absolute;
}
#sidefour{
	height:37.5%;
	position: absolute;
	top:76.75%;
	width:68%;
	left:1%;
	z-index: 100;
}

#sidefive{
	width:30%;
	left:70%;
	top:69.75%;
	height:50%;
	z-index:10;
	position: absolute;

}
#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:112.5%;
	position: absolute;
}
#sidethreedown{
	width:100%;
	left:00%;
	
	height:50%;
	z-index: 5;
	top:64.5%;
	position: absolute;

}
#wished{
	top:3.75%;
	left:-0.5%;
	width:95%;
	height:40%;
	position: absolute;
}
#examplarsxvc{
	margin-top:9%;
	margin-left:3%;
	width:23.5%;
}
#goupppp{
	font-size:100%;
	color:black;
	margin-top:-3%;
}
#preorderclose{
	position: absolute;
	width:14.75%;
	height:3.75%;
	right:15%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#preorderpurchase{
	position: absolute;
	width:80%;
	right:10%;
	height:52.5%;
	top:5%;
	z-index:90;
	background-color: rgb(8, 8, 8);
}

#example{
	width:27.5%;
	height:74%;
	position:absolute;
	top:0%;
	left:2.5%;
	top:7.5%;
	z-index: 50;
	
}
#gorightg{
	margin-top:9.5%;
	z-index:10;
	margin-right:-4%;
	position:relative;
	text-align: right;
}

#wickedccc{
	left:80.5%;
	top:12.5%;
	width:22.5%;
	height:98%;

}
#widthu{
	width:25%;

	margin-right:-1.5%;
}
#greenbeansssr{
	margin-top:5.5%;
	margin-right:-5.5%;
	text-align:right;
	font-size:80%;
	padding-right:15%;
}
#wickedcc{
	left:7.5%;
	top:12.5%;
	width:92.5%;
	height:100%;
}
#angle{
	position:absolute;
	width:100%;
	height:140%;
	background-color:rgb(6,6,6);
	top:652.5%;

}
#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:132.5%;
	top:99.25%;
	position: absolute;
}
#con{
	margin-top:.5%;
	position:absolute;
	height:100%;
	width:100%;
	z-index:200;
}
#upin{
	position:relative;
	z-index:50;
	
	
}
#thesize{
	position:relative;

	z-index:50;

text-align: right;
font-size: 80%;
}
#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:100%;
top:0%;
text-align: left;
padding-top:10px;

}

#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
height:99%;
}

#preorder{
	position: absolute;
	width:70%;
	right:15%;
	height:55%;
	top:4%;
	z-index:85;
	background-color: rgb(8, 8, 8);
}
#goup{
	font-size:100%;
text-align: right;
margin-top:-10%;
}


#gotolil{
	margin-left:1%;

	width:60%;
	margin-top:1%;
}

#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:40%;
	right:5%;
	height:39%;
	position:absolute;
	
}

#hearts{
	margin-top:7%;
	width:95%;
	height:20%;
	position: absolute;
}

}

@media only screen and (max-width:833px) and (max-height:431px) {
	#wickeddtwo{
	left:1.5%;
	top:6.5%;
	width:97.5%;
	height:130%;
}#heart{
	margin-top:9%;
	width:95%;
	height:30%;
	position: relative;
}

#width{
	width:40%;
	right:0%;
z-index:200;
}
#preorderlabelsss{
	top:4%;
	font-size:120%;
}
#mango{
	position:absolute;
	width:100%;
	top:-5%;
	height:100%;
	right:0%;
	
}
#ignf{
	color:transparent;
}
#gf{
	font-size:80%;
	line-height:1.5;
	top:0%;
	
	
}
#gorightg{
	color:transparent;
}
#clone{
	width:15.5%;
	position: absolute;
	top:95%;
	right:2.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}

#clones{
	width:21.5%;
	position: absolute;
	top:95%;
	right:19.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#blind{
	width:190%;
	margin-top:00%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:17.5%;
	height:4.25%;
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:138.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	font-size:150%;
	line-height:1.3;
}

#wickeddtwoo{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:45%;
}

#wickedcl{
	left:1.5%;
	top:2%;
	width:95%;
	height:90%;
	color:rgb(200,0,0, 0.193);
}
#greengob{
	width:25%;
	border:2px solid grey;
	margin-top:1%;
	margin-left:1%;
	position:relative;
	z-index:10;
}
#mento{
	width:100%;	
	margin-top:-30%;
}

#mentos{
	width:100%;	
	margin-top:-12.5%;
}

#mentoss{
	width:100%;	
	margin-top:-25.5%;
}
#examplarsxvt{
	width:150%;
	margin-top:-27.5%;
}

	#gorightsee{
	width:100%;
	position: relative;
	right:0%;
	height:105%;

	background-color: rgb(8, 8, 8);
	text-align: right;
}
#wickedddtwo{
	left:1.5%;
	top:2.5%;
	width:97.5%;
	height:20%;
}


#wicked{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:90%;
	color:rgba(0, 0, 0, 0.089);
}

#wickedef{
	left:12.5%;
	top:-2.5%;
	width:175%;
	position:absolute;
	z-index:150;
	height:140%;
	color:rgba(0, 0, 0, 0.178);
}
#wickedtwo{
	left:-7%;
	top:-1%;
	border:2px solid rgb(47, 2, 2);
	width:103.5%;
	color:rgba(242, 60, 60, 0.193);
	height:97%;
}
#fulfill{
	position:absolute;
	width:100%;
	height:100%;
	top:0%;
	right:0%;
	background-color:rgb(200,0,40, 0.193);
}
#wickedcll{
	left:7.5%;
	top:-5%;
	width:130%;
	height:120%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedclll{
	left:7.5%;
	top:-10%;
	width:130%;
	height:135%;
	position:absolute;
	z-index:300;
	color:rgb(200,0,0, 0.193);
}

#absolutedown{
	position: absolute;
	top:45.5%;
	height:40%;
	width:100%;

	color:white;

}
#boldg{
	font-weight:500;
	width:20%;	
	margin-right:0%;
	margin-top:0.5%;
}
#controlshqqqqq{
	width:100%;

	text-align: left;
	margin-top:1%;
	font-size:100%;
}

#controlshqqqqqq{
	width:100%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:100%;
	font-weight:500;
}
#controlshqqqq{
	width:100%;
	font-weight:500;
	text-align:left;
	margin-top:1%;
	margin-left:0%;
	font-size:100%;
}
#controlshqqq{
	width:100%;

	right:0%;
	font-weight:500;
	text-align:left;
	font-size:100%;
}
#absolutedown{
	position: absolute;
	top:95.5%;
	height:40%;
	width:100%;

	color:white;

}
#widthittttt{
margin-top:.6%;
width:59%;

}
#bold{
width:80%;
font-weight:500;
}
#goupxx{
width:50%;
margin-top:-1%;
}

#boldg{
	font-weight:500;
	margin-top:0.5%;
	width:100%;

}
#green{
	width:50%;
	margin-top:0.6%;
}
#gang{
	
	margin-top:-40%;
}
#goupx{
	font-weight:bold;
	margin-top:-2%;
}
#raporttt{
	font-size:90%;
font-weight: bold;
}
#raportt{
	
font-weight: bold;
}
#raport{
width:20%;
right:30%;
}
#upin{
	position:relative;
	z-index:39;
	
}
#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:100%;
top:0%;
text-align: left;
padding-top:10px;

}
#sidealldown{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:235.5%;
	top:90%;
	position: absolute;
}
#sidefour{
	height:37.5%;
	position: absolute;
	top:76.75%;
	width:68%;
	left:1%;
	z-index: 100;
}

#sidefive{
	width:30%;
	left:70%;
	top:69.75%;
	height:50%;
	z-index:10;
	position: absolute;

}
#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:112.5%;
	position: absolute;
}
#sidethreedown{
	width:100%;
	left:00%;
	
	height:50%;
	z-index: 5;
	top:64.5%;
	position: absolute;

}
#wished{
	top:3.75%;
	left:-0.5%;
	width:95%;
	height:40%;
	position: absolute;
}
#examplarsxvc{
	margin-top:9%;
	margin-left:3%;
	width:23.5%;
}
#goupppp{
	font-size:100%;
	color:black;
	margin-top:-3%;
}
#preorderclose{
	position: absolute;
	width:14.75%;
	height:3.75%;
	right:15%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#preorderpurchase{
	position: absolute;
	width:80%;
	right:10%;
	height:53%;
	top:5%;
	z-index:90;
	background-color: rgb(8, 8, 8);
}

#example{
	width:27.5%;
	height:74%;
	position:absolute;
	top:0%;
	left:2.5%;
	top:7.5%;
	z-index: 50;
	
}
#gorightg{
	margin-top:9.5%;
	z-index:10;
	margin-right:-4%;
	position:relative;
	text-align: right;
}

#wickedccc{
	left:80.5%;
	top:12.5%;
	width:22.5%;
	height:94%;

}
#widthu{
	width:25%;

	margin-right:-1.5%;
}
#greenbeansssr{
	margin-top:5.5%;
	margin-right:-5.5%;
	text-align:right;
	font-size:80%;
	padding-right:15%;
}
#wickedcc{
	left:7.5%;
	top:12.5%;
	width:92.5%;
	height:100%;
}
#angle{
	position:absolute;
	width:100%;
	height:140%;
	background-color:rgb(6,6,6);
	top:654.5%;

}
#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:132.5%;
	top:99.25%;
	position: absolute;
}
#con{
	margin-top:.5%;
	position:absolute;
	height:100%;
	width:100%;
	z-index:200;
}
#upin{
	position:relative;
	z-index:50;
	
	
}
#thesize{
	position:relative;

	z-index:50;

text-align: right;
font-size: 80%;
}
#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:100%;
top:0%;
text-align: left;
padding-top:10px;

}

#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
height:99%;
}

#preorder{
	position: absolute;
	width:70%;
	right:15%;
	height:55%;
	top:4%;
	z-index:85;
	background-color: rgb(8, 8, 8);
}
#goup{
	font-size:100%;
text-align: right;
margin-top:-10%;
}


#gotolil{
	margin-left:1%;

	width:60%;
	margin-top:1%;
}

#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:40%;
	right:5%;
	height:39%;
	position:absolute;
	
}

#hearts{
	margin-top:7%;
	width:95%;
	height:20%;
	position: absolute;
}

}

@media only screen and (max-width:1024px) and (max-height:1367px) and (min-width:933px){

	#widthittn{

font-size:140%;
}
#bold{
font-size:120%;
line-height:1.5;
font-weight:500;
}
#downcard{
	font-size:130%;}
#abs{
	right:5%;
	width:80%;
	top:0.25%;

	text-align: right;
	z-index: 10;
	font-size:100%;
	pointer-events: none;
	position: absolute;
	color:rgb(228, 228, 228);

}

#fataa{
	font-size:150%;
}
#fataaa{
	font-size:130%;
	line-height:1.5;
}
#dr{
	font-size:150%;
}
#evenlao{
	font-size:130%;
	margin-left:.5%;
	line-height:1.3;
}
#fataa{
	font-size:140%;
	line-height:1.2;
}
#gty{
	font-size:150%;
}
#fatesd{
	font-size:120%;
	line-height:1.2;
}
#gof{
	font-size:60%;
	line-height:1.5;
}
#accepted{
	width:fit-content;
	margin-left:1%;
	margin-top:2%;
	font-size:200%;
	background-color: rgb(8, 143, 17);
}
#clearup{
	font-size:110%;
	line-height:1.5;
}
#clearupp	{
	font-size:110%;
	line-height:1.5;
}
#gf{
	font-size:80%;
	line-height:1.5;
	top:0%;
	
}
#heart{
	margin-top:8%;
	width:95%;
	height:40%;
	position: relative;
}
#sunn{
	font-size:130%;
	line-height:2;
}
#sun{
	font-size:130%;
	line-height:1.5;
}
#boldfr{
	font-size:120%;
}
#get{
	font-size:110%;
}
#godowns{
	margin-top:0.5%;
	font-size:110%;
}
#odel{
	font-size:110%;
}
#angle{
	position:absolute;
	width:100%;
	height:140%;
	background-color:rgb(6,6,6);
	top:532.5%;
	z-index:190;
}
#wvv{
position:absolute;
color:black;
width:100%;
height:10%;
top:.5%;
font-size:200%;
}
#twww{
	margin-top:0.5%;
	width:45%;
	line-height:1.5;
	font-size:120%;
}
#wv{
	font-size:170%;
	margin-top:1%;
color:black;
}
#right{
	text-align: right;
	padding-right:30.5%;
	margin-top:2%;
	font-size:150%;
}
#rights{
	margin-top:0%;
	text-align: right;
	font-size:110%;
	padding-right:30.5%;
}
#gorightsd{
	margin-left:1.5%;
	font-size:150%;
	line-height:1.8;
	margin-top:1.5%;
}
#gorightse{
	margin-top:1%;
	margin-left:1.5%;
	font-size:140%;
	line-height:1.5;
}
#dosumm{
	font-size:240%;
	line-height:2;
}
#gorightsee{
	width:100%;
	position: relative;
	right:0%;
	margin-top:1%;
	background-color: rgb(8, 8, 8);
	text-align: right;
	font-size:130%;
}
#gorights{
	font-size:230%;
	line-height:1.4;
	margin-left:1.5%;
}
#marginu{
	font-size:140%;
	margin-top:-0.5%;
}
#blinds{
	width:100%;
	margin-top:-30%;
}
#loud{
	font-size:150%;
}
#icon{
	left:1%;
	margin-top:1%;
	width:35%;
	height:14%;
	color:rgba(16, 16, 16, 0.193);
}
#wickeddtwo{
	left:1.5%;
	top:6.5%;
	width:97.5%;
	height:130%;
}
#twwwx{
	margin-top:1%;
	width:55%;
	line-height:1.5;
	font-size:120%;
}
#tww{
	margin-top:0.5%;
	width:45%;
	font-size:140%;
	font-weight:bold;
	line-height:1.4;
}
#clone{
	width:15.5%;
	position: absolute;
	top:97.25%;
	right:2.5%;
	height:2%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}

#clones{
	width:21.5%;
	position: absolute;
	top:97.25%;
	right:19.5%;
	height:2%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#blind{
	width:290%;
	margin-top:00%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:17.5%;
	height:4.25%;
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:75.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#upin{
	position:relative;
	z-index:50;
	font-size:120%;
	font-weight:500;
	visibility:hidden;
	
}
#liness{
	width:77.5%;
	line-height:22%;
	top:4%;
	left:1%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
	#wickedc{
	left:3.5%;
	top:2.5%;
	width:97.5%;
	height:37.5%;
}#examplarsxvcii{
	margin-top:-34%;
	margin-left:1%;
	width:150%;

}
#examplarsxv{
	width:272%;
}
#heartsig{
	margin-top:-1%;
	width:8%;
	right:6%;
	top:19.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#heartsigs{
	margin-top:-1%;
	width:8%;
	right: 16%;
	top:19.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}

#heartsigss{
	margin-top:-1%;
	width:8%;
	right:26%;
	top:19.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.4%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#goflash{
	width:97.5%;
	margin-left:.5%;
	margin-top:2.5%;
	
}
#green{
	margin-top:1.6%;
	font-size:105%;
}
#droppedclass {
	width: 100%;
	height: 100%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}


#examplarsxvc{
	margin-top:1%;
	margin-left:1%;
	width:27.5%;
}
#wished{
	top:2.75%;
	left:-0.5%;
	width:95%;
	height:40%;
	position: absolute;
}
#examplarsxvci{
	margin-top:-20%;
	margin-left:1%;
	width:150%;
}

#accept{
	width:20%;
	margin-left:1%;
	margin-top:2%;
	font-size:150%;
	background-color: rgb(8, 143, 17);
}
#acceptedd{
	width:fit-content;
	margin-left:1%;
	margin-top:2%;
	color:rgb(30, 30, 30, 0.193);
	height:4%;
	border:2px solid rgb(33, 33, 33);
	background-color: rgb(168, 168, 168);
}

#ortega{
	font-size:110%;
	padding-top:2px;
	color:rgb(30, 30, 30);
	
}
#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:40%;
	right:5%;
	height:37.5%;
	position:absolute;
	
}

#gotolil{
	margin-left:1%;
	font-size:110%;
	width:60%;
	margin-top:1%;
}
#preorderlabels{
	position: absolute;
	top:48%;
	font-size:100%;
	color:black;
}
#preorderlabelss{
	position: absolute;
	top:32%;
	font-size:100%;
	color:black;
}

#preorderlabelsss{
	position: absolute;
	top:10.5%;
	font-size:140%;
	color:black;
}
#atad{
	margin-top:2%;
	font-size:120%;
}
#examplarsxg{
	width:110%;

}
#gouppp{
	font-size:120%;
	line-height:1.2;
}
#goup{
	font-size:120%;
text-align: right;
margin-top:-3%;
}
#example{
	width:27.5%;
	height:70%;
	position:absolute;
	top:0%;
	left:2.5%;
	top:10.5%;
	z-index: 50;
	
}
#preorderclose{
	position: absolute;
	width:15.75%;
	height:1.25%;
	right:5%;
	top:1.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#grow{
	position:relative;
	z-index:200;
	font-size:105%;
}
#groww{
	font-size:135%;
}
#thesize{
margin-top:1.5%;
text-align: right;
font-size: 80%;
}
#preorder{
	position: absolute;
	width:90%;
	right:5%;
	height:25%;
	top:2.5%;
	z-index:40;
	background-color: rgb(8, 8, 8);
}
#preorderpurchase{
	position: absolute;
	width:90%;
	right:5%;
	height:20%;
	top:5%;
	z-index:50;
	background-color: rgb(8, 8, 8);
}
#gorightgs{
	margin-top:8%;

	z-index:10;	

	font-size:105%;
	position:relative;
	text-align: left;
}
#linessg{
	width:97.5%;
	line-height:22%;
	top:16.75%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
#boldfs{
	font-size:80%;

	color:rgb(210,210,210);

}
#downcards{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:10%;
right:0%;
top:253%;
color:white;
text-align:left;
padding-top:155px;
font-size:90%;
border-top:15px solid rgb(12,12,12);
font-weight:600;
z-index:100;
}
#wickeddtwoh{
	left:3.5%;
	top:6.5%;
	width:60%;
	position:relative;
	z-index:10;
	height:80%;
}
#downcards{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:6%;
right:0%;
top:250%;
color:white;
text-align:left;
padding-top:25px;
font-size:90%;
border-top:15px solid rgb(12,12,12);
font-weight:600;
z-index:100;
}
#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:.5%;
	top:99.25%;
	position: absolute;
}
#angle{
	position:absolute;
	width:100%;
	height:70%;
	background-color:rgb(6,6,6);
	top:307.5%;
}

#wickedccc{
	left:77.5%;
	top:12.5%;
	width:22.5%;
	height:35%;

}


#gorightt{
	margin-top:-.75%;
	margin-left:-2.5%;
	font-weight:500;
	font-size:120%;
	text-align: left;
}
#greenbeans{
	margin-top:1%;
	font-size:110%;
}

#greenbeansgo{
	font-size:130%;
	margin-top:2%;
}
#gorighty{
font-size:105%;
margin-top:2.25%;
text-align: right;
}
#gorightst{
	font-size:110%;
	margin-left:1.5%;
	margin-top:.75%;
	
}
#text{
	font-size:150%;
}
#greenbeansssr{
	margin-top:3.5%;
	margin-right:-5.5%;
	text-align:right;
	font-size:95%;
	padding-right:15%;
}
#wickedcc{
	left:2.25%;
	top:2.5%;
	width:100%;
	height:50%;
}
#godownsg{
	margin-top:1.25%;
	font-size:120%;
	margin-left:1.75%;
	font-style:italic;
}

#boldgg{
	font-weight:500;
	margin-top:1.75%;
	font-size:105%;
}
#wickeddtwooooo{
	left:2.5%;
	margin-top:2%;
	width:23.5%;
	height:4%;
}
#greenbeansb{
	margin-top:1.5%;
	font-size:125%;
	font-weight:bold;
}
#greenbeans{
	margin-top:1.5%;
	font-size:125%;
}
#sizing{
	font-size:120%;
}

#greent{
	font-weight:bold;
	margin-top:0.6%;
	font-size:105%;
}
#iconess{
position:absolute;
top:32.5%;
right:30%;
width:20%;
height:35%;
color:rgba(46, 46, 46, 0.193);

}
#greensee{
	color:rgb(20,150,20);
	font-size:120%;
}

#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
height:103%;
}

#saints{
width:60%;
background-color: rgb(17, 17, 17);
position: absolute;
height:105%;
top:0%;
text-align: left;
padding-top:10px;

}
#widthu{
	width:25%;
	margin-right:-1.5%;
}
#con{
	margin-top:1%;
}

#gorightg{
	margin-top:15%;
	z-index:10;
	margin-right:-1%;
	position:relative;
	text-align: right;
}
#hearts{
	margin-top:10%;
	width:95%;
	height:20%;
	position: absolute;
}






#greent{
	font-weight:bold;
	font-size:110%;
	margin-top:0.6%;
}#hearts{
	margin-top:6%;
	width:95%;
	height:20%;
	position: absolute;
}
#goflash{
	width:97.5%;
	margin-left:.5%;
	margin-top:.5%;
	font-size:140%;
	
}

#greengg{
	font-size:200%;
	margin-top:0%;
}

#matrix{
	width:30%;
	font-size:170%;
}
	

#examplarsxvii{
	width:250%;
	margin-left:-20%;
	margin-top:-70%;
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	line-height:1.3;
	font-size:130%;
}

#boldgggm{

margin-top:0.5%;
font-size:140%;
line-height: 1.3;
}
#controls{
	width:50%;
	margin-top:1%;
	font-size:120%;
}

#examplarsxvhh{
	width:200%;
	margin-top:-72%;
}

#examplarsxvhhh{
	width:200%;
	margin-top:-126%;
}
#boldgggg{
	font-weight:500;
	margin-top:1.5%;
	font-size:140%;
}
#examplarsxvv{
	margin-top:-34%;
	width:150%;
	margin-left:-20%;
}
#boldggg{
	font-weight:500;
	margin-top:0.5%;
	line-height:1.2;
	font-size:140%;
}
#increasedr{
	font-size:260%;
}
#examplarsxvv{
	margin-top:-54%;
	width:150%;
	margin-left:-10%;	
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	line-height:1.3;
	font-size:130%;
}
#controlshqq{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:130%;
}
#controlshqqqqq{
	width:50%;


	margin-top:1%;
	font-size:120%;
}

#controlshqqqqqq{
	width:100%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:130%;
	font-weight:500;
}
#controlshqqqq{
	width:100%;
	font-weight:500;
	text-align:left;
	margin-top:1%;
	margin-left:0%;
	width:50%;
	line-height:1.2;
	font-size:130%;
}
#controlshqqq{
	width:50%;
	margin-left:0%;

	font-weight:500;
	line-height:1.2;
	font-size:130%;
}
#id{
	font-size:150%;
}
#boldfs{
	font-size:130%;
	color:rgb(210,210,210);

}
#greenss{
font-size:110%;
color:rgb(29, 162, 44);
margin-top:0.6%;

}
#absolutedown{
	position: absolute;
	top:15.5%;
	height:40%;
	width:100%;

	color:white;

}
#boldfr{
	font-size:130%;
	width:50%;
	line-height:1.5;
	margin-top:1%;
}
#bigfont{
	font-size:1900%;
	line-height:100%;
	margin-top:2.5%;

}

#wickedcl{
	left:1.5%;
	top:7%;
	width:95%;
	height:40%;
	color:rgb(200,0,0, 0.193);
}

#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:59.5%;
	position: absolute;
}

#sidefive{
	width:90%;
	left:6%;
	top:53.25%;
	height:25%;
	width:97.5%;
	position: absolute;

}
#sidefour{
	height:30%;
	position: absolute;
	top:40%;
	width:97.5%;
}
#sidethreedown{
	width:100%;
	left:00%;
	
	height:50%;
	top:37.25%;
	position: absolute;

}


#examplarsxvh{
	width:150%;
}



#wickedtwos{
	left:-1%;
	top:3.5%;
	width:98%;
	height:27.5%;
}
#wickedtwoss{
	left:-1%;
	top:4.5%;
	width:98%;
	height:27.5%;
}

#wickedtwosss{
	left:-1%;
	top:5.5%;
	width:98%;
	height:20%;
}


#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:150%;
	line-height:1.3;
	font-weight:500;
}
#wickeddtwooo{
	left:2.5%;
	top:1.5%;
	width:95%;
	height:40%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:12.5%;
	height:2.25%;
}

#red{
	color:rgb(173, 21, 21);
	font-size:120%;
}
#margindown{
font-size:220%;
	margin-top:14.5%;
}
#greeng{
	margin-top:2%;
	font-size:135%;
}

#wide{
	width:24.5%;
	height:2.25%;
	left:.5%;
	margin-top:1%;
}
#raporttt{
	font-size:130%;
	line-height:1.5;

}
#boldgreen{
color:green;
font-size:140%;
font-weight:500;
}
#control{
	width:50%;
	font-size:140%;
}

#wickeddtwo{
	left:1.5%;
	top:5.5%;
	width:97.5%;
	height:80%;
}

#examplarsxvt{
	width:200%;
	margin-top:-27.5%;
}
#widthittttt{
margin-top:0.6%;
width:89%;
font-size:110%;

}
#boldtre{
font-size:110%;
}
#widthitttty{
margin-top:0.6%;
width:69%;
font-size:110%;

}

#goupxxx{
font-weight:500;
margin-top:-1%;
font-size:110%;
}
#goupx{
	margin-top:-1%;
}
#increase{

font-size:120%;
}
#increases{
margin-top:1%;
font-size:200%;
}
#increasess{
margin-top:1%;
font-size:140%;
}

#raportt{
margin-top:1%;
}
#wickedtwo{
	left:-5%;
	top:2%;
	border:2px solid rgb(47, 2, 2);
	width:100%;
	color:rgba(242, 60, 60, 0.193);
	height:20%;
}
#wickedclll{
	left:7.5%;
	top:-15%;
	width:530%;
	height:125%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedcll{
	left:7.5%;
	top:-5%;
	width:130%;
	height:120%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedcl{
	left:1.5%;
	top:7.5%;
	width:100%;
	height:37%;
	color:rgb(200,0,0, 0.193);
}
#green{
font-size:120%;
line-height:1.5;
	
}
#boldrd{
	margin-top:1%;
	font-size:120%;
	font-weight:500;
}
#widthitttty{
margin-top:0.6%;
width:69%;
font-size:110%;
}
#goupxx{
	font-size:110%;	
	margin-top:-1%;
}

#boldr{
	margin-top:-1%;
	font-weight:500;
	font-size:110%;
}

#boldg{
	font-weight:500;
	font-size:110%;
	margin-top:1%;
}
#wicked{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:80%;
}
#gang{
	margin-top:-130%;
}

#wickeddtwoo{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:20%;
}

#red{
	color:rgb(173, 21, 21);
	font-size:150%;
}

#fate{
	font-size:150%;
}

#fates{
	font-size:130%;
}


#greenss{

color:rgb(29, 162, 44);
margin-top:0.6%;
font-size:150%;
margin-top:1%;
}
#examplarsxvvv{
	width:200%;
	margin-top:-44%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:22.5%;
	height:2.25%;
}
#inceasesss{
	font-size:120%;
}

#increasesss{
	font-size:120%;
}
#acceptqrr{
	width:30%;
	margin-left:1%;
	margin-top:2%;

	font-size:110%;
	right:5%;
	height:5%;
	text-align:right;
	position:absolute;

}
#inter{
	font-size:120%;
	line-height:1.5;
}
#gotolil{
	font-size:120%;
	width:50%;
	line-height:1.5;
}
#groww{
	font-size:120%;
	line-height:1.5;
}
#widthittyy{
	margin-top:.5%;
	width:50%;
	line-height:1.3;
	font-size:160%;
}
}


@media only screen and (max-width:1367px) and (max-height:1024px) and (min-width:933px){
	#greent{
	font-weight:bold;
	font-size:110%;
	margin-top:0.6%;
}#hearts{
	margin-top:6%;
	width:95%;
	height:20%;
	position: absolute;
}
#downcard{
	font-size:130%;}
#get{
	font-size:130%;
	line-height:1.3;
}
#fataaa{
	line-height:1.3;
	font-size:130%;
}

#fataa{
	line-height:1.3;
	font-size:130%;
}
#fatesd{
	font-size:130%;
	line-height:1.2;
}
#sun{
	font-size:130%;
}
#widthittn{

font-size:140%;
line-height:1.5;
}
#drr{
	font-size:120%;
	line-height:1.5;
}
#groww{
	font-size:120%;
	line-height:1.5;
}
#lineman{
	font-size:100%;
	line-height:1.5;
}
#linemans{
	font-size:120%;
	line-height:1.5;
}
#gof{
	font-size:110%;
	line-height:1.5;
}
#acceptqrr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:30.5%;
	font-size:110%;
	right:5%;
	height:5%;
	text-align:right;
	position:absolute;

}
#inter{
	font-size:110%;
}
#grow{
	font-size:110%;
	line-height:1.5;
}
#gotolil{
	font-size:110%;
	width:50%;
	line-height:1.5;
}

#gorightg{
	margin-top:10%;
	z-index:10;
	margin-right:-1%;
	position:relative;
	text-align: right;
	visibility:hidden;
	z-index:220;
}

#matrix{
	line-height:1.5;
}
#clearup{
	font-size:120%;
	line-height:1.3;
}
#raporttt{
	font-size:90%;
	line-height:1.5;

}
#twww{
	font-size:120%;
	line-height:1.5;
}
#heart{
	margin-top:5%;
	width:95%;
	height:40%;
	position: relative;
}
#clones{
	width:15.5%;
	position: absolute;
	top:95%;
	right:15.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#gorights{
	font-size:130%;
	line-height:1.4;
}
#gorightse{
	margin-top:1%;
	margin-left:1.5%;
	font-size:130%;
	line-height:1.4;
}
#dosumm{
	font-size:200%;
	line-height:2;
}
#twww{
	margin-top:0.5%;
	width:35%;
}
#twwwx{
	margin-top:1%;
	width:35%;
	font-size:120%;
	line-height:1.5;
}
#tww{
	margin-top:0.5%;
	width:35%;
	font-size:120%;
	line-height:1.5;
}

#goflash{
	width:97.5%;
	margin-left:.5%;
	margin-top:.5%;
	font-size:140%;
	
}

#greengg{
	font-size:200%;
	margin-top:0%;
}

#matrix{
	width:50%;
	font-size:130%;
}
	

#examplarsxvii{
	width:250%;
	margin-left:-20%;
	margin-top:-70%;
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	font-size:140%;
	line-height:1.2;
}

#boldgggm{

margin-top:0.5%;
font-size:130%;
line-height:1.2;
}
#controls{
	width:50%;
	margin-top:1%;
	font-size:120%;
	line-height:1.3;
}

#boldgggg{
	font-weight:500;
	line-height:1.3;
	margin-top:1.5%;
	font-size:140%;
}
#examplarsxvv{
	margin-top:-34%;
	width:150%;
	margin-left:-20%;
}
#boldggg{
	font-weight:500;
	margin-top:0.5%;
	line-height:1.2;
	font-size:140%;
}
#increasedr{
	font-size:260%;
}
#examplarsxvv{
	margin-top:-54%;
	width:150%;
	margin-left:-10%;	
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	line-height:1.3;
	font-size:130%;
}
#controlshqq{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:130%;
}
#controlshqqqqq{
	width:50%;

	line-height:1.5	;
	margin-top:1%;
	font-size:120%;
}

#controlshqqqqqq{
	width:100%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:130%;
	line-height:1.3;
	font-weight:500;
}
#controlshqqqq{
	width:100%;
	font-weight:500;
	text-align:left;
	margin-top:1%;
	margin-left:0%;
	width:50%;
	font-size:130%;
}
#controlshqqq{
	width:50%;
	margin-left:0%;

	font-weight:500;
	line-height:1.2;
	font-size:130%;
}
#id{
	font-size:120%;
}
#boldfs{
	font-size:100%;
	color:rgb(210,210,210);

}
#greenss{
font-size:110%;
color:rgb(29, 162, 44);
margin-top:0.6%;

}
#absolutedown{
	position: absolute;
	top:35.5%;
	height:40%;
	width:100%;

	color:white;

}
#boldfr{
	font-size:110%;
}
#bigfont{
	font-size:1500%;
	line-height:100%;
	margin-top:2.5%;

}

#wickedcl{
	left:1.5%;
	top:7%;
	width:98%;
	height:40%;
	color:rgb(200,0,0, 0.193);
}

#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:77%;
	position: absolute;
}

#sidefive{
	width:90%;
	left:6%;
	top:71%;
	height:25%;
	position: absolute;

}
#sidefour{
	height:30%;
	position: absolute;
	top:55.75%;
	width:90%;
}
#sidethreedown{
	width:100%;
	left:00%;
	
	height:50%;
	top:48.5%;
	position: absolute;

}

#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:150%;
	font-weight:500;
}
#wickeddtwooo{
	left:2.5%;
	top:3.5%;
	width:95%;
	height:40%;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:1%;
	width:12.5%;
	height:2.25%;
}

#red{
	color:rgb(173, 21, 21);
	font-size:120%;
}
#margindown{
font-size:220%;
	margin-top:8.5%;
}
#greeng{
	margin-top:1%;
	font-size:105%;
}

#wide{
	width:14.5%;
	height:3.25%;
	left:.5%;
	margin-top:1%;
}
#raporttt{
	font-size:130%;

}
#boldgreen{
color:green;
font-size:140%;
font-weight:500;
}
#control{
	width:50%;
	font-size:140%;
}

#wickeddtwo{
	left:1.5%;
	top:5.5%;
	width:97.5%;
	height:80%;
}

#examplarsxvt{
	width:150%;
	margin-top:-27.5%;
}
#widthittttt{
margin-top:0.6%;
width:89%;
font-size:110%;

}
#boldtre{
font-size:110%;
}
#widthitttty{
margin-top:0.6%;
width:69%;
font-size:110%;

}

#goupxxx{
font-weight:500;
margin-top:-1%;
font-size:110%;
}
#goupx{
	margin-top:-1%;
}
#increase{

font-size:120%;
}
#increases{
margin-top:1%;
font-size:200%;
}
#increasess{
margin-top:1%;
font-size:140%;
}

#raportt{
margin-top:1%;
}
#wickedtwo{
	left:-5%;
	top:-5%;
	border:2px solid rgb(47, 2, 2);
	width:85%;
	color:rgba(242, 60, 60, 0.193);
	height:25%;
}
#wickedclll{
	left:7.5%;
	top:-15%;
	width:530%;
	height:125%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedcll{
	left:7.5%;
	top:-5%;
	width:130%;
	height:120%;
	position:absolute;
	z-index:100;
	color:rgb(200,0,0, 0.193);
}
#wickedcl{
	left:1.5%;
	top:-6%;
	width:105%;
	height:50%;
	color:rgb(200,0,0, 0.193);
}
#green{
font-size:110%;
	
}
#boldrd{
	margin-top:1%;
	font-size:110%;
	font-weight:500;
}
#widthitttty{
margin-top:0.6%;
width:69%;
font-size:110%;
}
#goupxx{
	font-size:110%;	
	margin-top:-1%;
}

#boldr{
	margin-top:-1%;
	font-weight:500;
	font-size:110%;
}

#boldg{
	font-weight:500;
	font-size:110%;
	margin-top:1%;
}
#growwup{
font-size:150%;

}
#examplarsxvh{
	width:150%;
}

#examplarsxvhh{
	width:150%;
	margin-top:-50%;
}

#examplarsxvhhh{
	width:150%;
	margin-top:-100%;
}
#wicked{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:100%;
}
#examplarsxv{
	width:200%;
}
#blind{
	width:250%;
	margin-top:00%;
}
#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:35%;
	right:5%;
	height:37.5%;
	position:absolute;
	
}
#preorderpurchase{
	position: absolute;
	width:80%;
	right:10%;
	height:30%;
	top:5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}
#goup{
	font-size:110%;
text-align: right;
margin-top:-3%;
}

#atad{
	margin-top:2%;
	font-size:120%;
}
#gouppp{
	font-size:120%;
	line-height: 1.5;
}
#preorderclose{
	position: absolute;
	width:13.75%;
	height:2.25%;
	right:15%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#preorder{
	position: absolute;
	width:70%;
	right:15%;
	height:30%;
	top:2.5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}

#heartsig{
	margin-top:-1%;
	width:6%;
	right:6%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.1%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#heartsigs{
	margin-top:-1%;
	width:6%;
	right:13%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.1%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}

#heartsigss{
	margin-top:-1%;
	width:6%;
	right:20%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3.1%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#examplarsxvcii{
	margin-top:-34%;
	margin-left:1%;
	width:100%;

}

#heart{
	margin-top:6%;
	width:95%;
	height:40%;
	position: relative;
}
#examplarsxvc{
	margin-top:1%;
	margin-left:1%;
	width:20.5%;
}
#wished{
	top:3.5%;
	left:-0.5%;
	width:95%;
	height:30%;
	position: absolute;
}
#gorightg{
	margin-top:15%;
	z-index:10;
	font-size:110%;
	margin-right:-1%;
	position:relative;
	text-align: right;
}
#gorightgs{
	margin-top:8%;
	font-size:110%;
	z-index:10;
	position:relative;
	text-align: left;
}
#bold{
font-size:105%;
font-weight:500;
}
#gorightt{
	margin-top:0%;
	margin-left:-2.5%;
	font-weight:500;
	font-size:130%;
	text-align: left;
}
#gorightgs{
	margin-top:6%;
	margin-left:2.5%;
	z-index:10;
	position:relative;
	text-align: left;
}
#dpe{
	font-size:100%;
}
#con{
	margin-top:.75%;
}
#gorightg{
	margin-top:19%;
	z-index:10;
	margin-right:-2%;
	position:relative;
	text-align: right;
}
#wickedccc{
	left:80.5%;
	top:27.5%;
	width:22.5%;
	height:63%;
	
}

#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:47.5%;
	top:99.25%;
	position: absolute;
}
#angle{
	position:absolute;
	width:100%;
	height:100%;
	background-color:rgb(6,6,6);
	top:402.5%;
}
#linessg{
	width:97.5%;
	line-height:22%;
	top:25%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
height:99.5%;
}

#sizing{
	font-size:120%;
}
#greenbeansgo{
	margin-top:2%;
	font-size:110%;
}
#gorighty{
font-size:110%;
margin-top:2.25%;
text-align: right;
}
#gorightst{
	margin-left:1.5%;
	font-size:110%;
	margin-top:.75%;
	
}
#gorightt{
	margin-top:0.25%;
	margin-left:-2.5%;
	font-weight:500;
	font-size:120%;
	text-align: left;
}
#greenbeansssr{
	margin-top:6%;
	margin-right:-3.5%;
	text-align:right;
	font-size:125%;
	padding-right:15%;
}
#wickedcc{
	left:3.5%;
	top:7.5%;
	width:97.5%;
	height:80%;
}

#examplarsxvzz{
	margin-top:-7.5%;
	width:110%;
}
#boldgg{
	font-weight:500;
	font-size:120%;
	margin-top:0.75%;
}
#greenbeansb{
	margin-top:1%;
	font-size:110%;
	font-weight:bold;
}

#greenbeans{
	font-size:110%;
	margin-top:1%;
}
#godownsg{
	margin-top:1.25%;
	font-size:120%;
	margin-left:1.75%;
	font-style:italic;
}
#iconess{
position:absolute;
top:22.5%;
right:30%;
width:20%;
height:30%;
color:rgba(46, 46, 46, 0.193);

}
#widthu{
	width:20%;
	font-size:120%;
	font-weight:bold;
	margin-right:-1.5%;
	margin-top:0%;
}
#green{
	margin-top:0.6%;
	font-size:120%;
	line-height:1.2;
}
#wickedc{
	left:2.5%;
	top:4.5%;
	width:100%;
	height:60%;
}
#greensee{
	color:rgb(20,150,20);
	font-size:110%;
	font-weight:500;
}

#upin{
	position:relative;
	z-index:50;
	margin-top:1%;
	
	
}
#raportttt{
margin-top:1%;
}

}



@media only screen and (max-width:431px) and (max-height:933px) and (min-height:850px){

	#con{
	margin-top:15%;
}#wickedtwo{
	left:1%;
	margin-left:0%;
	width:98%;
	color:rgba(242, 60, 60, 0.193);
	height:80%;
}
#mango{
	position:absolute;
	width:100%;
	top:4%;
	height:100%;
	right:0%;
	
}
#widthitt{
	margin-top:1%;
	width:50%;

	font-size:180%;
}

#godowns{
	margin-top:0.5%;
	font-size:120%;
	width:80%;
	line-height:1.5;
}

#get{
	font-size:120%;
	line-height:2;
}
#controls{
	width:50%;
	margin-top:1%;
	font-size:130%;
	line-height:1.4;
}

#acceptqrr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:33%;
	right:5%;
	height:5%;
	text-align:right;
	position:absolute;

}
#gorightgs{
	margin-top:10%;
	font-size:100%;
	z-index:10;
	line-height:1.5;
	position:relative;
	text-align: left;
}
#angle{
	position:absolute;
	width:100%;
	height:140%;
	background-color:rgb(6,6,6);
	top:532.5%;
	z-index:190;
}

#greent{
	font-weight:bold;
	font-size:110%;
	margin-top:1%;
}
#greensee{
	font-size:110%;
}
#heart{
	margin-top:8%;
	width:95%;
	height:40%;
	position: relative;
}
#twww{
visibility:hidden;
}
#clone{
	width:32.5%;
	position: absolute;
	top:95%;
	right:2.5%;
	height:2.25%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}

#clones{
	width:42.5%;
	position: absolute;
	top:95%;
	right:37%;
	height:2.25%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	border:2px solid black;
}
#tw{

	font-size:150%;
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:98.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#environment{
	width:100%;
	left:0%;
	top:26.5%;
	position: absolute;
	height:100%;

}
#worldinfo{
	width:100%;
	height:100%;
	top:37.5%;

	position: absolute;
	background-color: rgba(63, 82, 110, 0.482);
}
#icon{
	left:1%;
	margin-top:2.75%;
	width:45%;
	height:5%;
	color:rgba(16, 16, 16, 0.193);
}
#planet{
	width:80%;
	left:1%;
	height:5.5%;
}
#twww{
	margin-top:0.5%;
	width:85%;
}
#twwwx{
	margin-top:1%;
	visibility:hidden;
	width:85%;
}
#tww{
	margin-top:0.5%;
	width:85%;
	font-size:130%;
	line-height:1.5;
}
#blind{
	width:560%;
	margin-top:00%;
}
#wickeddtwooo{
	left:2.5%;
	top:2.5%;
	width:95%;
	height:60%;
}
#goupx{
	font-weight:bold;
}
#boldr{
	margin-top:-2%;
	font-weight:500;
	line-height:1.2;
}

#boldfr{
	font-size:100%;
	width:90%;
	margin-top:1%;
}
#matrix{
	width:30%;
	font-size:120%;
}
	
#dec{
	font-size:120%;
}
#greengg{
	margin-top:-5%;
	font-size:170%;
}

#widthittttt{
margin-top:1%;
width:89%;
font-size:120%;
line-height:1.3;
}
#clearup{
	font-size:140%;
}
#getyoup{

	font-size:140%;
	
}
#getyouppp{

font-size:140%;
margin-top:-2%;
}

#getyoupp{
	width:100%;
	margin-top:5%;
	margin-left:-1%;
	line-height:50%;
	background-color:black;
}
#widthitttty{
margin-top:0.6%;
width:69%;
line-height:1.2;
font-size:120%;
}
#goupxxx{
font-weight:500;
margin-top:-1%;
font-size:120%;
width:70%;
line-height:1.4;
}

#goupx{
	margin-top:-2%;
}
#linessss{
	width:99%;
	line-height:75%;
	top:7.85%;
	left:0.5%;
	font-size:100%;
	filter:drop-shadow(2px 0px 2px rgb(43, 43, 43));
	color:transparent;
	background-color: rgb(15, 15, 15);
}
#fatesd{
	font-size:150%;
}
#upper{
	font-size:150%;
}
#raporttt{
line-height:1.3;

}
#widthitty{
	margin-top:1%;
	width:50%;
	line-height:1.1;
	text-align:left;
	font-size:180%;
}
#boldrr{
	font-weight:bold;
}
#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:200%;
	line-height:1.5;
	

}

#saintit{
	margin-top:5%;
}
#widthittyy{
	margin-top:0%;
	width:90%;

	font-size:170%;
}
#fataa{
	font-size:130%;
}

#fataaa{
	font-size:150%;
}
#sidealldown{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:150.75%;
	top:99.25%;
	position: absolute;
}
#examplarsxvv{
	margin-top:-34%;
	width:350%;
}
#boldgggx{
	font-weight:500;
	margin-top:1.5%;
	font-size:150%;
	line-height:1.3;
}
#boldgggm{

margin-top:0.5%;
font-size:150%;
line-height:1.35;
}
#fatesd{
	font-size:150%;
}
#boldgggg{
	font-weight:500;
	margin-top:1.5%;
	font-size:120%;
}
#boldggg{
	font-weight:500;
	margin-top:0.5%;
	font-size:130%;
	line-height:1.2;
}
#downcard{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:20%;
right:0%;
top:625%;
color:white;
text-align:left;
padding-top:25px;
font-size:150%;
border-top:15px solid rgb(10,10,10);
font-weight:600;
z-index:100;
}

#greenbeans{
	width:80%;
	margin-top:1%;
	font-weight:bold;
}
#boldgreen{
color:green;
font-size:120%;
font-weight:500;
}
#examplarsxvtt{
	width:200%;
	margin-top:-5%;
}
#raporttt{
	font-size:120%;

}
#raportt{
	font-size:200%;
	font-weight: bold;
	}
#wickedddtwo{
	left:1.5%;
	top:2.5%;
	width:97.5%;
	height:17%;
}

#boldg{
	font-weight:500;
	font-size:110%;
	margin-top:1%;
	line-height:1.2;
}
#fata{
	font-size:120%;
}
#fatess{
	font-size:100%;
}
#fatess{
	top:1%;
	font-size:120%;
}
#greenss{

color:rgb(29, 162, 44);
margin-top:1.5%;
font-size:120%;
}
#red{
	color:rgb(173, 21, 21);
	font-size:120%;
	font-weight:bold;
}
#wickeddtwoooo{
	left:2.5%;
	margin-top:2%;
	width:47.5%;
	height:3.25%;
}
#godownsg{
	margin-top:1.25%;
	margin-left:1.75%;
	font-style:italic;
	font-size:105%;
}
#examplarsxvvv{
	width:500%;
	margin-top:-274%;
}

#absolutedown{
	position: absolute;
	top:60.5%;
	height:40%;
	width:100%;

	color:white;

}
#greeng{
	font-size:120%;
	margin-top:2%;
}
#wide{
	width:14.5%;
	height:3.25%;
	left:.5%;
	margin-top:1%;
}

#examplarsxvz{
	width:300%;
}
#margindown{
font-size:220%;
	margin-top:21.5%;
}
#wickeddtwoo{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:20%;
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:2%;
	line-height:1.2;
	font-size:150%;
}
#controlshqq{
	width:50%;
	margin-left:15%;
	margin-top:2%;
	line-height:1.2;
	font-size:150%;
}
#controlshqqqqq{
	width:50%;
	line-height:1.5;

	margin-top:3%;
	font-size:200%;
}

#controlshqqqqqq{
	width:100%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:110%;
	font-weight:500;
}
#controlshqqqq{
	width:100%;
	font-weight:500;
	text-align:left;
	margin-top:3%;
	margin-left:0%;
	width:50%;
	font-size:200%;
	line-height: 1.1;
}
#controlshqqq{
	width:50%;
	margin-left:0%;
	margin-top:2%;
	font-weight:500;
	line-height: 1.1;
	font-size:200%;
}
#wickeddtwo{
	left:1.5%;
	top:5.5%;
	width:97.5%;
	height:80%;
}

#examplarsxvt{
	width:300%;
	margin-top:-27.5%;
}

#examplarsxvtt{
	width:280%;
	margin-top:-0%;
}
#hit{
	margin-top:15%;
}
#examplarsxv{
	width:200%;
}
#wicked{
	left:2.5%;
	top:2.5%;
	width:92%;
	height:90%;
}

#sizing{
	font-size:100%;
}
#boldrd{
	font-size:130%;
	margin-top:3%;
	font-weight:500;
}
#green{
	font-size:120%;
	margin-top:0.6%;
}
#lines{
	width:82.5%;
	line-height:22%;
	left:.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#wickedcl{
	left:1%;
	top:-7%;
	width:98%;
	height:50%;
	color:rgb(200,0,0, 0.193);
}
#wicked{
	left:1%;
	top:7.5%;
	width:98%;
	height:90%;
}

#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:111.5%;
	position: absolute;
}
#idiots{

	margin-top:1%;
}
#examplarsxvh{
	width:200%;
	margin-top:-10%;
}

#examplarsxvhh{
	width:200%;
	margin-top:-70%;
}

#examplarsxvhhh{
	width:200%;
	margin-top:-140%;
}
#sideone{
	height:30%;
	position: absolute;
	top:16%;
	width:100%;
}

#sidefour{
	height:30%;
	position: absolute;
	top:88.75%;
	width:100%;
}

#sidefive{
	width:100%;
	left:0%;
	top:102%;
	height:10%;
	z-index:100;
	position: absolute;

}
#sidethreedown{
	width:100%;
	left:00%;
	
	height:50%;
	top:76.5%;
	position: absolute;

}
#sidetwo{
	width:99%;
	left:2%;
	top:43%;
	height:30%;
	position: absolute;

}
#groww{
	color:black;
}
#accept{
	width:40%;
	margin-left:1%;
	margin-top:2%;
	color:rgba(0, 0, 0, 0.36);
	background-color: rgb(8, 143, 17);
}
#acceptqr{
	width:40%;
	margin-left:1%;
	margin-top:2%;
	top:36%;
	left:55%;
	height:23.5%;
	position:absolute;
	
}

#preorderpurchase{
	position: absolute;
	width:100%;
	right:0%;
	height:35%;
	top:5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}
#examplarsxg{
	width:292%;
margin-left:-95%;
}
#goup{
	font-size:110%;
text-align: right;
margin-top:0%;
}

#preorderclose{
	position: absolute;
	width:40.75%;
	height:2.25%;
	right:1.5%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#preorderlabelsss{
	position: absolute;
	top:2.5%;
	font-size:140%;
	color:black;
}
#width{
	width:50%;
	height:30%;
	z-index:50;
	right:0%;
	background-color: rgb(7, 7, 7);
	position: absolute;
	text-align: right;
}
#examplarss{
	width:169%;
}

#buy{
	width:95%;
	height:11%;
	left:2.5%;
	border:2px solid rgb(2, 90, 2);
	top:85%;
	position:absolute;
	color:rgb(19, 19, 19);
	background-color: rgb(0, 128, 45);
}
#example{
	width:29.5%;
	height:80%;
	position:absolute;
	top:0%;
	left:2.5%;
	top:5.5%;
	visibility:hidden;
	z-index: 50;
	
}
#examplarsx{
	width:300%;
	margin-left	:-50%;
	margin-top:-10%;
}
#heartsig{
	margin-top:-1%;
	width:15%;
	right:6%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.9%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#preorder{
	position: absolute;
	width:100%;
	right:0%;
	height:40%;
	top:2.5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}
#heartsigs{
	margin-top:-1%;
	width:15%;
	right:26%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.9%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}

#heartsigss{
	margin-top:-1%;
	width:15%;
	right:46%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:2.9%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#examplarsxvcii{
	margin-top:-34%;
	margin-left:1%;
	width:300%;

}
#abs{
	right:5%;
	width:80%;
	top:5.15%;

	text-align: right;
	z-index: 10;
	font-size:100%;
	pointer-events: none;
	position: absolute;
	color:rgb(228, 228, 228);

}
#hearts{
	margin-top:35.5%;
	width:95%;
	height:20%;
	margin-left:1%;
	position: absolute;
}
#wished{
	top:3.5%;
	left:-0.5%;
	width:95%;
	height:30%;
	position: absolute;
}
#heart{
	margin-top:1%;
	width:95%;
	height:10%;
	position: relative;
}
#droppedclassx {
	width: 100%;
	height: 250%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#droppedclass {
	width: 100%;
	height: 100%;
	right: 0%;
	top: 0%;
	position: absolute;
	z-index: 200;
	color:rgb(214, 214, 214);
	background-color: rgb(9, 9, 10);
}
#goflash{
	width:97.5%;
	margin-left:.5%;
	margin-top:2.5%;
	
}
#heart{
	margin-top:20%;
	width:95%;
	height:30%;
	position: relative;
}
#green{
	margin-top:2.6%;
	line-height:1.2;
}
#examplarsxvc{
	margin-top:10%;
	margin-left:3%;
	width:52.5%;
}
#examplarsxvci{
	margin-top:-20%;
	margin-left:1%;
	width:200%;
}

#angle{
	position:absolute;
	width:100%;
	height:60%;
	background-color:rgb(6,6,6);
	top:595%;
}
#gorightgs{
	margin-top:-15%;
	width:50%;
	z-index:10;
	position:relative;
	text-align: left;
}
#linessg{
	width:97.5%;
	line-height:22%;
	top:21%;
	left:3%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
#wickedccc{
	left:62.5%;
	top:12.5%;
	width:32.5%;
	height:33%;
	
}
#gorightg{
	margin-top:32.5%;
	z-index:10;
	width:50%;
	margin-left:50%;
	position:relative;
	text-align: right;
}
#sidealldownf{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:72.5%;
	top:99.25%;
	position: absolute;
}
#linessgg{
	width:94.5%;
	line-height:22%;
	top:-.5%;
	left:2.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}
#liness{
	width:77.5%;
	line-height:22%;
	top:4%;
	left:1.5%;
	font-size:100%;
	color:transparent;
	background-color: rgb(213, 213, 213);
}

#greenbeans{
	margin-top:1%;
	font-size:110%;
}
#greent{
	font-weight:bold;
	margin-top:1%;
}
#greensee{
	font-weight:bold;
	color:rgb(20,150,20);
	
}
#greenbeans{
	margin-top:1.75%;
}
#greenbeansb{
	font-size:110%;
}
#marginx{
	margin-right:2.5%;
	margin-top:2.5%;
}
#gorighty{
width:130%;
margin-left:-27.5%;
margin-top:2.25%;
font-size:120%;
text-align: right;
}
#gorightt{
	margin-top:-10.2%;
	margin-left:-4.5%;
	font-weight:500;
	font-size:110%;
	text-align: left;
}
#margin{
	margin-left:3.5%;
	margin-top:2.5%;
}
#greenbeansssr{
	margin-top:7.5%;
	margin-left:12.5%;
	text-align:right;
	font-size:100%;
	width:100%;
	padding-right:15%;
}
#wickedcc{
	left:2.5%;
	top:2.5%;
	width:97.5%;
	height:30%;
}

#examplarsxvzz{
	width:150%;
	margin-top:-20%;
}
#godownsg{
	margin-top:3.25%;
	font-size:105%;
	margin-left:1.75%;
	font-style:italic;
}
#boldgg{
	font-weight:500;
	margin-top:2.75%;
	font-size:110%;
}
#wickeddtwooooo{
	left:2.5%;
	margin-top:2%;
	width:42.5%;
	height:4%;
}
#wickedc{
	left:3.5%;
	top:2.5%;
	width:97.5%;
	height:90%;
}
#examplarsxvj{
	width:200%;
	margin-top:-20%;
}
#iconess{
position:absolute;
top:42.5%;
right:10%;
width:24%;
height:30%;
color:rgba(46, 46, 46, 0.193);

}
#upin{
	position:relative;
	z-index:39;
	visibility:hidden;
}
#saints{
width:100%;
background-color: rgb(17, 17, 17);
position: absolute;
height:170%;
top:5%;
text-align: left;
padding-top:10px;

}
#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
visibility:hidden;
height:100%;
}

#gorightst{
	margin-left:1.5%;
	font-size:110%;
	margin-top:.75%;
	
}

#greenbeansgo{
	margin-top:4%;
	font-size:110%;
}

#wide{
	width:37.5%;
	height:3.25%;
	left:1%;
	margin-top:1%;
}

#wickedtwos{
	left:-1%;
	top:3.5%;
	width:99%;
	height:40%;
}
#wickedtwoss{
	left:-1%;
	top:4.5%;
	width:99%;
	height:30%;
}

#wickedtwosss{
	left:-1%;
	top:5.5%;
	width:99%;
	height:22%;
}


#controlshqqqqqqq{
	width:100%;

	margin-top:0.5%;
	font-size:150%;
	line-height:1;
	font-weight:500;
}
#boldgggg{
	font-weight:500;
	font-size:130%;
	margin-top:1.5%;
}
#gof{
font-size:110%;
line-height:1.5;
}
#lineman{
	font-size:120%;
	line-height:1.5;
}
#wickedcll{
	visibility:hidden;
}
#wickedclll{
	visibility:hidden;
}
#preorderone{
	height:35%;
	width:90%;
	left:5%;
	color:rgba(52, 52, 52, 0.222);
	top:2.5%;
}
#preordertwo{
	height:35%;
	width:90%;
	left:5%;
	color:rgba(52, 52, 52, 0.222);
	top:8.5%;
}
}

@media only screen and (max-width:431px) and (max-height:849px) and (min-height:700px){
	#id{
		line-height:1.3;
	}
	#gang{
	margin-top:-320%;
}#smurf{
	width:98%;
	right:-1%;
	margin-top:1%;
}
#mango{
	position:absolute;
	width:100%;
	top:4%;
	height:100%;
	right:0%;
	
}	

#preorderone{
	height:35%;
	width:90%;
	left:5%;
	color:rgba(52, 52, 52, 0.222);
	top:2.5%;
}
#preordertwo{
	height:35%;
	width:90%;
	left:5%;
	color:rgba(52, 52, 52, 0.222);
	top:8.5%;
}
#clearup{
	font-size:70%;
	line-height:1.5;
}
#wickedclll{
	visibility:hidden;
}
#wickedcll{
	visibility:hidden;
}
#con{
margin-top:15%;
}#wickedtwo{
left:1%;
margin-left:0%;
width:98%;
color:rgba(242, 60, 60, 0.193);
height:80%;
}

#widthitt{
margin-top:1%;
width:50%;

font-size:180%;
}

#godowns{
margin-top:0.5%;
font-size:120%;
width:80%;
line-height:1.5;
}

#get{
font-size:120%;
line-height:2;
}
#controls{
width:50%;
margin-top:1%;
font-size:130%;
line-height:1.4;
}

#acceptqrr{
width:30%;
margin-left:1%;
margin-top:2%;
top:30%;
right:5%;
height:5%;
text-align:right;
position:absolute;

}
#gorightgs{
margin-top:10%;
font-size:100%;
z-index:10;
line-height:1.5;
position:relative;
text-align: left;
}
#angle{
position:absolute;
width:100%;
height:140%;
background-color:rgb(6,6,6);
top:532.5%;
z-index:190;
}

#greent{
font-weight:bold;
font-size:110%;
margin-top:1%;
}
#greensee{
font-size:110%;
}
#heart{
margin-top:8%;
width:95%;
height:40%;
position: relative;
}
#twww{
visibility:hidden;
}
#clone{
width:32.5%;
position: absolute;
top:95%;
right:2.5%;
height:2.25%;
color:rgb(179, 179, 179);
padding-right:5px;
text-align: right;
background-color: rgb(13, 13, 13);
z-index: 50;
border:2px solid black;
}

#clones{
width:42.5%;
position: absolute;
top:95%;
right:37%;
height:2.25%;
color:rgb(179, 179, 179);
padding-right:5px;
text-align: right;
background-color: rgb(13, 13, 13);
z-index: 50;
border:2px solid black;
}
#tw{

font-size:150%;
}
#worldview{
width:95%;
right:2.5%;
top:2.5%;
position: absolute;
height:98.5%;
z-index: 160;
background-color: rgb(20, 20, 20);
}
#environment{
width:100%;
left:0%;
top:26.5%;
position: absolute;
height:100%;

}
#worldinfo{
width:100%;
height:100%;
top:37.5%;

position: absolute;
background-color: rgba(63, 82, 110, 0.482);
}
#icon{
left:1%;
margin-top:2.75%;
width:45%;
height:5%;
color:rgba(16, 16, 16, 0.193);
}
#planet{
width:80%;
left:1%;
height:5.5%;
}
#twww{
margin-top:0.5%;
width:85%;
}
#twwwx{
margin-top:1%;
visibility:hidden;
width:85%;
}
#tww{
margin-top:0.5%;
width:85%;
font-size:130%;
line-height:1.5;
}
#blind{
width:560%;
margin-top:00%;
}
#wickeddtwooo{
left:2.5%;
top:2.5%;
width:95%;
height:60%;
}
#goupx{
font-weight:bold;
}
#boldr{
margin-top:-2%;
font-weight:500;
line-height:1.2;
}

#boldfr{
font-size:100%;
width:90%;
margin-top:1%;
}
#matrix{
width:30%;
font-size:120%;
}

#dec{
font-size:120%;
}
#greengg{
margin-top:-5%;
font-size:170%;
}

#widthittttt{
margin-top:1%;
width:89%;
font-size:120%;
line-height:1.3;
}
#clearup{
font-size:120%;
}
#getyoup{

font-size:140%;

}
#getyouppp{

font-size:140%;
margin-top:-2%;
}

#getyoupp{
width:100%;
margin-top:5%;
margin-left:-1%;
line-height:50%;
background-color:black;
}
#widthitttty{
margin-top:0.6%;
width:69%;
line-height:1.2;
font-size:120%;
}
#goupxxx{
font-weight:500;
margin-top:-1%;
font-size:120%;
width:70%;
line-height:1.4;
}

#goupx{
margin-top:-2%;
}
#linessss{
width:99%;
line-height:75%;
top:7.85%;
left:0.5%;
font-size:100%;
filter:drop-shadow(2px 0px 2px rgb(43, 43, 43));
color:transparent;
background-color: rgb(15, 15, 15);
}
#fatesd{
font-size:110%;
}
#upper{
font-size:150%;
}
#raporttt{
line-height:1.3;

}
#widthitty{
margin-top:1%;
width:50%;
line-height:1.1;
text-align:left;
font-size:180%;
}
#boldrr{
font-weight:bold;
}
#controlshq{
width:50%;
margin-left:10%;
margin-top:2%;
font-size:200%;
line-height:1.5;


}

#saintit{
margin-top:5%;
}
#widthittyy{
margin-top:0%;
width:90%;

font-size:170%;
}
#fataa{
font-size:130%;
}

#fataaa{
font-size:150%;
}
#sidealldown{
width:100%;
left:0%;
background-color: rgb(9, 9, 10);
height:167.75%;
top:99.25%;
position: absolute;
}
#examplarsxvv{
margin-top:-34%;
width:350%;
}
#boldgggx{
font-weight:500;
margin-top:1.5%;
font-size:150%;
line-height:1.3;
}
#boldgggm{

margin-top:0.5%;
font-size:150%;
line-height:1.35;
}
#fatesd{
font-size:120%;
}
#boldgggg{
font-weight:500;
margin-top:1.5%;
font-size:120%;
}
#boldggg{
font-weight:500;
margin-top:0.5%;
font-size:130%;
line-height:1.2;
}
#downcard{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:10%;
right:0%;
top:662.5%;
color:white;
text-align:left;
padding-top:25px;
font-size:100%;
border-top:15px solid rgb(10,10,10);
font-weight:600;
z-index:100;
}

#greenbeans{
width:80%;
margin-top:1%;
font-weight:bold;
}
#boldgreen{
color:green;
font-size:120%;
font-weight:500;
}
#examplarsxvtt{
width:200%;
margin-top:-5%;
}
#raporttt{
font-size:120%;

}
#raportt{
font-size:150%;
font-weight: bold;
}
#idiots{
	font-size:125%;
	margin-top:1%;
}
#wickedddtwo{
left:1.5%;
top:2.5%;
width:97.5%;
height:19%;
}

#boldg{
font-weight:500;
font-size:110%;
margin-top:1%;
line-height:1.2;
}
#fata{
font-size:120%;
}
#fatess{
font-size:100%;
}
#fatess{
top:1%;
font-size:120%;
}
#greenss{

color:rgb(29, 162, 44);
margin-top:1.5%;
font-size:120%;
}
#red{
color:rgb(173, 21, 21);
font-size:120%;
font-weight:bold;
}
#wickeddtwoooo{
left:2.5%;
margin-top:2%;
width:47.5%;
height:3.25%;
}
#godownsg{
margin-top:1.25%;
margin-left:1.75%;
font-style:italic;
font-size:105%;
}
#examplarsxvvv{
width:500%;
margin-top:-274%;
}

#absolutedown{
position: absolute;
top:70.5%;
height:40%;
width:100%;

color:white;

}
#greeng{
font-size:120%;
margin-top:2%;
}
#wide{
width:14.5%;
height:3.25%;
left:.5%;
margin-top:1%;
}

#examplarsxvz{
width:300%;
}
#margindown{
font-size:220%;
margin-top:21.5%;
}
#wickeddtwoo{
left:1.5%;
top:2.5%;
width:97%;
height:22%;
}
#controlsh{
width:50%;
margin-left:15%;
margin-top:2%;
line-height:1.2;
font-size:150%;
}
#controlshqq{
width:50%;
margin-left:15%;
margin-top:2%;
line-height:1.2;
font-size:150%;
}
#controlshqqqqq{
width:50%;
line-height:1.5;

margin-top:3%;
font-size:160%;
}

#controlshqqqqqq{
width:100%;

margin-top:2%;
font-size:110%;
font-weight:500;
}

#controlshqqqqqqq{
width:100%;

margin-top:0.5%;
font-size:110%;
font-weight:500;
}
#controlshqqqq{
width:100%;
font-weight:500;
text-align:left;
margin-top:3%;
margin-left:0%;
width:50%;
font-size:130%;
line-height: 1.2;
}
#controlshqqq{
width:50%;
margin-left:0%;
margin-top:2%;
font-weight:500;
line-height: 1.2;
font-size:130%;
}
#wickeddtwo{
left:1.5%;
top:5.5%;
width:97.5%;
height:90%;
}

#examplarsxvt{
width:300%;
margin-top:-27.5%;
}

#examplarsxvtt{
width:280%;
margin-top:-0%;
}
#hit{
margin-top:15%;
}
#examplarsxv{
width:200%;
}
#wicked{
left:2.5%;
top:2.5%;
width:92%;
height:90%;
}

#sizing{
font-size:100%;
}
#boldrd{
font-size:130%;
margin-top:3%;
font-weight:500;
}
#green{
font-size:120%;
margin-top:0.6%;
}
#lines{
width:82.5%;
line-height:22%;
left:.5%;
font-size:100%;
color:transparent;
background-color: rgb(213, 213, 213);
}

#wickedcl{
left:1%;
top:-7%;
width:98%;
height:50%;
color:rgb(200,0,0, 0.193);
}
#wicked{
left:1%;
top:7.5%;
width:98%;
height:90%;
}

#sidesixdown{
width:100%;
left:00%;

height:50%;
top:115.5%;
position: absolute;
}

#examplarsxvh{
width:200%;
margin-top:-10%;
}

#examplarsxvhh{
width:200%;
margin-top:-70%;
}

#examplarsxvhhh{
width:200%;
margin-top:-140%;
}
#sideone{
height:30%;
position: absolute;
top:18%;
width:100%;
}

#sidefour{
height:30%;
position: absolute;
top:91.75%;
width:100%;
}

#sidefive{
width:100%;
left:0%;
top:105%;
height:10%;
z-index:100;
position: absolute;

}
#sidethreedown{
width:100%;
left:00%;

height:50%;
top:79%;
position: absolute;

}
#sidetwo{
width:99%;
left:2%;
top:45%;
height:30%;
position: absolute;

}
#groww{
color:black;
}
#accept{
width:40%;
margin-left:1%;
margin-top:2%;
color:rgba(0, 0, 0, 0.36);
background-color: rgb(8, 143, 17);
}
#acceptqr{
width:40%;
margin-left:1%;
margin-top:2%;
top:36%;
left:55%;
height:23.5%;
position:absolute;

}

#preorderpurchase{
position: absolute;
width:100%;
right:0%;
height:35%;
top:5%;
z-index:150;
background-color: rgb(8, 8, 8);
}
#examplarsxg{
width:292%;
margin-left:-95%;
}
#goup{
font-size:110%;
text-align: right;
margin-top:0%;
}

#preorderclose{
position: absolute;
width:40.75%;
height:2.25%;
right:1.5%;
top:0.1%;
border:2px solid rgb(12, 12, 12);
background-color: rgb(205, 205, 205);
}
#preorderlabelsss{
position: absolute;
top:1.5%;
font-size:140%;
color:black;
}
#width{
width:50%;
height:30%;
z-index:50;
right:0%;
background-color: rgb(7, 7, 7);
position: absolute;
text-align: right;
}
#examplarss{
width:169%;
}

#buy{
width:95%;
height:11%;
left:2.5%;
border:2px solid rgb(2, 90, 2);
top:85%;
position:absolute;
color:rgb(19, 19, 19);
background-color: rgb(0, 128, 45);
}
#example{
width:29.5%;
height:80%;
position:absolute;
top:0%;
left:2.5%;
top:5.5%;
visibility:hidden;
z-index: 50;

}
#examplarsx{
width:300%;
margin-left	:-50%;
margin-top:-10%;
}
#heartsig{
margin-top:-1%;
width:15%;
right:6%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.1%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}
#preorder{
position: absolute;
width:100%;
right:0%;
height:40%;
top:2.5%;
z-index:150;
background-color: rgb(8, 8, 8);
}
#heartsigs{
margin-top:-1%;
width:15%;
right:26%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.1%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}

#heartsigss{
margin-top:-1%;
width:15%;
right:46%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.1%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}
#examplarsxvcii{
margin-top:-34%;
margin-left:1%;
width:300%;

}
#abs{
right:5%;
width:80%;
top:6.25%;

text-align: right;
z-index: 100;
font-size:100%;
pointer-events: none;
position: absolute;
color:rgb(228, 228, 228);

}

#wished{
top:3.5%;
left:-0.5%;
width:95%;
height:30%;
position: absolute;
}
#hearts{
margin-top:37.5%;
width:95%;
height:20%;
margin-left:1%;
position: absolute;
}
#heart{
margin-top:1%;
width:95%;
height:10%;
position: relative;
}
#droppedclassx {
width: 100%;
height: 250%;
right: 0%;
top: 0%;
position: absolute;
z-index: 200;
color:rgb(214, 214, 214);
background-color: rgb(9, 9, 10);
}
#droppedclass {
width: 100%;
height: 100%;
right: 0%;
top: 0%;
position: absolute;
z-index: 200;
color:rgb(214, 214, 214);
background-color: rgb(9, 9, 10);
}
#goflash{
width:97.5%;
margin-left:.5%;
margin-top:2.5%;

}
#heart{
margin-top:20%;
width:95%;
height:30%;
position: relative;
}
#green{
margin-top:2.6%;
line-height:1.2;
}
#examplarsxvc{
margin-top:10%;
margin-left:3%;
width:52.5%;
}
#examplarsxvci{
margin-top:-20%;
margin-left:1%;
width:200%;
}

#angle{
position:absolute;
width:100%;
height:60%;
background-color:rgb(6,6,6);
top:650%;
}
#gorightgs{
margin-top:-15%;
width:50%;
z-index:10;
position:relative;
text-align: left;
}
#linessg{
width:97.5%;
line-height:22%;
top:24%;
left:3%;
font-size:100%;
color:transparent;
background-color: rgb(213, 213, 213);
}
#wickedccc{
left:62.5%;
top:12.5%;
width:32.5%;
height:33%;

}
#gorightg{
margin-top:32.5%;
z-index:10;
width:50%;
margin-left:50%;
position:relative;
text-align: right;
}
#sidealldownf{
width:100%;
left:0%;
background-color: rgb(9, 9, 10);
height:72.5%;
top:99.25%;
position: absolute;
}
#linessgg{
width:94.5%;
line-height:22%;
top:-.5%;
left:2.5%;
font-size:100%;
color:transparent;
background-color: rgb(213, 213, 213);
}
#liness{
width:77.5%;
line-height:22%;
top:4%;
left:1.5%;
font-size:100%;
color:transparent;
background-color: rgb(213, 213, 213);
}

#greenbeans{
margin-top:1%;
font-size:110%;
}
#greent{
font-weight:bold;
margin-top:1%;
}
#greensee{
font-weight:bold;
color:rgb(20,150,20);

}
#greenbeans{
margin-top:1.75%;
}
#greenbeansb{
font-size:110%;
}
#marginx{
margin-right:2.5%;
margin-top:2.5%;
}
#gorighty{
width:130%;
margin-left:-27.5%;
margin-top:2.25%;
font-size:120%;
text-align: right;
}
#gorightt{
margin-top:-10.2%;
margin-left:-4.5%;
font-weight:500;
font-size:110%;
text-align: left;
}
#margin{
margin-left:3.5%;
margin-top:2.5%;
}
#greenbeansssr{
margin-top:7.5%;
margin-left:12.5%;
text-align:right;
font-size:100%;
width:100%;
padding-right:15%;
}
#wickedcc{
left:2.5%;
top:2.5%;
width:97.5%;
height:30%;
}

#examplarsxvzz{
width:150%;
margin-top:-20%;
}
#godownsg{
margin-top:3.25%;
font-size:105%;
margin-left:1.75%;
font-style:italic;
}
#boldgg{
font-weight:500;
margin-top:2.75%;
font-size:110%;
}
#wickeddtwooooo{
left:2.5%;
margin-top:2%;
width:42.5%;
height:4%;
}
#wickedc{
left:3.5%;
top:2.5%;
width:97.5%;
height:90%;
}
#examplarsxvj{
width:200%;
margin-top:-20%;
}
#iconess{
position:absolute;
top:42.5%;
right:10%;
width:24%;
height:30%;
color:rgba(46, 46, 46, 0.193);

}
#upin{
position:relative;
z-index:39;
visibility:hidden;
}
#saints{
width:100%;
background-color: rgb(17, 17, 17);
position: absolute;
height:170%;
top:5%;
text-align: left;
padding-top:10px;

}
#iconegg{
position:absolute;
right:25%;
margin-top:0%;
width:18%;
top:0%;
visibility:hidden;
height:100%;
}

#gorightst{
margin-left:1.5%;
font-size:110%;
margin-top:.75%;

}

#greenbeansgo{
margin-top:4%;
font-size:110%;
}

#wide{
width:37.5%;
height:3.25%;
left:1%;
margin-top:1%;
}

#wickedtwos{
left:-1%;
top:3.5%;
width:99%;
height:40%;
}
#wickedtwoss{
left:-1%;
top:4.5%;
width:99%;
height:30%;
}

#wickedtwosss{
left:-1%;
top:5.5%;
width:99%;
height:24%;
}


#controlshqqqqqqq{
width:100%;

margin-top:0.5%;
font-size:150%;
line-height:1;
font-weight:500;
}
#boldgggg{
font-weight:500;
font-size:130%;
margin-top:1.5%;
}
#gof{
font-size:110%;
line-height:1.5;
}
#lineman{
font-size:120%;
line-height:1.5;
}
#sideonec{
	height:25%;
	position: absolute;
	z-index:250;
	width:95%;
}
}
@media only screen and (max-width:431px) and (max-height:700px) {

	#preorderpurchase{
position: absolute;
width:100%;
right:0%;
height:37.5%;
top:5%;
z-index:150;
background-color: rgb(8, 8, 8);
}
#gorightg{
margin-top:40.5%;
z-index:10;
width:50%;
margin-left:50%;
position:relative;
text-align: right;
}
#heartsig{
margin-top:-1%;
width:15%;
right:6%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.5%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}
#heartsigs{
margin-top:-1%;
width:15%;
right:26%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.5%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}

#heartsigss{
margin-top:-1%;
width:15%;
right:46%;
top:20.5%;
color:rgba(53, 53, 53, 0.207);
height:3.5%;
background-color: rgba(27, 27, 27, 0.126);
position: absolute;
}
#wished{
top:4.5%;
left:-0.5%;
width:95%;
height:30%;
position: absolute;
}
#gorightgs{
margin-top:-8%;
width:50%;
z-index:10;
position:relative;
text-align: left;
}
#angle{
position:absolute;
width:100%;
height:60%;
background-color:rgb(6,6,6);
top:740%;
}
#downcard{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:10%;
right:0%;
top:705%;
color:white;
text-align:left;
padding-top:25px;
font-size:130%;
border-top:15px solid rgb(10,10,10);
font-weight:600;
z-index:100;
}
#discs{
	position: absolute;
	width:100%;
	top:0%;
	right:0%;
	height:722.5%;
	background-color: rgb(8, 8, 8);
}
#absolutedown{
	position: absolute;
	top:80.5%;
	height:40%;
	width:100%;

	color:white;

}
#sidesixdown{
width:100%;
left:00%;

height:55%;
top:121.5%;
position: absolute;
}
#sidefive{
width:100%;
left:0%;
top:112%;
height:10%;
z-index:100;
position: absolute;

}
#sidefour{
height:30%;
position: absolute;
top:98.75%;
width:100%;
}
#sidethreedown{
width:100%;
left:00%;

height:50%;
top:84%;
position: absolute;

}
#wicked{
left:1%;
top:17.5%;
width:98%;
height:90%;
}
#sidetwo{
width:99%;
left:2%;
top:50%;
height:30%;
position: absolute;

}
#acceptqr{
width:40%;
margin-left:1%;
margin-top:2%;
top:40%;
left:55%;
height:23.5%;
position:absolute;

}


#greenbeansss{
	position:relative;
	z-index:200;
	margin-top:27.5%;
}

}
@media only screen and (min-width:1550px) {


	#sidethreedown{
	width:100%;

	
	height:50%;
	top:46.25%;
	position: absolute;

}
#is{
	font-size:170%;
	line-height:1.5;	
}
#lineman{
	font-size:80%;
	line-height:1.2;
}
#sun{
	font-weight:bold;
}
#acceptqrr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:72.5%;
	right:5%;
	font-size:130%;
	height:5%;
	text-align:right;
	position:absolute;

}

#gof{
font-size:130%;
line-height:1.3;
}

#abs{
	right:5%;
	width:80%;
	top:2%;

	text-align: right;
	z-index: 10;
	font-size:120%;
	pointer-events: none;
	position: absolute;
	color:rgb(228, 228, 228);

}
#heartsig{
	margin-top:-1%;
	width:4%;
	right:6%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#heartsigs{
	margin-top:-1%;
	width:4%;
	right:11%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}

#heartsigss{
	margin-top:-1%;
	width:4%;
	right:16%;
	top:20.5%;
	color:rgba(53, 53, 53, 0.207);
	height:3%;
	background-color: rgba(27, 27, 27, 0.126);
	position: absolute;
}
#scale{
	width:100%;
}

#scaless{
	width:102.5%;
	margin-left:-.5%;
}
#scales{
	margin-left:-35%;
	height:97%;
	width:170%;
}
#acceptqr{
	width:30%;
	margin-left:1%;
	margin-top:2%;
	top:30%;
	right:3%;
	height:40%;
	position:absolute;

}
#og{
	font-size:170%;
	line-height:2;
}
#twww{
	margin-top:0.5%;
	width:35%;
	font-size:150%;
	line-height:2;
}
#icon{
	left:1%;
	margin-top:0.75%;
	width:20%;
	height:1%;
	color:rgba(16, 16, 16, 0.193);
}

#blinds{
	width:100%;
	margin-top:-30%;
}
#blind{
	width:140%;
	margin-top:00%;
}
#worldview{
	width:95%;
	right:2.5%;
	top:2.5%;
	position: absolute;
	height:72.5%;
	z-index: 160;
	background-color: rgb(20, 20, 20);
}
#goup{
	font-size:110%;
text-align: right;
margin-top:0%;
}#clone{
	width:12.5%;
	position: absolute;
	top:95%;
	right:2.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	padding-top:7px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	font-size:120%;
	border:2px solid black;
}

#clones{
	width:12.5%;
	position: absolute;
	top:95%;
	right:15.5%;
	height:3%;
	color:rgb(179, 179, 179);
	padding-right:5px;
	padding-top:7px;
	text-align: right;
	background-color: rgb(13, 13, 13);
	z-index: 50;
	font-size:120%;
	border:2px solid black;
}

#gorightse{
	font-size:150%;
	line-height:1.6;
	margin-top:1%;
	margin-left:1.5%;
}

#gorights{
	font-size:150%;
	line-height:1.6;
	margin-left:1.5%;
}
#dosumm{
	font-size:200%;
	line-height:2;
}
#tww{
	margin-top:0.5%;
	width:35%;
	line-height:2;
	font-size:150%;
}
#preorderclose{
	position: absolute;
	width:10.75%;
	height:2%;
	right:25%;
	top:0.1%;
	font-size:110%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
#groww{
	font-size:135%;
}
#accept{
	width:20%;
	margin-left:1%;
	margin-top:2%;
font-size:130%;
	background-color: rgb(8, 143, 17);
}
#ortega{
	font-size:120%;
	margin-top:1%;
}
#gotolil{
	margin-left:1%;
	margin-top:1%;
	font-size:130%;
	line-height:2;
	width:70%;

}
#inter{
	font-size:130%;
	line-height:2;
}
#preorderlabel{
	position: absolute;
	top:63.5%;
	font-size:120%;
	line-height:2;
	color:black;
}
#preorderlabels{
	position: absolute;
	top:48%;
	font-size:120%;
	line-height:2;
	color:black;
}
#preorderlabelss{
	position: absolute;
	top:32%;
	font-size:110%;
	line-height:2;
	color:black;
}
#goupgv{
	font-size:100%;
	line-height:2;
}
#thesize{

text-align: right;
font-size:150%;
font-size: 100%;
}
#atad{
	margin-top:2%;
	line-height:1.5;
	font-size:120%;
}
#gouppp{
	font-size:120%;
	line-height:1.5;
}
#preorder{
	position: absolute;
	width:50%;
	right:25%;
	height:30%;
	top:2.5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}

#gorightgs{
	margin-top:5%;
	font-size:130%;
	z-index:10;
	position:relative;
	text-align: left;
}
#gorightg{
	margin-top:15%;
	z-index:10;
	margin-right:-1%;
	font-size:130%;
	position:relative;
	text-align: right;
	z-index:220;
}
#wickedccc{
	left:80.5%;
	top:27.5%;
	position:relative;
	z-index:200;
	width:22.5%;
	height:87%;
	
}

#grow{
	font-size:150%;
	line-height:2;
}
#greenbeansss{
	position:relative;
	z-index:200;
	font-size:200%;
	margin-top:6.5%;
}
#greenbeansgo{
	margin-top:2%;
	position:relative;
	z-index:200;
	font-size:130%;
	
}
#greenbeans{
	position:relative;
	z-index:200;
	margin-top:1%;
	line-height:2;
}
#gorighty{
	position:relative;
	z-index:200;
	font-size:150%;
	line-height:2;
margin-top:2.25%;
text-align: right;
}
#gorightst{
	margin-left:1.5%;
	position: relative;
	z-index:200;
	margin-top:.75%;
	font-size:140%;
	line-height:2;
	
}
#gorightt{
	margin-top:-.75%;
	margin-left:-3.5%;
	font-weight:500;
	font-size:150%;
	line-height:2;
	text-align: left;
	position:relative;
	z-index:200;
}
#baeug{
	font-size:150%;
	line-height:2;
}
#greenbeanss{
	font-size:150%;
	margin-top:3.5%;
	line-height:2;
}

#greenbeansssr{
	margin-top:5.5%;
	margin-right:-5.5%;
	text-align:right;
	position:relative;
	z-index:200;
	font-size:130%;
	padding-right:15%;
}
#wickedcc{
	left:17.5%;
	top:12.5%;
	width:82.5%;
	height:100%;
	position:relative;
	z-index:150;
}

#examplarsxvzz{
	width:150%;
}
#godownsg{
	margin-top:1.25%;
	margin-left:1.75%;
	font-style:italic;
	font-size:130%;
}
#greenbeansb{
	margin-top:1%;
	font-size:130%;
	font-weight:bold;
}
#greent{
	font-weight:bold;
	margin-top:0.6%;
	font-size:130%;
}
#greensee{
	font-size:140%;
	color:rgb(20,150,20);
	
}
#some{
	line-height:2;
}
#thisthird{
	font-size:150%;
	line-height:2;
}
#thisthat{
	font-size:130%;
	line-height:2;
}
#golo{
	font-size:150%;
	line-height:2;
}
#wished{
	top:3.75%;
	left:-0.5%;
	width:95%;
	height:30%;
	position: absolute;
}
#gottabe{
	font-size:150%;
	line-height:1.5;
}
#wickedtwo{
	left:-5%;
	top:-1%;
	border:2px solid rgb(7, 2, 2);
	width:101.5%;
	color:rgba(242, 60, 60, 0.193);
	height:97%;
}
#widthittn{

font-size:150%;
}
#growwup{
font-size:150%;
line-height:2;

}
#goupx{
	font-size:105%;
	margin-top:-3%;
	line-height:1.3;
}

#increasesc{
	font-size:120%;
	line-height:1.5;
}
#boldr{
	font-size:130%;
	line-height:1.5;
}
#examplarsxvh{
	width:120%;
}

#examplarsxvhh{
	width:120%;
	margin-top:-40%;
}

#examplarsxvhhh{
	width:120%;
	margin-top:-75%;
}
#boldtre{
font-size:130%;
line-height:1.5;
}

#examplarsxvii{
	width:100%;
	margin-left:0%;
	margin-top:-0%;
}#entice{
	font-size:200%;
	margin-top:1%;
}
#goupxx{
font-size:130%;
margin-top:0%;
line-height:1.5;
}

#getyouppx{
	font-size:150%;
	line-height:1.5;
}
#widthittttt{
margin-top:.6%;
width:89%;
font-size:130%;
line-height:1.5;
}


#widthitttty{
margin-top:0.6%;
width:69%;
font-size:130%;
line-height:1.5;

}
#goupxxx{
font-weight:500;
margin-top:0%;
font-size:130%;
line-height:1.5;
}
#getyoup{
	font-size:150%;
	line-height:1.5;
}
#getyouppp{

font-size:150%;
line-height:1.5;
margin-top:-2%;
}

#goupnow{
	font-size:150%;
	line-height:1.5;
}
#hitup{
	font-size:120%;
}
#wickeddtwo{
	left:2.5%;
	top:5.5%;
	width:87.5%;
	height:90%;
}
#falsehope{
	font-size:120%;
}
#raporttt{
	font-size:120%;
}
#wickedddtwo{
	left:1.5%;
	top:2.5%;
	width:97.5%;
	height:12.5%;
}
#sun{
	font-size:130%;
	line-height:1.5;
}
#sunn{
	font-size:150%;
	line-height:1.5;
}
#supper{
	font-size:110%;
}
#boldgreen{
color:green;
font-weight:500;
margin-left:0.5%;
}
#greengg{
	margin-left:0.5%;
	margin-top:0%;
}
#dr{
	font-size:150%;
	line-height:1.5;
}

#drr{
	line-height:1.5;
	font-size:200%;
}

#drrr{
	line-height:1.5;
	font-size:110%;
}
#increasedr{
	font-size:250%;
	line-height:1.5;
}
#bold{
	font-size:130%;
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:150%;
}
#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:170%;
	font-weight:500;
}
#widthittyy{
	margin-top:0%;
	width:50%;
	line-height:1.5;
	font-size:200%;
}
#controlsh{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	line-height:1.5;
	font-size:150%;
}
#controlshqq{
	width:50%;
	margin-left:15%;
	margin-top:1%;
	font-size:150%;
	line-height:1.5;
}
#controlshqqq{
	width:50%;
	margin-left:0%;

	font-weight:500;
	line-height:1.5;
	font-size:130%;
}

#controlshqqqq{
	width:55%;
	font-weight:500;

	margin-top:1%;
	margin-left:0%;
	font-size:150%;
	line-height:1.5;
}

#controlshqqqqq{
	width:50%;


	margin-top:1%;
	font-size:150%;
	line-height:1.2;
}

#controlshqqqqqq{
	width:50%;

	margin-top:2%;
	font-size:110%;
	font-weight:500;
}

#controlshqqqqqqq{
	width:50%;

	margin-top:0.5%;
	font-size:180%;
	line-height:1.5;
	font-weight:500;
}
#controlshq{
	width:50%;
	margin-left:10%;
	margin-top:2%;
	font-size:160%;
	line-height:1.5;
	font-weight:500;
}
#widthit{
	margin-top:1%;
	width:50%;
	font-size:150%;
	line-height:1.5;
}
#godowns{
	font-size:120%;
	line-height:1.5;
	margin-top:0.5%;
}

#bold{
	font-size:130%;
	line-height:1.5;
}
#boldgggm{

margin-top:0.5%;
font-size:200%;
line-height:1.5;

}
#boldgggx{
font-weight:500;
margin-top:1.5%;
font-size:180%;
line-height:1.5;
}
#forsure{
	font-size:200%;
	line-height:1.5;
}
#controls{
	width:50%;
	margin-top:1%;
	line-height:1.5;
	font-size:130%;
}

#boldgggg{
	font-weight:500;
	margin-top:1.5%;
	font-size:140%;
}
#boldggg{
	font-weight:500;

	font-size:170%;
	line-height:1.2;
}
#examplarsxvii{
	width:120%;

	margin-top:-90%;
}
#raport{
	font-size:130%;
	line-height:1.5;
}
#widthitt{
	margin-top:1%;
	width:50%;
	line-height:1.5;
	font-size:200%;
}

#raportt{
	font-size:200%;
	line-height:2;
	margin-top:2%;
	font-weight: bold;
	}
#donp{
	font-size:190%;
	margin-top:2%;
}
#examplarsxvv{
	margin-top:-34%;
	width:120%;
}
#absoluteg{
	position: absolute;
	width:100%;
	top:0%;
	right:0%;
	text-align:left;
	color:black;
}

#increasesss{
	font-size:120%;
}
#wickeddtwo{
	left:2.5%;
	top:5.5%;
	width:87.5%;
	height:85%;
}
#boldg{
	font-size:130%;
	line-height:1.3;
}
#greenbeans{
	font-size:130%;
	line-height:1.5;
}
#boldfr{
	font-size:130%;
	line-height:1.3;
}
#bigfont{
	font-size:1300%;
}
#hitup{
	font-size:130%;
}
#matrix{
	font-size:130%;
	line-height:1.7;
}
#id{
	margin-left:0.5%;
	font-size:120%;
}	
#inceasesss{
	line-height:1.3;
	font-size:180%;
}

#raporttt{
	font-size:140%;
	line-height:1.5;
}
#wickeddtwo{
	left:1.5%;
	top:5.5%;
	width:97.5%;
	height:90%;
}
#examplarsxvt{
	width:140%;
	margin-top:-27.5%;
}
#boldgreen{
color:green;
font-size:160%;
font-weight:500;
}
#greengg{
	font-size:210%;
	margin-top:2%;
}
#greenss{
	font-size:130%;
color:rgb(29, 162, 44);
margin-top:0.6%;

}
#red{
	font-size:150%;
	color:rgb(173, 21, 21);
}
#hesus{
	font-size:160%;
}
#wide{
	width:14.5%;
	height:3.25%;
	left:.5%;
	margin-top:1%;
}

#goupsome{
	font-size:190%;
	margin-top:-12.5%;
}

#green{
	font-size:130%;
	margin-top:0.6%;
	line-height:1.5;
}
#godownsome{
	font-size:190%;
}
#increasess{
margin-top:1%;
line-height:1.5;
font-size:160%;
}

#greeng{
	font-size:120%;
	margin-top:1%;
}
#sidealldown{
	width:100%;
	left:0%;
	background-color: rgb(9, 9, 10);
	height:137.5%;
	top:99.25%;
	position: absolute;
}

#downcard{

background-color:rgb(7.5,7.5,7.5);
position:absolute;
width:100%;
height:10%;
right:0%;
top:590%;
color:white;
text-align:left;
padding-top:25px;
font-size:130%;
border-top:15px solid rgb(10,10,10);
font-weight:600;
z-index:100;
}
#boldgg{
	font-weight:500;
	margin-top:0.75%;
	font-size:120%;
}

#absolutedown{
	position: absolute;
	top:51.5%;
	height:40%;
	width:100%;

	color:white;

}
#sidesixdown{
	width:100%;
	left:00%;
	
	height:50%;
	top:89%;
	position: absolute;
}

#wickedtwo{
	left:1.5%;
	top:76%;
	border:2px solid rgb(7, 2, 2);
	width:97%;
	color:rgba(242, 60, 60, 0.193);
	height:25%;
}
#sidefour{
	height:30%;
	position: absolute;
	top:52%;
	width:100%;
}
#sidefive{
	width:100%;
	left:00%;
	top:57.75%;
	height:30%;
	position: absolute;

}
#wickedcl{
	left:1.5%;
	top:2.5%;
	width:97%;
	height:90%;
	color:rgb(200,0,0, 0.193);
}

#examplarsxvvy{
	margin-top:-50%;
	width:100%;

}
#huy{
	margin-left:1%;
	margin-top:1%;
	width:20%;
}
#raportttt{
margin-top:1%;
}
#goupx{
	margin-top:-1.5%;
}
#raportttt{
	font-size:105%;
}
#increase{
	font-size:110%;
}
#hit{
	margin-top:1%;
	font-size:160%;
}
#solo{
	font-size:150%;
}
#surf{
	font-size:150%;
}
#sizing{
	font-size:120%;
}

#truethat{
	font-size:160%;
}
#boldrd{
	margin-top:1%;
	font-size:140%;
	font-weight:500;
}
#dpe{
	font-size:110%;
	line-height:1.7;
}

}



@media only screen and (min-width:500px) and (max-width:1000px) and (min-height:700px) {

	#preorder{
	position: absolute;
	width:80%;
	right:10%;
	height:35%;
	top:2.5%;
	z-index:150;
	background-color: rgb(8, 8, 8);
}
#preorderlabelsss{
	position:absolute;
	top:-22.5%;
	font-size:50%;
}
#preorderclose{
	position: absolute;
	width:20.75%;
	height:2.25%;
	right:25%;
	top:0.1%;
	border:2px solid rgb(12, 12, 12);
	background-color: rgb(205, 205, 205);
}
}
</style>
